export default function(sql) {
	var keywords = [
		'SELECT', 'AS', 'FROM', 'WHERE',
		'COUNT',
		'LEFT', 'RIGHT', 'INNER', 'JOIN', 'ON',
		'ORDER', 'BY', 'GROUP',
		'LIMIT',
		'UNION',
		'DESC', 'ASC',
		'OR', 'AND',
		'SET', 'NAMES'
	];
	var defaultColor = '#383';
	var keywordColor = '#814';
	var quoteColor = '#48d';
	var backquoteColor = '#184';
	var numberColor = '#841';

	var ret = '%c';
	var colors = ['color:'+defaultColor];

	var state = 'none';
	var quote = '';
	var token = '';

	for(var i = 0; i < sql.length; i++) {
		var c = sql[i];
		switch(state) {
			case 'none':
				switch(c) {
					case '`':
						state = 'in_backquote';
						ret += '%c`';
						colors.push('color:'+backquoteColor);
						break;
					case '\'':
					case '"':
						state = 'in_quote';
						quote = c;
						ret += '%c\'';
						colors.push('color:'+quoteColor);
						break;
					case ' ':
					case '\n':
					case '\t':
						ret += c;
						break;
					default:
						if(c.match(/[A-Za-z_]+/)) {
							state = 'in_token';
							token = c;
						} else ret += c;
						break;
				}
				break;
			case 'in_backquote':
				if(c == '`') {
					ret += '`%c';
					colors.push('color:'+defaultColor);
					state = 'none';
				} else {
					ret += c;
				}
				break;
			case 'in_quote':
				if(c == quote) {
					ret += quote+'%c';
					colors.push('color:'+defaultColor);
					state = 'none';
					quote = '';
				} else {
					ret += c;
				}
				break;
			case 'in_token':
				if(!c.match(/[a-zA-Z0-9_]/)) {
					state = 'none';
					if(keywords.indexOf(token.toUpperCase()) > -1) {
						ret += '%c'+token+'%c';
						colors.push('color:'+keywordColor);
						colors.push('color:'+defaultColor);
					} else if(token.match(/^\d+(\.\d+)?$/)) {
						ret += '%c'+token+'%c';
						colors.push('color:'+numberColor);
						colors.push('color:'+defaultColor);
					} else {
						ret += token;
					}
					switch(c) {
						case '`':
							state = 'in_backquote';
							ret += '%c`';
							colors.push('color:'+quoteColor);
							break;
						default:
							ret += c;
					}
				} else {
					token += c;
				}
				break;
		}
	}

	switch(state) {
		case 'in_token':
			ret += token;
	}

	return { str: ret, colors: colors };
}
