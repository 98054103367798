var Diff = require('diff');

angular.module('app')
.config(function($stateProvider) {
	$stateProvider
		.state('updates', {
			url: "/updates",
			template: '<updates></updates>'
		})
		.state('update', {
			url: '/update/:id',
			template: require('./partials/updates/update.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$state',
				function($scope, $stateParams, api, $state) {
					api.get('updates', {
						id: $stateParams.id
					}).then(function(response) {
						var update = response.results[0];
						$scope.update = update;
						$scope.diff = Diff.diffJson(update.old, update.new);
					});
				}
			]
		})
	;
})
.directive('updates', function() {
	return {
		restrict: 'E',
		scope: {
			table: '=',
			key: '=',
		},
		template: require('./partials/updates/updates.html'),
		controller: [
			'$scope', '$uibModal', 'api',
			function($scope, $uibModal, api) {
				$scope.pagination = { curPage: 1, limit: 10, total : 0 }
				$scope.loadUpdates = function(table, key) {
					api.get('updates', {
						table: $scope.table,
						key: $scope.key,
						q: $scope.q,
						skip: ($scope.pagination.curPage - 1) * $scope.pagination.limit,
						limit: $scope.pagination.limit
					}).then(function(response) {
						$scope.updates = response.results;
						$scope.pagination.total = parseInt(response.total);
						$scope.pagination.skip = parseInt(response.skip);
						$scope.pagination.limit = parseInt(response.limit);
					});
				};
				$scope.$watch('key', function(newVal, oldVal) {
					$scope.loadUpdates();
				});
				$scope.$watch('pagination.curPage', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						$scope.loadUpdates();
					}
				});
				$scope.$watch('q', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						if($scope.pagination.curPage != 1)
							$scope.pagination.curPage = 1;
						else
							$scope.loadUpdates();
					}
				});
				$scope.popupUpdate = function(update) {
					var modal = $uibModal.open({
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="close()" aria-label="Close">\
									<span aria-hidden="true">&times;</span>\
								</button>\
								Diff\
							</div>\
							<div class="modal-body">\
								<div class="diff"><div ng-repeat="line in diff" ng-class="{\'added\': line.added, \'removed\': line.removed}">{{line.value}}</div></div> \
							</div>\
							<div class="modal-footer">\
								<button class="btn btn-default" type="button" ng-click="close()">Close</button>\
							</div>',
						controller: [
							'$scope', '$uibModalInstance',
							function(modalScope, $uibModalInstance) {
								modalScope.diff = Diff.diffJson(update.old, update.new);
								modalScope.close = function() { $uibModalInstance.close() };
							}
						]
					});
					modal.result.then(function (selectedItem) {
						$scope.model = selectedItem;
					}, function () {

					});
				};
			}
		]
	};
})
;
