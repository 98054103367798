angular.module('app')
.config([
	'$httpProvider', 'jwtInterceptorProvider', '$stateProvider',
	function($httpProvider, jwtInterceptorProvider, $stateProvider) {
		jwtInterceptorProvider.tokenGetter = ['user', function(user) {
			return user.getToken();
		}];
		$httpProvider.interceptors.push('jwtInterceptor');
		$stateProvider
			.state('users', {
				url: "/users",
				template: require('./partials/users/users.html'),
				controller: [ '$scope', '$stateParams', 'api', function($scope, $stateParams, api) {
					$scope.users = [];
					$scope.pagination = { curPage: 1, limit: 12, total : 0 };
					$scope.loadPage = function() {
						var where = { conditions: [], foreign: {} };
						if($scope.q) {
							where.conditions.push({
								type: 'or',
								value: [
									{ field: 'username', type: 'like', value: $scope.q + '%' },
								]
							});
						}
						api.get('users', {
							calc_found_rows: true,
							fields: [
								'id',
								'username',
								'num_playlists',
								'num_topics',
								'num_posts',
								'num_packs',
								'regdate',
								'website',
								'avatar_ext'
							],
							order_by: [ 'num_packs:DESC', 'username:ASC' ],
							where: where,
							limit: 12,
							skip: ($scope.pagination.curPage - 1) * 12
						}).then(function(response) {
							$scope.users = response.results;
							$scope.pagination.total = parseInt(response.total);
							$scope.pagination.skip = parseInt(response.skip);
							$scope.pagination.limit = parseInt(response.limit);
						});
					}
					$scope.$watch('pagination.curPage', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							$scope.loadPage();
						}
					});
					$scope.$watch('q', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							if($scope.pagination.curPage != 1)
								$scope.pagination.curPage = 1;
							else
								$scope.loadPage();
						}
					});
					$scope.loadPage();
				}]
			})
			.state('user', {
				url: '/user/:id',
				template: require('./partials/users/user.html'),
				controller: [
					'$scope', '$stateParams', 'api', 'user',
					function($scope, $stateParams, api, user) {
						$scope.loadUser = function(userId) {
							api.get('users', {
								fields: [
									'id',
									'type',
									'username',
									'regdate',
									'about',
									'location',
									'occupation',
									'interests',
									'website',
									'signature',
									'num_playlists',
									'num_topics',
									'num_posts',
									'num_pack_ratings',
									'num_packs',
									'avg_rating',
									'num_pack_edits',
									'avatar_ext',
								],
								foreign: {
									pack_authors: {
										foreign: {
											pack: {
												fields: [ 'url', 'title', 'rating', 'num_ratings', 'rating_sort', 'num_songs' ]
											}
										},
										limit: 10,
										order_by: [ 'pack.rating_sort:DESC' ]
									},
									posts: {
										fields: [ 'topic_id', 'post_time', 'result_number' ],
										foreign: {
											topic: {
												fields: [ 'id', 'url', 'title', 'num_posts', 'posted_at' ],
												foreign: {
													forum: {
														fields: [ 'url', 'name' ]
													}
												}
											}
										},
										limit: 10,
										order_by: [ 'post_time:DESC', 'topic.posted_at:DESC' ],
										// group_by: [ 'topic_id' ]
									},
									playlists: {
										fields: [ 'id', 'name', 'public', 'num_songs' ]
									},
									pack_edits: {
										fields: [ 'id', 'description', 'version', 'status', 'submission_date', 'approval_date' ],
										foreign: {
											pack: {
												fields: [ 'url', 'title' ]
											}
										},
										order_by: [ 'id:DESC' ],
										limit: 10
									},
									group_users: {
										foreign: {
											group: {
												fields: [ 'id', 'name', 'description', 'icon_ext' ],
											}
										}
									}
								},
								where: {
									conditions: [
										{
											field: 'id',
											type: '=',
											value: userId
										}
									]
								}
							}).then(function(response) {
								$scope.user = response.results[0];
							});
						};

						$scope.postsToPage = function(p) { return Math.floor(p / 10); };
						$scope.loadUser($stateParams.id);
						$scope.afterSave = function(user) {
							$scope.loadUser($stateParams.id);
						};
						$scope.afterDelete = function(person) {
							$state.go('users');
						};

						$scope.switchToUser = function(username) {
							user.switch(username);
						};

					}
				]
			})
			.state('preferences', {
				url: '/preferences',
				template: require('./partials/users/preferences.html'),
				controller: [
					'$scope', 'api', 'user',
					function($scope, api, user) {
						$scope.playerTypes = {
							emu: 'ScriptNode emulation',
							emuWorker: 'WebWorker emulation + ScriptNode',
							emuWorklet: 'WebWorker emulation + AudioWorklet shared buffers',
							mp3: 'MP3 playback',
						};
						if(user.getUserData().id) {
							api.get('users', {
								fields: [ 'preferences', 'favorites_playlist_id' ],
								where: {
									conditions: [
										{ field: 'id', value: user.getUserData().id }
									]
								}
							}).then(function(response) {
								if(response.results.length > 0) {
									try {
										$scope.preferences = JSON.parse(response.results[0].preferences);
									} catch(er) {
										$scope.preferences = {};
									}
									$scope.preferences.favoritesPlaylistId = response.results[0].favorites_playlist_id;
									api.get('playlists', {
										fields: [ 'id', 'name' ],
										where: {
											conditions: [
												{ field: 'owner_id', value: user.getUserData().id }
											]
										}
									}).then(function(response) {
										$scope.playlists = response.results;
									});
								}
							});
							$scope.savePreferences = function() {
								api.post('users/preferences', $scope.preferences).then(function(response) {
									console.log(response);
								});
							};
						}
					}
				]
			})
			.state('forgotpass', {
				url: '/forgotpass?hash',
				template: require('./partials/users/forgotpass.html'),
				controller: [
					'$scope',
					'$stateParams',
					'$http',
					function($scope, $stateParams, $http) {
						$scope.user = { email: '', hash: $stateParams.hash };
						$scope.send = function() {
							$scope.sent = false;
							$scope.errors = null;
							$http.post(app.apiBase+'/users/forgotpass', { email: $scope.user.email }).then(function(response) {
								if(response.errors && response.errors.length > 0) {
									$scope.errors = response.errors;
								} else {
									$scope.sent = true;
								}
							});
						};
						$scope.ok = function() {
							$scope.sent = false;
							$scope.errors = null;
							$http.post(app.apiBase+'/users/resetpass', $scope.user).then(function(response) {
								if(response.errors && response.errors.length > 0) {
									$scope.errors = response.errors;
								} else {
									$scope.user.username = response.results.username;
									$scope.sent = true;
								}
							});
						};
					}
				]
			})
			;
	}
])
.directive('userEdit', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope', '$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(userId) {
					var modal = $uibModal.open({
						template: require('./partials/users/user-edit.html'),
						size: 'lg',
						backdrop: 'static',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(userId) {
									api.get('playlists', {
										fields: [ 'id', 'name', 'num_songs' ],
										where: {
											conditions: [
												{ field: 'owner_id', value: userId }
											]
										}
									}).then(function(response) {
										modalScope.playlists = response.results;
									});
									api.get('users', {
										fields: [
											'id',
											'username',
											'email',
											'regdate',
											'about',
											'location',
											'occupation',
											'interests',
											'website',
											'signature',
											'favorites_playlist_id',
											'avatar_md5',
											'avatar_ext'
										],
										where: {
											conditions: [
												{ field: 'id', type: '=', value: userId }
											]
										}
									}).then(function(response) {
										modalScope.user = response.results[0];
									});
								} else {
									modalScope.user = {};
								}
								modalScope.ok = function() {
									if(!modalScope.user.username) {
										Notification.error('Please specify a username');
									} else if(!modalScope.user.email) {
										Notification.error('Please specify an email address');
									} else {
										var saveUser = function() {
											api.post('users', {
												key: {
													id: modalScope.user.id || null
												},
												data: {
													username: modalScope.user.username,
													email: modalScope.user.email,
													regdate: modalScope.user.regdate,
													about: modalScope.user.about,
													location: modalScope.user.location,
													occupation: modalScope.user.occupation,
													interests: modalScope.user.interests,
													website: modalScope.user.website,
													signature: modalScope.user.signature,
													favorites_playlist_id: modalScope.user.favorites_playlist_id,
													avatar_md5: modalScope.user.avatar_md5,
													avatar_ext: modalScope.user.avatar_ext
												}
											}).then(function(response) {
												Notification.success('User saved');
												$uibModalInstance.close(modalScope.user);
											}, function(err) {
												Notification.error(err);
											});
										};
										if('avatarBlob' in modalScope.user) {
											if(modalScope.user.avatarBlob) {
												api.upload('files', {
													file: modalScope.user.avatarBlob
												}).then((response) => {
													modalScope.user.avatar_md5 = response.file.md5;
													modalScope.user.avatar_ext = response.file.extension;
													saveUser();
												}, (err) => {
													Notification.error(err);
												});
											} else {
												modalScope.user.avatar_md5 = null;
												modalScope.user.avatar_ext = null;
												saveUser();
											}
										} else {
											saveUser();
										}
									}
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then((user) => {
						$scope.success({ user: user });
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
.directive('userList', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=?',
			success: '&'
		},
		controller: [
			'$scope', '$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/users/user-list.html'),
						backdrop: 'static',
						controller: [
							'$scope', 'api', '$uibModalInstance',
							function(modalScope, api, $uibModalInstance) {
								modalScope.q = '';
								modalScope.pagination = { curPage: 1, limit: 10, total : 0 }
								modalScope.$watch('q', function() {
									modalScope.getUsers();
								});
								modalScope.$watch('pagination.curPage', function(a, b) {
									modalScope.getUsers();
								});
								modalScope.selectUser = function(idx) {
									$uibModalInstance.close(modalScope.users[idx]);
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
								modalScope.getUsers = function() {
									var where = {};
									if(modalScope.q) {
										where.conditions = [
											{
												type: 'or',
												value: [
													{ field: 'username', type: 'like', value: modalScope.q + '%' }
												]
											}
										];
									}
									api.get('users', {
										calc_found_rows: true,
										fields: [ 'id', 'username', 'num_packs', 'avatar_ext' ],
										where: where,
										order_by: [ 'num_packs:DESC' ],
										skip: (modalScope.pagination.curPage - 1) * 10,
										limit: 10
									}).then(function(response) {
										modalScope.users = response.results;
										modalScope.pagination.total = parseInt(response.total);
										modalScope.pagination.skip = parseInt(response.skip);
										modalScope.pagination.limit = parseInt(response.limit);
									});

								};
								$uibModalInstance.opened.then(function() {
									modalScope.getUsers();
								});
							}
						]
					});
					modal.result.then(function (selectedItem) {
						$scope.model = selectedItem;
						$scope.success({ user: selectedItem });
					}, function () {

					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('userSelect', function() {
	return {
		restrict: 'E',
		template: require('./partials/users/user-select.html'),
		scope: {
			model: '=?',
			disabled: '=?'
		},
		controller: [
			'$scope', 'api',
			function($scope, api) {
				$scope.clear = function() {
					$scope.model = null;
				};
				// Autocomplete
				$scope.getUsers = function(val) {
					return api.get('users', {
						fields: [ 'id', 'username', 'avatar_ext' ],
						where: {
							conditions: [
								{
									type: 'or',
									value: [
										{ field: 'username', type: 'like', value: val + '%' },
									]
								}
							]
						},
						limit: 10
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	};
})
.factory('user', [
	'api', 'store', 'jwtHelper', '$rootScope', 'Notification',
	function(api, store, jwtHelper, $rootScope, Notification) {
		var userData = {
			loggedIn: false
		};

		return {
			login: function(username, password) {
				return api.post('users/auth', {
					username: username,
					password: password
				}).then(function(response) {
					response.userdata.token = response.token;
					store.set('userdata', response.userdata);
					userData = response.userdata;
					userData.loggedIn = true;
					Notification.success('Logged in as ' + userData.username);
					$rootScope.$broadcast('authorization:changed', userData);
				});
			},
			logout: function() {
				store.remove('userdata');
				userData = { loggedIn: false };
				Notification.success('Logged out');
				$rootScope.$broadcast('authorization:changed', userData);
			},
			register: function(username, email, password) {
				return api.post('users/register', {
					username: username,
					email: email,
					password: password
				}).then(function(response) {
					// store.set('jwt', response.results);
					// user.data = jwtHelper.decodeToken(response.results);
					// user.loggedIn = true;
					// $rootScope.$broadcast('authorization:changed', user);
				});
			},
			switch: function(username) {
				api.post('users/switch', { username: username }).then(function(response) {
					response.userdata.token = response.token;
					store.set('userdata', response.userdata);
					userData = response.userdata;
					userData.loggedIn = true;
					Notification.success('Switched to user ' + userData.username);
					$rootScope.$broadcast('authorization:changed', userData);
				});
			},
			loadStored: function() {
				var userdata = store.get('userdata');
				if(userdata && userdata.token && !jwtHelper.isTokenExpired(userdata.token)) {
					userData = userdata;
					userData.loggedIn = true;
				}
				$rootScope.$broadcast('authorization:changed', userData);
			},
			checkExpiry: function() {
				if(userData.loggedIn) {
					if(!userData || !userData.token || jwtHelper.isTokenExpired(userdata.token)) {
						userData = { loggedIn: false };
						store.remove('userdata');
						$rootScope.$broadcast('authorization:changed', userData);
					}
				}
			},
			getUserData: function() { return userData; },
			getToken: function() { return userData.token; },
			isLoggedIn: function() { return userData && userData.loggedIn; }
		};
	}
])
.directive('authorized', [
	'user', '$animate',
	function(user, $animate) {
		return {
			restrict: 'A',
			link: function(scope, element, attr) {
				var applyAuthorization = function() {
					var good = user.isLoggedIn();
					if(user.isLoggedIn() && attr.authorized) {
						var perms = attr.authorized.split(',');
						var userPerms = user.getUserData().permissions;
						for(var i in perms) {
							var perm = perms[i].split(':');
							if(userPerms[perm[0]] && userPerms[perm[0]][perm[1]||'edit'] == 'no') {
								good = false;
							}
						}
					}
					if(good)
						angular.element(element).removeClass('hidden');
					else
						angular.element(element).addClass('hidden');
				}

				scope.$on('authorization:changed', function(ev) {
					applyAuthorization();
				});
				applyAuthorization();
			}
		};
	}
])
.directive('admin', [
	'user', '$animate',
	function(user, $animate) {
		return {
			restrict: 'A',
			link: function(scope, element, attr) {
				var applyAuthorization = function() {
					var good = user.isLoggedIn();
					if(user.getUserData().type != 'admin')
						good = false;
					if(good)
						angular.element(element).removeClass('hidden');
					else
						angular.element(element).addClass('hidden');
				}

				scope.$on('authorization:changed', function(ev) {
					applyAuthorization();
				});
				applyAuthorization();
			}
		};
	}
])
.run(['user', function(user) {
	user.loadStored();
}])
.directive('userDelete', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(userId) {
					var modal = $uibModal.open({
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
									<span aria-hidden="true">&times;</span>\
								</button>\
								<h3 class="modal-title" ng-if="user.id">Please confirm</h3>\
							</div>\
							<div class="modal-body">\
								Are you sure you want to delete {{user.username}}?<br/>\
								This user has posted {{user.num_packs}} packs, {{user.num_playlists}} playlists, {{user.num_topics}} topics, {{user.num_posts}} posts.\
							</div>\
							<div class="modal-footer">\
								<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
								<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
							</div>',
						size: 'md',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(userId) {
									api.get('users', {
										fields: [
											'id',
											'username',
											'num_packs',
											'num_playlists',
											'num_topics',
											'num_posts'
										],
										where: {
											conditions: [
												{ field: 'id', type: '=', value: userId }
											]
										},

									}).then(function(response) {
										modalScope.user = response.results[0];
									});
								}
								modalScope.ok = function() {
									api.delete('users', {
										id: modalScope.user.id,
									}).then(function(response) {
										Notification.success('User deleted');
										$uibModalInstance.close();
										$scope.success({ user: modalScope.user });
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
;
