angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('companies', {
			url: "/companies",
			template: require('./partials/companies/companies.html'),
			controller: [
				'$scope', 'api', '$state',
				function($scope, api, $state) {
					$scope.companies = [];
					$scope.pagination = { curPage: 1, limit: 9, total : 0 }
					$scope.loadPage = function() {
						var where = {
							conditions: []
						};
						if($scope.q) {
							where.conditions = [
								{
									type: 'or',
									value: [
										{ field: 'name', type: 'like', value: $scope.q + '%' },
										{ field: 'name_en', type: 'like', value: $scope.q + '%' },
										{ field: 'name_jp', type: 'like', value: $scope.q + '%' }
									]
								}
							];
						}
						api.get('companies', {
							calc_found_rows: true,
							fields: [ 'id', 'url', 'name', 'name_en', 'name_jp', 'logo_ext', 'num_games_published', 'num_games_developed' ],
							foreign: {
								logo: {
									fields: [ 'extension' ]
								}
							},
							order_by: [ 'num_games_developed:DESC' ],
							where: where,
							skip: ($scope.pagination.curPage - 1) * 9,
							limit: 9
						}).then(function(response) {
							$scope.companies = response.results;
							$scope.pagination.total = parseInt(response.total);
							$scope.pagination.skip = parseInt(response.skip);
							$scope.pagination.limit = parseInt(response.limit);
						});
					};
					$scope.$watch('pagination.curPage', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							$scope.loadPage();
						}
					});
					$scope.$watch('q', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							if($scope.pagination.curPage != 1)
								$scope.pagination.curPage = 1;
							else
								$scope.loadPage();
						}
					});
					$scope.afterCreate = function(company) {
						$state.go('company', { url: company.url });
					};
					$scope.loadPage();
				}
			]
		})
		.state('company', {
			url: '/company/:url',
			template: require('./partials/companies/company.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$state',
				function($scope, $stateParams, api, $state) {
					$scope.loadCompany = function(url) {
						api.get('companies', {
							fields: [
								'id',
								'url',
								'name',
								'name_en',
								'name_jp',
								'description',
								'type',
								'num_games_developed',
								'num_games_published',
								'num_platforms',
								'num_chips',
								'logo_ext'
							],
							foreign: {
								parent: {
									fields: [ 'url', 'name', 'name_en', 'name_jp' ]
								},
								chips: {
									fields: [ 'url', 'name', 'num_pack_chips' ],
									foreign: {
										icon: {
											fields: [ 'extension' ]
										}
									},
									order_by: [ 'num_pack_chips:DESC' ],
									limit: 10
								},
								companies: {
									fields: [ 'url', 'name', 'name_en', 'name_jp' ],
									limit: 10
								},
								game_developers: {
									fields: [ 'role' ],
									foreign: {
										game: {
											fields: [ 'url', 'name_en', 'name_jp', 'num_releases', 'num_packs' ],
											foreign: {
												top_rated_pack: {
													fields: [ 'rating', 'num_ratings', 'rating_sort' ]
												}
											}
										}
									},
									order_by: [ 'game.top_rated_pack.rating_sort:DESC' ],
									limit: 10
								},
								platforms: {
									fields: [ 'url', 'name', 'name_en', 'name_jp', 'num_games' ],
									order_by: [ 'num_games:DESC' ],
									limit: 10
								},
								releases: {
									fields: [],
									foreign: {
										game: {
											fields: [ 'url', 'name_en', 'name_jp', 'num_releases', 'num_packs' ],
											foreign: {
												top_rated_pack: {
													fields: [ 'rating', 'num_ratings', 'rating_sort' ]
												}
											}
										}
									},
									group_by: [ 'game_id' ],
									order_by: [ 'game.top_rated_pack.rating_sort:DESC'],
									limit: 10
								},
								logo: {
									fields: [ 'extension' ]
								}
							},
							where: {
								conditions: [
									{
										field: 'url',
										type: '=',
										value: $stateParams.url
									}
								]
							}
						}).then(function(response) {
							if(response.results && response.results[0])
								$scope.company = response.results[0];
							else
								$scope.error = 'Not found';
						});
					};
					$scope.loadCompany();
					$scope.afterSave = function(company) {
						if(company.url != $stateParams.url)
							$state.go('company', { url: company.url });
						else
							$scope.loadCompany();
					};
					$scope.afterDelete = function(company) {
						$state.go('companies');
					};
				}
			]
		})
		;
}])
.directive('companyEdit', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(companyId) {
					var modal = $uibModal.open({
						template: require('./partials/companies/company-edit.html'),
						backdrop: 'static',
						size: 'lg',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								modalScope.aliasTypes = [ 'Alias', 'Alt spelling', 'Long name', 'Short name', 'Team member' ];
								modalScope.genders = [ 'Male', 'Female' ];
								modalScope.companyTypes = [ 'Company', 'Alias' ];
								if(companyId) {
									api.get('companies', {
										fields: [
											'id',
											'url',
											'name',
											'name_en',
											'name_jp',
											'description',
											'type',
											'logo_md5',
											'logo_ext'
										],
										foreign: {
											parent: {
												fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ]
											}
										},
										where: {
											conditions: [
												{ field: 'id', type: '=', value: companyId }
											]
										}
									}).then(function(response) {
										modalScope.company = response.results[0];
										modalScope.initialCompanyUrl = modalScope.company.url;
									});
								} else {
									modalScope.company = { type: 'Company' };
								}
								modalScope.ok = function() {
									if(!modalScope.company.name) {
										Notification.error('Please specify company name');
									} else if(!modalScope.company.url) {
										Notification.error('Please input company URL slug');
									} else if(modalScope.company.type == 'Alias' && (!modalScope.company.parent || !modalScope.company.parent.id)) {
										Notification.error('Please select a company that this company is an alias of');
									} else {
										var saveCompany = () => {
											api.post('companies', {
												key: {
													id: modalScope.company.id||null
												},
												data: {
													url: modalScope.company.url,
													name: modalScope.company.name,
													name_en: modalScope.company.name_en,
													name_jp: modalScope.company.name_jp,
													description: modalScope.company.description,
													type: modalScope.company.type,
													parent_id: modalScope.company.parent ? modalScope.company.parent.id : null,
													logo_md5: modalScope.company.logo_md5,
													logo_ext: modalScope.company.logo_ext
												}
											}).then(function(response) {
												Notification.success('Company saved');
												$uibModalInstance.close(modalScope.company);
											}, function(err) {
												Notification.error(err);
											});
										};
										if('logoBlob' in modalScope.company) {
											if(modalScope.company.logoBlob) {
												api.upload('files', {
													file: modalScope.company.logoBlob
												}).then((response) => {
													modalScope.company.logo_md5 = response.file.md5;
													modalScope.company.logo_ext = response.file.extension;
													saveCompany();
												}, (err) => {
													Notification.error(err);
												});
											} else {
												modalScope.company.logo_md5 = null;
												modalScope.company.logo_ext = null;
												saveCompany();
											}
										} else {
											saveCompany();
										}
									}
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then((company) => {
						$scope.success({ company: company });
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
.directive('companySelect', function() {
	return {
		restrict: 'E',
		template: require('./partials/companies/company-select.html'),
		scope: {
			model: '=?'
		},
		controller: [
			'$scope', 'api', '$uibModal',
			function($scope, api, $uibModal) {
				$scope.company = $scope.model;
				$scope.$watch('company', function() {
					$scope.model = $scope.company;
				});
				$scope.$watch('model', function() {
					if(!$scope.company)
						$scope.company = $scope.model;
				});
				$scope.clear = function() {
					$scope.company = null;
				};
				// Autocomplete
				$scope.getCompanies = function(val) {
					return api.get('companies', {
						fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ],
						where: {
							conditions: [
								{
									type: 'or', value: [
										{ field: 'name', type: 'like', value: val + '%' },
										{ field: 'name_jp', type: 'like', value: val + '%' },
										{ field: 'name_en', type: 'like', value: val + '%' }
									]
								}
							]
						},
						limit: 10
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	};
})
.directive('companyList', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=?'
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/companies/company-list.html'),
						controller: [
							'$scope', 'api', '$uibModalInstance',
							function(modalScope, api, $uibModalInstance) {
								modalScope.q = '';
								modalScope.pagination = { curPage: 1, limit: 10, total : 0 }
								modalScope.$watch('q', function() {
									modalScope.getCompanies();
								});
								modalScope.$watch('pagination.curPage', function(a, b) {
									modalScope.getCompanies();
								});
								modalScope.selectCompany = function(idx) {
									$uibModalInstance.close(modalScope.companies[idx]);
								};
								modalScope.ok = function() {
									$uibModalInstance.close();
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
								modalScope.getCompanies = function() {
									var where = {};
									if(modalScope.q) {
										where.conditions = [
											{
												type: 'or',
												value: [
													{ field: 'name', type: 'like', value: modalScope.q + '%' },
													{ field: 'name_en', type: 'like', value: modalScope.q + '%' },
													{ field: 'name_jp', type: 'like', value: modalScope.q + '%' }
												]
											}
										];
									}
									api.get('companies', {
										calc_found_rows: true,
										fields: [
											'id',
											'url',
											'name',
											'name_en',
											'name_jp',
											'num_games_published',
											'num_games_developed',
											'num_platforms',
											'num_chips'
										],
										where: where,
										order_by: [ 'num_games_developed:DESC' ],
										skip: (modalScope.pagination.curPage - 1) * 10,
										limit: 10
									}).then(function(response) {
										modalScope.companies = response.results;
										modalScope.pagination.total = parseInt(response.total);
										modalScope.pagination.skip = parseInt(response.skip);
										modalScope.pagination.limit = parseInt(response.limit);
									});

								};
								$uibModalInstance.opened.then(function() {
									modalScope.getCompanies();
								});
							}
						]
					});
					modal.result.then(function (selectedItem) {
						$scope.model = selectedItem;
					}, function () {

					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('companyDelete', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(companyId) {
					var modal = $uibModal.open({
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
									<span aria-hidden="true">&times;</span>\
								</button>\
								<h3 class="modal-title" ng-if="company.id">Please confirm</h3>\
							</div>\
							<div class="modal-body">\
								Are you sure you want to delete {{company.name_en||company.name||company.name_jp}}?<br/>\
								This company has:\
								<ul>\
									<li>{{company.num_games_developed}} developed games</li>\
									<li>{{company.num_games_published}} published games</li>\
									<li>{{company.num_platforms}} platforms manufactured</li>\
									<li>{{company.num_chips}} chips manufactured</li>\
								</ul>\
							</div>\
							<div class="modal-footer">\
								<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
								<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
							</div>',
						size: 'md',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(companyId) {
									api.get('companies', {
										fields: [
											'id',
											'url',
											'name',
											'name_en',
											'name_jp',
											'num_games_developed',
											'num_games_published',
											'num_platforms',
											'num_chips'
										],
										foreign: {
											alias_of: {
												fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ]
											}
										},
										where: {
											conditions: [
												{ field: 'id', type: '=', value: companyId }
											]
										},

									}).then(function(response) {
										modalScope.company = response.results[0];
									});
								}
								modalScope.ok = function() {
									api.delete('companies', {
										id: modalScope.company.id,
									}).then(function(response) {
										Notification.success('Company deleted');
										$uibModalInstance.close();
										$scope.success({ company: modalScope.company });
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
;
