angular.module('app')
.config(function($stateProvider) {
	$stateProvider
		.state('conversations', {
			url: "/conversations",
			template: require('./partials/messages/conversations.html'),
			controller: [
				'$scope', '$uibModal', '$stateParams', 'api',
				function($scope, $uibModal, $stateParams, api) {
					$scope.conversations = [];
					$scope.pagination = { curPage: 1, limit: 10, total : 0 };
					$scope.loadPage = function() {
						var where = { conditions: [], foreign: {} };
						if($scope.q) {
							where.conditions.push({
								type: 'or',
								value: [
									{ field: 'subject', type: 'like', value: '%' + $scope.q + '%' },
								]
							});
						}

						api.get('conversations', {
							calc_found_rows: true,
							fields: [ 'id', 'subject', 'started_at' ],
							foreign: {
								starter: {
									fields: [ 'id', 'username' ]
								},
								conversation_users: {
									fields: [ 'last_read_time' ],
									foreign: {
										user: {
											fields: [ 'id', 'username' ]
										}
									}
								}
							},
							where: where,
							order_by: [ 'started_at:DESC' ],
							skip: ($scope.pagination.curPage - 1) * 10,
							limit: 10
						}).then(function(response) {
							$scope.conversations = response.results;
							$scope.pagination.total = parseInt(response.total);
							$scope.pagination.skip = parseInt(response.skip);
							$scope.pagination.limit = parseInt(response.limit);
						});
					};
					$scope.$watch('pagination.curPage', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							$scope.loadPage();
						}
					});
					$scope.$watch('q', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							if($scope.pagination.curPage != 1)
								$scope.pagination.curPage = 1;
							else
								$scope.loadPage();
						}
					});
					$scope.loadPage();
				}
			]
		})
		.state('conversation', {
			url: '/conversation/:id',
			template: require('./partials/messages/conversation.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$state',
				function($scope, $stateParams, api, $state) {
					$scope.loadConversation = function() {
						api.get('conversations', {
							fields: [ 'id', 'subject', 'started_at' ],
							foreign: {
								starter: {
									fields: [ 'id', 'username' ]
								},
								conversation_users: {
									fields: [ 'last_read_time' ],
									foreign: {
										user: {
											fields: [ 'id', 'username', 'avatar_ext' ]
										}
									}
								},
								messages: {
									fields: [ 'content', 'posted_at', 'ip' ],
									foreign: {
										poster: {
											fields: [ 'id', 'username', 'avatar_ext' ]
										},
										message_attachments: {
											fields: [ 'filename' ]
										}
									}
								}
							},
							where: {
								conditions: [
									{
										field: 'id',
										type: '=',
										value: $stateParams.id
									}
								]
							}
						}).then(function(response) {
							$scope.conversation = response.results[0];
						});
					};
					$scope.loadConversation();
					$scope.afterSave = function(message) {
						$scope.loadConversation();
					};
					$scope.afterDelete = function(message) {
						$state.go('conversations');
					};
					$scope.reply = {
						content: '',
						attachments: []
					};
					$scope.postReply = function() {
						console.log($scope.reply);
					};
				}
			]
		})
	;
})
.directive('messageEdit', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope', '$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(messageId) {
					$uibModal.open({
						template: require('./partials/messages/message-edit.html'),
						size: 'lg',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								modalScope.messageTypes = [ 'Message', 'Alias' ];
								if(messageId) {
									api.get('messages', {
										fields: [
											'id',
											'name'
										],
										foreign: {
											parent: {
												fields: [ 'id', 'url', 'name' ]
											},
											manufacturer: {
												fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ]
											}
										},
										where: {
											conditions: [
												{ field: 'id', type: '=', value: messageId }
											]
										},

									}).then(function(response) {
										var message = response.results[0];
										var specs = JSON.parse(message.specs);
										message.specs = [];
										if(specs) {
											for(var i in specs) {
												message.specs.push({ key: i, value: specs[i] });
											}
										}
										modalScope.message = message;
									});
								} else {
									modalScope.message = {};
								}
								modalScope.addSpec = function() {
									modalScope.message.specs.push({ key: '', value: '' });
								};
								modalScope.removeSpec = function(idx) {
									modalScope.message.specs.splice(idx, 1);
								};
								modalScope.ok = function() {
									var specs = {};
									for(var i in modalScope.message.specs)
										specs[modalScope.message.specs[i].key] = modalScope.message.specs[i].value;
									console.log("saving", modalScope.message);
									api.post('messages', {
										key: {
											id: modalScope.message.id,
										},
										data: {
											url: modalScope.message.url,
											name: modalScope.message.name,
											friendly_name: modalScope.message.friendly_name,
											tech_name: modalScope.message.tech_name,
											short_name: modalScope.message.short_name,
											comment: modalScope.message.comment,
											parent_message_id: modalScope.message.parent&&modalScope.message.parent.id,
											manufacturer_id: modalScope.message.manufacturer&&modalScope.message.manufacturer.id,
											icon_id: modalScope.message.icon&&modalScope.message.icon.id,
											specs: JSON.stringify(specs)
										}
									}).then(function(response) {
										Notification.success('Message saved');
										$uibModalInstance.close();
										$scope.success({ message: modalScope.message });
									}, function(err) {
										if(err && err[0] && err[0].errstr)
											Notification.error(err[0].errstr);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then(function() {}, function() {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
.directive('messageDelete', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(messageId) {
					var modal = $uibModal.open({
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
									<span aria-hidden="true">&times;</span>\
								</button>\
								<h3 class="modal-title" ng-if="message.id">Please confirm</h3>\
							</div>\
							<div class="modal-body">\
								Are you sure you want to delete {{message.name}}?<br/>\
								{{message.num_packs}} packs use this message.\
							</div>\
							<div class="modal-footer">\
								<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
								<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
							</div>',
						size: 'md',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(messageId) {
									api.get('messages', {
										fields: [
											'id',
											'url',
											'name',
											'num_packs'
										],
										foreign: {
											alias_of: {
												fields: [ 'id', 'url', 'name' ]
											}
										},
										where: {
											conditions: [
												{ field: 'id', type: '=', value: messageId }
											]
										},

									}).then(function(response) {
										modalScope.message = response.results[0];
									});
								}
								modalScope.ok = function() {
									api.delete('messages', {
										id: modalScope.message.id,
									}).then(function(response) {
										Notification.success('Message deleted');
										$uibModalInstance.close();
										$scope.success({ message: modalScope.message });
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
;
