var Diff = require('diff');

angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('packs', {
			url: "/packs?chip_url&author_id",
			template: require('./partials/packs/packs.html'),
			controller: ['$scope', '$stateParams', 'api', function($scope, $stateParams, api) {
				$scope.packs = [];
				$scope.pagination = { curPage: 1, limit: 10, total : 0 }
				$scope.loadPage = function() {
					var where = { conditions: [], foreign: {} };

					if($scope.q) {
						where.conditions.push({field: 'title', type: 'like', value: $scope.q + '%' });
					}
					if($stateParams.chip_url) {
						api.get('chips', {
							fields: [ 'id', 'name', 'url' ],
							where: {
								conditions: [
									{ field: 'url', type: '=', value: $stateParams.chip_url }
								]
							}
						}).then(function(response) {
							$scope.chip = response.results[0];
						});
						where.foreign.pack_chips = {
							foreign: {
								chip: {
									conditions: [
										{
											field: 'url',
											type: '=',
											value: $stateParams.chip_url
										}
									]
								}
							}
						};
					}
					if($stateParams.author_id) {
						api.get('users', {
							fields: [ 'id', 'username' ],
							where: {
								conditions: [
									{ field: 'id', type: '=', value: $stateParams.author_id }
								]
							}
						}).then(function(response) {
							$scope.author = response.results[0];
						});
						where.foreign.pack_authors = {
							foreign: {
								author: {
									conditions: [
										{
											field: 'id',
											type: '=',
											value: $stateParams.author_id
										}
									]
								}
							}
						};
					}

					api.get('packs', {
						calc_found_rows: true,
						fields: [
							'url',
							'title',
							'num_songs',
							'rating',
							'num_ratings',
							'rating_sort',
							'num_pack_m3u'
						],
						foreign: {
							pack_chips: {
								fields: [],
								foreign: {
									chip: {
										fields: [ 'id', 'name', 'url' ]
									}
								}
							},
							game: {
								fields: [ 'name_en', 'name_jp', 'url' ],
							},
							pack_authors: {
								foreign: {
									author: {
										fields: [ 'id', 'username' ]
									}
								}
							},
							packs_releases: {
								foreign: {
									release: {
										foreign: {
											platform: {
												fields: [ 'url', 'name_en', 'name_jp', 'short_name' ]
											}
										}
									}
								},
								limit: 3
							}
						},
						where: where,
						order_by: [ 'rating_sort:DESC' ],
						limit: 10,
						skip: ($scope.pagination.curPage - 1) * 10
					}).then(function(response) {
						$scope.packs = response.results;
						$scope.pagination.total = parseInt(response.total);
						$scope.pagination.skip = parseInt(response.skip);
						$scope.pagination.limit = parseInt(response.limit);
						api.markLoaded();
					});
				}
				$scope.$watch('pagination.curPage', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						$scope.loadPage();
					}
				});
				$scope.$watch('q', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						if($scope.pagination.curPage != 1)
							$scope.pagination.curPage = 1;
						else
							$scope.loadPage();
					}
				});
				$scope.loadPage();
			}]
		})
		.state('pack', {
			url: '/pack/:url?version',
			template: require('./partials/packs/pack.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$rootScope',
				function($scope, $stateParams, api, $rootScope) {
					api.get('packs', {
						fields: [
							'id',
							'url',
							'title',
							'description',
							'rating',
							'num_ratings'
						],
						foreign: {
							game: {
								fields: [ 'url', 'name_en', 'name_jp', 'num_packs' ],
								foreign: {
									top_rated_pack: {
										fields: [ 'title', 'rating', 'num_ratings' ]
									}
								}
							},
							packs_releases: {
								foreign: {
									release: {
										fields: [ 'id', 'order', 'release_date', 'title_en', 'title', 'territory_code' ],
										foreign: {
											platform: {
												fields: [ 'url', 'name_en', 'name_jp', 'short_name' ]
											},
											publisher: {
												fields: [ 'url', 'name_en', 'name_jp' ]
											},
											screenshots: {
												fields: [ 'filename'  ],
												order_by: [ 'order' ]
											}
										}
									}
								}
							},
							songs: {
								fields: [
									'id',
									'order',
									'filename',
									'track',
									'url',
									'track_name_en',
									'track_name_jp',
									'total_samples',
									'loop_samples',
									'favorite'
								],
								foreign: {
									song_staff: {
										fields: [ 'comment' ],
										foreign: {
											person: {
												fields: [ 'url', 'name_en', 'name_jp', 'name' ]
											},
											role: {
												fields: [ 'id', 'name' ]
											}
										}
									}
								}
							},
							pack_chips: {
								foreign: {
									chip: {
										fields: [ 'url', 'name' ]
									}
								}
							},
							pack_authors: {
								fields: [ 'role' ],
								foreign: {
									author: {
										fields: [ 'id', 'username', 'avatar_ext' ]
									}
								}
							},
							pack_edits: {
								fields: [ 'id', 'description', 'status', 'version', 'approval_date', 'submission_date' ],
								foreign: {
									user: {
										fields: [ 'id', 'username' ]
									}
								},
								order_by: [ 'submission_date:DESC' ]
							},
							pack_m3u: {
								fields: [ 'id', 'name' ],
								foreign: {
									songs: {
										fields: [ 'title' ],
										foreign: {
											song: {
												fields: [
													'id',
													'order',
													'filename',
													'track',
													'url',
													'track_name_en',
													'track_name_jp',
													'total_samples',
													'loop_samples',
													'favorite'
												],
												foreign: {
													song_staff: {
														fields: [ 'comment' ],
														foreign: {
															person: {
																fields: [ 'url', 'name_en', 'name_jp', 'name' ]
															},
															role: {
																fields: [ 'id', 'name' ]
															}
														}
													}
												}
											}
										}
									}
								}
							}
						},
						where: {
							conditions: [
								{ field: 'url', type: '=', value: $stateParams.url }
							]
						}
					}).then(function(response) {
						if(!response.results || !response.results[0]) {
							$scope.error = 'Not found';
						} else {
							$scope.pack = response.results[0];
							if($stateParams.version) {
								api.get('pack_edits', {
									fields: [ 'id', 'new', 'status', 'version', 'submission_date' ],
									where: {
										conditions: [
											{ field: 'version', value: $stateParams.version },
											{ field: 'pack_id', value: $scope.pack.id }
										]
									}
								}).then((response) => {
									$scope.version = response.results[0];
								});
							}
							$scope.jp = false;
							$rootScope.$on('favoritedSong', function(ev, data) {
								for(var i in $scope.pack.songs) {
									if($scope.pack.songs[i].id == data.song_id) {
										$scope.pack.songs[i].favorite = data.favorite;
									}
								}
								for(var i in $scope.pack.pack_m3u) {
									for(var j in $scope.pack.pack_m3u[i].songs) {
										if($scope.pack.pack_m3u[i].songs[j].song.id == data.song_id) {
											$scope.pack.pack_m3u[i].songs[j].song.favorite = data.favorite;
										}
									}
								}
							});
							$rootScope.$on('player:setPlaying', function(ev, data) {
								for(var i in $scope.pack.songs) {
									if($scope.pack.songs[i].id == data.song_id) {
										$scope.pack.songs[i].playing = data.playing;
									} else if($scope.pack.songs[i].playing) {
										$scope.pack.songs[i].playing = false;
									}
								}

								for(var i in $scope.pack.pack_m3u) {
									for(var j in $scope.pack.pack_m3u[i].songs) {
										if($scope.pack.pack_m3u[i].songs[j].song.id == data.song_id) {
											$scope.pack.pack_m3u[i].songs[j].song.playing = data.playing;
										} else if($scope.pack.pack_m3u[i].songs[j].song.playing) {
											$scope.pack.pack_m3u[i].songs[j].song.playing = false;
										}
									}
								}
							});
						}
					});
				}
			]
		})
		.state('pack-edit', {
			url: '/pack-edit/:url?game&request&load',
			template: require('./partials/packs/pack-edit.html'),
			controller: [
				'$scope', '$stateParams', 'api', 'vgmPlayer', '$uibModal', 'user', '$q', 'Notification',
				function($scope, $stateParams, api, vgmPlayer, $uibModal, user, $q, Notification) {
					var curPack = null, prevPack = null;
					$scope.pack = null;

					$scope.preparePackDetails = function() {
						$scope.pack.title = curPack.title || '';
						$scope.pack.prevTitle = prevPack.title || '';

						$scope.pack.url = curPack.url || '';
						$scope.pack.prevUrl = prevPack.url || '';

						$scope.pack.description = (curPack.description || '').replace(/\r\n/g, '\n');
						$scope.pack.prevDescription = (prevPack.description || '').replace(/\r\n/g, '\n');
					};

					$scope.preparePackM3Us = function() {
						// /* Keep previous values for M3Us */
						// for(let i in pack.pack_m3u) {
						// 	var m3u = pack.pack_m3u[i];

						// 	m3u.isModified = false;
						// 	m3u.isNew = false;
						// 	m3u.isDeleted = false;

						// 	m3u.prevName = m3u.name;

						// 	for(let j in m3u.songs) {
						// 		m3u.songs[j].songIndex = songIndicesById[m3u.songs[j].song_id];
						// 		m3u.songs[j].prevSongIndex = m3u.songs[j].songIndex;
						// 		m3u.songs[j].prevTitle = m3u.songs[j].title;
						// 		m3u.songs[j].siblingBeforeIndex = j > 0 ? j - 1 : null;
						// 		m3u.songs[j].siblingAfterIndex = j < m3u.songs.length - 1 ? j + 1 : null;
						// 	}
						// }
					};

					$scope.preparePack = function() {
						$scope.pack = {};

						$scope.preparePackDetails();
						$scope.preparePackAuthors();
						$scope.preparePackChips();
						$scope.preparePackReleases();
						$scope.preparePackSongs();
						$scope.preparePackM3Us();
						$scope.pack.pack_edits = curPack.pack_edits;
					}

					function loadEdit(packUrl, editId) {
						api.get('pack_edits', {
							fields: [ 'id', 'description', 'version', 'new', 'old', 'approval_date', 'submission_date' ],
							foreign: {
								pack: {
									fields: [ 'id', 'url' ],
									foreign: {
										game: {
											fields: [ 'id', 'url', 'name_en', 'name_jp', 'num_packs' ],
											foreign: {
												releases: {
													fields: [ 'id', 'order', 'title', 'title_en', 'territory_code', 'release_date' ],
													foreign: {
														platform: {
															fields: [ 'id', 'name', 'name_en', 'name_jp' ]
														},
														publisher: {
															fields: [ 'id', 'name', 'name_en', 'name_jp' ]
														},
														territory: {
															fields: [ 'code', 'name' ]
														}
													}
												}
											}
										}
									}
								}
							},
							where: {
								conditions: [
									{ field: 'id', value: editId },
									{ field: 'pack.url', value: packUrl }
								]
							}
						}).then((response) => {
							if(!response.results || !response.results[0]) {
								$scope.error = 'Pack Update Not Found';
							} else {
								newPack = JSON.parse($scope.edit.new);
								newPack.game = $scope.edit.pack.game;
								oldPack = JSON.parse($scope.edit.old);
								$scope.loadPack();
								$scope.setCurSong(0);
								$scope.curM3u = $scope.pack.pack_m3u[0] || null;
								$scope.edit = response.results[0];
							}
						});
					}

					function loadPack(url) {
						api.get('packs', {
							fields: [
								'id', 'url', 'title', 'description', 'rating', 'num_ratings'
							],
							foreign: {
								game: {
									fields: [ 'id', 'url', 'name_en', 'name_jp', 'num_packs' ],
									foreign: {
										releases: {
											fields: [ 'id', 'order', 'title', 'title_en', 'territory_code', 'release_date' ],
											foreign: {
												platform: {
													fields: [ 'id', 'name', 'name_en', 'name_jp' ]
												},
												publisher: {
													fields: [ 'id', 'name', 'name_en', 'name_jp' ]
												},
												territory: {
													fields: [ 'code', 'name' ]
												},
												screenshots: {
													fields: [ 'filename' ],
													limit: 1,
													order_by: [ 'order' ]
												}
											}
										}
									}
								},
								packs_releases: {
									fields: [ 'release_id' ]
								},
								songs: {
									fields: [
										'id', 'order', 'filename', 'track',
										'url', 'version',
										'total_samples', 'loop_samples',
										'track_name_en', 'track_name_jp',
										'game_name_en', 'game_name_jp',
										'system_name_en', 'system_name_jp',
										'track_author_en', 'track_author_jp',
										'release_date', 'ripper', 'notes'
									],
									foreign: {
										file: {
											fields: [ 'md5', 'size' ],
										},
										song_staff: {
											fields: [ 'comment' ],
											foreign: {
												person: {
													fields: [ 'id', 'url', 'name_en', 'name_jp', 'name' ]
												},
												role: {
													fields: [ 'id', 'name' ]
												}
											}
										},
										song_chips: {
											fields: [ 'clock', 'multiplier' ],
											foreign: {
												chip: {
													fields: [ 'id', 'url', 'name', 'friendly_name', 'short_name', 'icon_ext' ]
												}
											}
										}
									}
								},
								pack_m3u: {
									fields: [ 'name' ],
									foreign: {
										songs: {
											fields: [ 'order', 'title', 'song_id' ],
											foreign: {
												song: {
													fields: [ 'id', 'filename' ]
												}
											},
											order_by: [ 'order' ]
										}
									}
								},
								pack_chips: {
									fields: [ 'multiplier', 'comment' ],
									foreign: {
										chip: {
											fields: [ 'id', 'url', 'name', 'icon_ext' ]
										}
									}
								},
								pack_authors: {
									fields: [ 'role' ],
									foreign: {
										author: {
											fields: [ 'id', 'username', 'avatar_ext' ]
										}
									}
								},
								pack_edits: {
									fields: [ 'id', 'description', 'status', 'version', 'approval_date', 'submission_date' ],
									foreign: {
										user: {
											fields: [ 'id', 'username' ]
										}
									},
									order_by: [ 'version:DESC' ]
								}
							},
							where: {
								conditions: [
									{ field: 'url', type: '=', value: url }
								]
							}
						}).then(function(response) {
							if(!response.results || !response.results[0]) {
								$scope.error = 'Not found';
							} else {
								curPack = prevPack = response.results[0];
								$scope.preparePack();
								$scope.setCurSong(0);
								$scope.curM3u = $scope.pack.pack_m3u && $scope.pack.pack_m3u[0] || null;
							}
						});
					}

					function loadGame(gameUrl) {
						api.get('games', {
							fields: [ 'id', 'url', 'name_en', 'name_jp', 'num_packs' ],
							foreign: {
								releases: {
									fields: [ 'id', 'order', 'title', 'title_en', 'territory_code', 'release_date' ],
									foreign: {
										platform: {
											fields: [ 'id', 'name', 'name_en', 'name_jp' ]
										},
										publisher: {
											fields: [ 'id', 'name', 'name_en', 'name_jp' ]
										},
										territory: {
											fields: [ 'code', 'name' ]
										},
										screenshots: {
											fields: [ 'filename' ],
											limit: 1,
											order_by: [ 'order' ]
										}
									}
								}
							},
							where: {
								conditions: [ { field: 'url', value: gameUrl } ]
							}
						}).then(function(response) {
							if(!response.results || !response.results[0]) {
								$scope.error = 'Game Not Found';
							} else {
								curPack = prevPack = {
									game: response.results[0],
									pack_releases: [],
									songs: [],
									pack_m3u: [],
									pack_authors: [{
										author: {
											username: user.getUserData().username,
											id: user.getUserData().id
										}
									}]
								};
								$scope.preparePack();

								$scope.edit = {
									version: '1.00',
									description: 'Initial release.'
								};
							}
						});
					}

					function loadRequest(requestId) {
						api.get('requests', {
							fields: [ 'id', 'title', 'description' ],
							foreign: {
								game: {
									fields: [ 'id', 'url', 'name_en', 'name_jp', 'num_packs' ],
									foreign: {
										releases: {
											fields: [ 'id', 'order', 'title', 'title_en', 'territory_code', 'release_date' ],
											foreign: {
												platform: {
													fields: [ 'id', 'name', 'name_en', 'name_jp' ]
												},
												publisher: {
													fields: [ 'id', 'name', 'name_en', 'name_jp' ]
												}
											}
										}
									}
								},
								request_releases: {
									fields: [ 'release_id' ]
								}
							},
							where: {
								conditions: [ { field: 'id', value: requestId } ]
							}
						}).then(function(response) {
							if(!response.results || !response.results[0]) {
								$scope.error = "Request Not Found";
							} else {
								var request = response.results[0];
								curPack = prevPack = {
									title: request.title,
									description: request.description,
									songs: [],
									pack_authors: [{
										author: {
											username: user.getUserData().username,
											id: user.getUserData().id
										}
									}],
									game: request.game,
									pack_releases: request.request_releases
								};
								$scope.preparePack();

								$scope.edit = {
									version: '1.00',
									description: response.results[0].description
								};
							}
						});
					}

					if($stateParams.url) {
						$scope.initialUrl = $stateParams.url;
						if($stateParams.load) {
							loadEdit($stateParams.url, $stateParams.load);
						} else {
							loadPack($stateParams.url);
						}
					} else if($stateParams.game) {
						loadGame($stateParams.game);
					} else if($stateParams.request) {
						loadRequest($stateParams.request);
					} else {
						/* This is basically a testing situation */
						prevPack = curPack = {};
						$scope.preparePack();
					}

					$scope.composerRole = null;
					api.get('staff_roles', {
						fields: [ 'id', 'name' ],
						where: {
							conditions: [
								{ field: 'name', value: 'composer' }
							]
						}
					}).then((response) => {
						if(response.results && response.results[0])
							$scope.composerRole = response.results[0];
					});

					/* Details */
					$scope.resetDetails = function() {
						$scope.pack.title = $scope.pack.prevTitle = prevPack.title || '';
						$scope.pack.url = $scope.pack.prevUrl = prevPack.url || '';
						$scope.pack.description = $scope.pack.prevDescription = prevPack.description || '';
					};

					/* Pack Authors */
					function cleanPackAuthorData(data) {
						return {
							author: angular.copy(data.author),
							role: data.role || ''
						};
					}
					function copyPrevPackAuthorData(data, newData) {
						if(!newData)
							newData = data;

						data.prevRole = newData.role || '';
					}
					$scope.preparePackAuthors = function() {
						/* Detect inserted and modified pack authors */
						let curPackAuthorIds = {}, prevPackAuthorIds = {}, allPackAuthors = {};
						for(let i in curPack.pack_authors) {
							let pack_author = curPack.pack_authors[i];
							curPackAuthorIds[pack_author.author.id] = true;
							allPackAuthors[pack_author.author.id] = cleanPackAuthorData(pack_author);
							copyPrevPackAuthorData(allPackAuthors[pack_author.author.id]);
							allPackAuthors[pack_author.author.id].isNew = true;
							allPackAuthors[pack_author.author.id].isDeleted = false;
						}

						for(let i in prevPack.pack_authors) {
							let pack_author = prevPack.pack_authors[i];
							prevPackAuthorIds[pack_author.author.id] = true;
							if(!(pack_author.author.id in allPackAuthors)) {
								allPackAuthors[pack_author.author.id] = cleanPackAuthorData(pack_author);
								allPackAuthors[pack_author.author.id].isDeleted = true;
							}
							allPackAuthors[pack_author.author.id].isNew = false;
							copyPrevPackAuthorData(allPackAuthors[pack_author.author.id], pack_author);
						}

						var pack_authors = [];
						for(let i in allPackAuthors)
							pack_authors.push(allPackAuthors[i]);
						allPackAuthors = curPackAuthorIds = prevPackAuthorIds = null;

						$scope.pack.pack_authors = pack_authors;
					};
					$scope.resetPackAuthors = function() {
						var pack_authors = [];
						for(var i in prevPack.pack_authors) {
							var pack_author = prevPack.pack_authors[i];
							pack_authors.push(cleanPackAuthorData(pack_author));
							copyPrevPackAuthorData(pack_authors[i]);
						}
						$scope.pack.pack_authors = pack_authors;
					};
					$scope.addPackAuthor = function(user) {
						if(!$scope.pack.pack_authors)
							$scope.pack.pack_authors = [];
						$scope.pack.pack_authors.push({ role: null, author: user, isNew: true });
					};
					$scope.removePackAuthor = function(idx) {
						if($scope.pack.pack_authors[idx].isNew) {
							$scope.pack.pack_authors.splice(idx, 1);
						} else {
							$scope.pack.pack_authors[idx].isDeleted = 1;
						}
					};
					$scope.restorePackAuthor = function(idx) {
						$scope.pack.pack_authors[idx].isDeleted = 0;
					};

					/* Pack Chips */
					function cleanPackChipData(data) {
						return {
							chip: angular.copy(data.chip),
							clock: data.clock || '',
							multiplier: data.multiplier || '',
							comment: data.comment || ''
						};
					}
					function copyPrevPackChipData(data, newData) {
						if(!newData)
							newData = data;

						data.prevClock = newData.clock || '';
						data.prevMultiplier = newData.multiplier || '';
						data.prevComment = newData.comment || '';
					}
					$scope.preparePackChips = function() {
						/* Detect inserted and modified pack chips */
						let curPackChipIds = {}, prevPackChipIds = {}, allPackChips = {};
						for(let i in curPack.pack_chips) {
							let pack_chip = curPack.pack_chips[i];
							curPackChipIds[pack_chip.chip.id] = true;
							allPackChips[pack_chip.chip.id] = cleanPackChipData(pack_chip);
							copyPrevPackChipData(allPackChips[pack_chip.chip.id]);
							allPackChips[pack_chip.chip.id].isNew = true;
							allPackChips[pack_chip.chip.id].isDeleted = false;
						}

						for(let i in prevPack.pack_chips) {
							let pack_chip = prevPack.pack_chips[i];
							prevPackChipIds[pack_chip.chip.id] = true;
							if(!(pack_chip.chip.id in allPackChips)) {
								allPackChips[pack_chip.chip.id] = cleanPackChipData(pack_chip);
								allPackChips[pack_chip.chip.id].isDeleted = true;
							}
							allPackChips[pack_chip.chip.id].isNew = false;
							copyPrevPackChipData(allPackChips[pack_chip.chip.id], pack_chip);
						}

						var pack_chips = [];
						for(let i in allPackChips)
							pack_chips.push(allPackChips[i]);
						allPackChips = curPackChipIds = prevPackChipIds = null;

						$scope.pack.pack_chips = pack_chips;
					};
					$scope.resetPackChips = function() {
						var pack_chips = [];
						for(var i in prevPack.pack_chips) {
							var pack_chip = prevPack.pack_chips[i];
							pack_chips.push(cleanPackChipData(pack_chip));
							copyPrevPackChipData(pack_chips[i]);
						}
						$scope.pack.pack_chips = pack_chips;
					};
					$scope.addPackChip = function(chip) {
						if(!$scope.pack.pack_chips)
							$scope.pack.pack_chips = [];
						$scope.pack.pack_chips.push({ chip: chip, multiplier: 1, clock: null, comment: null, isNew: true });
					};
					$scope.removePackChip = function(idx) {
						if($scope.pack.pack_chips[idx].isNew) {
							$scope.pack.pack_chips.splice(idx, 1);
						} else {
							$scope.pack.pack_chips[idx].isDeleted = 1;
						}
					};
					$scope.restorePackChip = function(idx) {
						$scope.pack.pack_chips[idx].isDeleted = 0;
					};

					/* Releases */
					$scope.preparePackReleases = function() { 
						/* Keep previous values for pack releases */
						var game = angular.copy(curPack.game || {});
						let curPackReleaseIds = {}, prevPackReleaseIds = {};
						for(let i in curPack.packs_releases) {
							curPackReleaseIds[curPack.packs_releases[i].release_id] = true;
						}
						for(let i in prevPack.packs_releases) {
							prevPackReleaseIds[prevPack.packs_releases[i].release_id] = true;
						}
						for(let i in game.releases) {
							game.releases[i].selected = curPackReleaseIds[game.releases[i].id] || false;
							game.releases[i].prevSelected = prevPackReleaseIds[game.releases[i].id] || false;
						}
						curPackReleaseIds = prevPackReleaseIds = null;

						$scope.pack.game = game;
					};
					$scope.resetReleases = function() {
						let prevPackReleaseIds = {};
						for(let i in prevPack.packs_releases) {
							prevPackReleaseIds[prevPack.packs_releases[i].release_id] = true;
						}
						for(let i in $scope.pack.game.releases) {
							$scope.pack.game.releases[i].selected = $scope.pack.game.releases[i].prevSelected = prevPackReleaseIds[$scope.pack.game.releases[i].id] || false;
						}
					};

					/* Songs */
					function isSongModified(song) {
						if(song.isNew)
							return false;

						if(song.track != song.prevTrack)
							return true;
						
						if(song.filename != song.prevFilename)
							return true;

						for(let i in song.song_staff) {
							if(song.song_staff[i].isDeleted || song.song_staff[i].isNew) {
								return true;
							}
							if(!song.song_staff[i].role && song.song_staff[i].prevRoleId)
								return true;
							if(song.song_staff[i].role && !song.song_staff[i].prevRoleId)
								return true;
							if(song.song_staff[i].role.id != song.song_staff[i].prevRoleId)
								return true;
							if(song.song_staff[i].comment != song.song_staff[i].prevComment)
								return true;
						}

						for(let i in song.song_chips) {
							if(song.song_chips[i].isDeleted || song.song_chips[i].isNew) {
								return true;
							}
							if(song.song_chips[i].multiplier != song.song_chips[i].prevMultiplier)
								return true;
							if(song.song_chips[i].clock != song.song_chips[i].prevClock)
								return true;
						}

						if(song.file.md5 != song.prevFileMd5)
							return true;

						return false;
					}
					function cleanSongStaffData(data) {
						return {
							person: angular.copy(data.person),
							role: {
								id: data.role.id || '',
								name: data.role.name || ''
							},
							comment: data.comment || ''
						};
					}
					function cleanSongChipData(data) {
						return {
							chip: angular.copy(data.chip),
							multiplier: data.multiplier || '',
							clock: data.clock || ''
						};
					}
					function cleanSongData(data) {
						var song = {
							id: data.id,
							track: data.track || '',
							filename: data.filename || '',
							file: angular.copy(data.file),
							version: data.version,
							total_samples: data.total_samples,
							loop_samples: data.loop_samples,
							track_name_en: data.track_name_en,
							track_name_jp: data.track_name_jp,
							game_name_en: data.game_name_en,
							game_name_jp: data.game_name_jp,
							system_name_en: data.system_name_en,
							system_name_jp: data.system_name_jp,
							track_author_en: data.track_author_en,
							track_author_jp: data.track_author_jp,
							release_date: data.release_date,
							ripper: data.ripper,
							notes: data.notes,

							song_staff: [],
							song_chips: []
						};

						for(let i in data.song_staff) {
							var song_staff = data.song_staff[i];
							song.song_staff.push(cleanSongStaffData(song_staff));
						}

						for(let i in data.song_chips) {
							var song_chip = data.song_chips[i];
							song.song_chips.push(cleanSongChipData(song_chip));
						}

						return song;
					}
					function copyPrevSongStaffData(data, prevData) {
						if(!prevData)
							prevData = data;

						data.prevRoleId = prevData.role.id || '';
						data.prevRoleName = prevData.role.name || '';
						data.prevComment = prevData.comment || '';
					}
					function copyPrevSongChipData(data, prevData) {
						if(!prevData)
							prevData = data;

						data.prevMultiplier = prevData.multiplier || '';
						data.prevClock = prevData.clock || '';
					}
					function copyPrevSongData(data, prevData) {
						if(!prevData)
							prevData = data;

						data.prevTrack = prevData.track || '';
						data.prevFilename = prevData.filename || '';
						data.prevFileMd5 = prevData.file && prevData.file.md5 || '';
						data.prevFileSize = prevData.file && prevData.file.size || '';
						data.prevVersion = prevData.version || '';
						data.prevTotalSamples = prevData.total_samples || '';
						data.prevLoopSamples = prevData.loop_samples || '';

						let songStaff = {};
						for(let i in data.song_staff) {
							let song_staff = data.song_staff[i];
							if(song_staff.person.id) {
								songStaff[song_staff.person.id] = cleanSongStaffData(song_staff);
								copyPrevSongStaffData(songStaff[song_staff.person.id], song_staff);
								songStaff[song_staff.person.id].isNew = true;
							} else {
								console.error('Song staff without ID', song_staff);
							}
						}
						for(let i in prevData.song_staff) {
							let song_staff = prevData.song_staff[i];
							if(song_staff.person.id in songStaff) {
								songStaff[song_staff.person.id].isNew = false;
								copyPrevSongStaffData(songStaff[song_staff.person.id], song_staff);
							} else {
								songStaff[song_staff.person.id] = cleanSongStaffData(song_staff);
								copyPrevSongStaffData(songStaff[song_staff.person.id], song_staff);
								songStaff[song_staff.person.id].isDeleted = true;
							}
						}
						data.song_staff = [];
						for(let i in songStaff) {
							data.song_staff.push(songStaff[i]);
						}

						let songChips = {};
						for(let i in data.song_chips) {
							let song_chip = data.song_chips[i];
							if(song_chip.chip.id) {
								songChips[song_chip.chip.id] = cleanSongChipData(song_chip);
								copyPrevSongChipData(songChips[song_chip.chip.id], song_chip);
								songChips[song_chip.chip.id].isNew = true;
							} else {
								console.error('Song chip without ID', song_chip);
							}
						}
						for(let i in prevData.song_chips) {
							let song_chip = prevData.song_chips[i];
							if(song_chip.chip.id in songChips) {
								songChips[song_chip.chip.id].isNew = false;
							} else {
								songChips[song_chip.chip.id] = cleanSongChipData(song_chip);
								songChips[song_chip.chip.id].isDeleted = true;
							}
							copyPrevSongChipData(songChips[song_chip.chip.id], song_chip);
						}
						data.song_chips = [];
						for(let i in songChips) {
							data.song_chips.push(songChips[i]);
						}
					}
					$scope.preparePackSongs = function() {
						let existingSongs = {}, newSongs = {};
						for(let i in curPack.songs) {
							let song = curPack.songs[i];
							if(song.id) {
								existingSongs[song.id] = cleanSongData(song);
								copyPrevSongData(existingSongs[song.id]);
							} else {
								newSongs[song.file.md5] = cleanSongData(song);
								copyPrevSongData(newSongs[song.file.md5]);
							}
						}

						for(let i in prevPack.songs) {
							let song = prevPack.songs[i];
							if(song.id) {
								if(song.id in existingSongs) {
									copyPrevSongData(existingSongs[song.id], song);
								} else {
									existingSongs[song.id] = cleanSongData(song);
									copyPrevSongData(existingSongs[song.id]);
								}
							} else {
								console.error('Cannot have previous song without ID', song);
							}
						}

						var songs = [];
						for(let i in existingSongs)
							songs.push(existingSongs[i]);
						for(let i in newSongs)
							songs.push(newSongs[i]);

						$scope.pack.songs = songs;

						if($scope.curSongIndex >= $scope.pack.songs.length)
							$scope.curSongIndex = $scope.pack.songs.length - 1;
						$scope.setCurSong($scope.curSongIndex);
					};

					$scope.setCurSong = function(idx) {
						if($scope.pack.songs && $scope.pack.songs[idx]) {
							$scope.curSongIndex = idx;
							$scope.curSong = $scope.pack.songs[idx];
						} else {
							$scope.curSongIndex = $scope.curSong = null;
						}
					};

					/* Song Chips */
					$scope.addCurSongChip = function(chip) {
						if(!$scope.curSong.song_chips)
							$scope.curSong.song_chips = [];
						$scope.curSong.song_chips.push({
							chip: chip,
							multiplier: 1,
							comment: '',
							isNew: true
						});
						$scope.checkSongModified($scope.curSong);
					};
					$scope.removeCurSongChip = function(idx) {
						if(!$scope.curSong.song_chips)
							return;
						if(!$scope.curSong.song_chips[idx])
							return;
						if($scope.curSong.song_chips[idx].isNew) {
							$scope.curSong.song_chips.splice(idx, 1);
						} else {
							$scope.curSong.song_chips[idx].isDeleted = 1;
						}
						$scope.checkSongModified($scope.curSong);
					};
					$scope.restoreCurSongChip = function(idx) {
						$scope.curSong.song_chips[idx].isDeleted = 0;
						$scope.checkSongModified($scope.curSong);
					};
					$scope.resetCurSongChips = function() {
						for(let i = 0; i < $scope.curSong.song_chips.length; i++) {
							var song_chip = $scope.curSong.song_chips[i];
							song_chip.multiplier = song_chip.prevMultiplier;
							song_chip.clock = song_chip.prevClock;
							if(song_chip.isDeleted) song_chip.isDeleted = false;
							else if(song_chip.isNew) {
								$scope.curSong.song_chips.splice(i, 1);
								i--;
							}
						}
						$scope.checkSongModified($scope.curSong);
					};

					/* Song Staff */
					$scope.addCurSongStaff = function(person) {
						if(!$scope.curSong.song_staff)
							$scope.curSong.song_staff = [];
						$scope.curSong.song_staff.push({
							person: person,
							role: $scope.composerRole,
							comment: '',
							isNew: true
						});
						$scope.checkSongModified($scope.curSong);
					};
					$scope.removeCurSongStaff = function(aIdx) {
						if(!$scope.curSong.song_staff)
							return;
						if(!$scope.curSong.song_staff[aIdx])
							return;
						if($scope.curSong.song_staff[aIdx].isNew)
							$scope.curSong.song_staff.splice(aIdx, 1);
						else
							$scope.curSong.song_staff[aIdx].isDeleted = true;
						$scope.checkSongModified($scope.curSong);
					};
					$scope.restoreCurSongStaff = function(idx) {
						if(!$scope.curSong.song_staff)
							return;
						if(!$scope.curSong.song_staff[idx])
							return;
						$scope.curSong.song_staff[idx].isDeleted = false;
						$scope.checkSongModified($scope.curSong);
					};
					$scope.resetCurSongStaff = function() {
						for(let i = 0; i < $scope.curSong.song_staff.length; i++) {
							var song_staff = $scope.curSong.song_staff[i];
							if(song_staff.isNew) {
								$scope.curSong.song_staff.splice(i, 1);
								i--;
							} else {
								var song_staff = $scope.curSong.song_staff[i];
								song_staff.role = {
									id: song_staff.prevRoleId,
									name: song_staff.prevRoleName
								};
								song_staff.comment = song_staff.prevComment;
								if(song_staff.isDeleted) song_staff.isDeleted = false;
							}
						}
						$scope.checkSongModified($scope.curSong);
					};
					var loadFile = function(file) {
						return $q((resolve, reject) => {
							if(!file) reject('No file specified');
							vgmPlayer.getPlayer().then(function(player) {
								player.getVgmInfo(file, (data) => {
									var song = {
										blob: file,
										filename: file.name,
										file: {
											size: file.size,
											md5: ''
										}
									};
									var m = file.name.match(/^([0-9][0-9]*[a-z]?)(?: - | +|_)(?<song>.*?)\.vg[mz]$/);
									if(m) {
										song.track = m ? m[1] : idx + 1;
										song.filename = m[2];
									}
									var props = [
										'track_name_en', 'track_name_jp',
										'game_name_en', 'game_name_jp',
										'system_name_en', 'system_name_jp',
										'track_author_en', 'track_author_jp',
										'ripper', 'release_date', 'notes',
										'total_samples', 'loop_samples',
										'version'
									];
									for(var i in props)
										song[props[i]] = data[props[i]];
									song.file.md5 = data.md5;

									var promises = {};

									song.song_staff = [];
									var enAuthors = data.track_author_en.split(/[,、]/).map(x => x.trim());
									var jpAuthors = data.track_author_jp.split(/[,、]/).map(x => x.trim());
									promises.people = api.get('people', {
										fields: [ 'id', 'url', 'name', 'name_en', 'name_jp', 'mugshot_ext' ],
										where: {
											conditions: [
												{
													type: 'or',
													value: [
														{ field: 'name_en', type: 'in', value: enAuthors },
														{ field: 'name_jp', type: 'in', value: jpAuthors }
													]
												}
											]
										}
									});

									promises.staff_roles = api.get('staff_roles', {
										fields: [ 'id', 'name' ],
										where: {
											conditions: [ { field: 'name', value: 'Composer' } ]
										}
									});

									song.song_chips = [];
									let uniqueChips = {};
									for(let j in data.chips) {
										if(data.chips[j].name in uniqueChips) {
											uniqueChips[data.chips[j].name].multiplier++;
										} else {
											uniqueChips[data.chips[j].name] = data.chips[j];
											uniqueChips[data.chips[j].name].multiplier = 1;
										}
									}
									let chipNames = Object.keys(uniqueChips);
									promises.chips = api.get('chips', {
										fields: [ 'id', 'name', 'icon_ext', 'url' ],
										where: {
											conditions: [
												{
													type: 'or',
													value: [
														{ field: 'name', type: 'in', value: chipNames },
														{ field: 'friendly_name', type: 'in', value: chipNames },
														{ field: 'tech_name', type: 'in', value: chipNames },
														{ field: 'short_name', type: 'in', value: chipNames }
													]
												}
											]
										}
									});

									$q.all(promises).then((responses) => {
										var song_staff = [];
										if(responses.people && responses.people.results && responses.people.results[0]) {
											for(var i in responses.people.results) {
												song.song_staff.push({
													comment: '',
													person: responses.people.results[i],
													role: responses.staff_roles && responses.staff_roles.results && responses.staff_roles.results[0] || null
												});
											}
										}

										song.song_chips = [];
										if(responses.chips && responses.chips.results && responses.chips.results[0]) {
											for(let i in responses.chips.results) {
												var result = responses.chips.results[i];
												if(result.name in uniqueChips) {
													uniqueChips[result.name].chip = result;
													song.song_chips.push(uniqueChips[result.name]);
												} else if(result.friendly_name in uniqueChips) {
													uniqueChips[result.friendly_name].chip = result;
													song.song_chips.push(uniqueChips[result.friendly_name]);
												} else if(result.tech_name in uniqueChips) {
													uniqueChips[result.tech_name].chip = result;
													song.song_chips.push(uniqueChips[result.tech_name]);
												} else if(result.short_name in uniqueChips) {
													uniqueChips[result.short_name].chip = result;
													song.song_chips.push(uniqueChips[result.short_name]);
												} else {
													// no match
												}
											}
										}

										resolve(song);
									}, (err) => {
										Notification.error(err);
									});

								}, (err) => {
									reject('Error with ' + file.name + ': ' + err);
								});
							});
						});
					};

					$scope.addSongs = function(files) {
						for(var i in files) {
							loadFile(files[i]).then((song) => {
								for(var j in $scope.pack.songs) {
									if($scope.pack.songs[j].file.md5 == song.file.md5) {
										Notification.error('File already loaded or is identical: '+song.filename);
										return;
									}
								}
								var cleanData = cleanSongData(song);
								$scope.pack.songs.push(cleanData);
								copyPrevSongData($scope.pack.songs[$scope.pack.songs.length - 1]);
								$scope.pack.songs[$scope.pack.songs.length - 1].isNew = true;
								$scope.setCurSong($scope.pack.songs.length - 1);
							}, (err) => {
								Notification.error(err);
							});
						}
					};

					$scope.replaceCurSongFile = function(file) {
						if(!file) return;
						loadFile(file).then((song) => {
							for(var j in $scope.pack.songs) {
								if($scope.pack.songs[j].file.md5 == song.file.md5) {
									Notification.error('File already loaded or is identical: '+song.filename);
									return;
								}
							}

							$scope.resetCurSong();
							$scope.pack.songs[$scope.curSongIndex] = cleanSongData(song);
							copyPrevSongData($scope.pack.songs[$scope.curSongIndex], $scope.curSong);
							$scope.pack.songs[$scope.curSongIndex].id = $scope.curSong.id;
							$scope.setCurSong($scope.curSongIndex);
							$scope.checkSongModified($scope.curSong);
						}, (err) => {
							Notification.error(err);
						});
					};
					$scope.deleteCurSong = function() {
						if(!$scope.curSong)
							return;

						if($scope.curSong.id) {
							$scope.curSong.isDeleted = 1;
							for(var j = $scope.curSongIndex + 1; j < $scope.pack.songs.length; j++) {
								if(!$scope.pack.songs[j].isDeleted) {
									$scope.setCurSong(j);
									return;
								}
							}
							for(var j = 0; j < $scope.curSongIndex; j++) {
								if(!$scope.pack.songs[j].isDeleted) {
									$scope.setCurSong(j);
									return;
								}
							}
						} else {
							$scope.pack.songs.splice($scope.curSongIndex, 1);
							for(var j = $scope.curSongIndex; j < $scope.pack.songs.length; j++) {
								if(!$scope.pack.songs[j].isDeleted) {
									$scope.setCurSong(j);
									return;
								}
							}
							for(var j = 0; j < $scope.curSongIndex; j++) {
								if(!$scope.pack.songs[j].isDeleted) {
									$scope.setCurSong(j);
									return;
								}
							}
						}
						$scope.setCurSong(-1);
					};
					$scope.restoreCurSong = function() {
						$scope.curSong.isDeleted = false;
						for(let j = $scope.curSongIndex + 1; j < $scope.pack.songs.length; j++) {
							if($scope.pack.songs[j].isDeleted) {
								$scope.setCurSong(j);
								return;
							}
						}
						for(let j = 0; j < $scope.curSongIndex; j++) {
							if($scope.pack.songs[j].isDeleted) {
								$scope.setCurSong(j);
								return;
							}
						}
					};
					$scope.checkSongModified = function(song) {
						song.isModified = isSongModified(song);
					};
					$scope.resetCurSong = function() {
						$scope.curSong.track = $scope.curSong.prevTrack;
						$scope.curSong.filename = $scope.curSong.prevFilename;
						$scope.curSong.file.md5 = $scope.curSong.prevFileMd5;
						$scope.curSong.file.size = $scope.curSong.prevFileSize;
						$scope.curSong.version = $scope.curSong.prevVersion;
						$scope.curSong.total_samples = $scope.curSong.prevTotalSamples;
						$scope.curSong.loop_samples = $scope.curSong.prevLoopSamples;

						$scope.resetCurSongStaff();
						$scope.resetCurSongChips();
					};
					$scope.resetSongs = function() {
						$scope.preparePackSongs();
					};

					/* Pack M3U */
					$scope.setCurM3u = function(idx) {
						$scope.curM3u = $scope.pack.pack_m3u[idx];
					};
					$scope.addM3u = function(idx) {
						$scope.pack.pack_m3u.push({
							name: '',
							songs: []
						});
						$scope.curM3u = $scope.pack.pack_m3u[$scope.pack.pack_m3u.length - 1];
					};
					$scope.addCurM3uSong = function() {
						var selectedSongs = {};
						for(var i = 0; i < $scope.curM3u.songs.length; i++) {
							selectedSongs[$scope.curM3u.songs[i].song.filename] = true;
						}
						var newSongFilename = '';
						for(var i in $scope.pack.songs) {
							if(!($scope.pack.songs[i].filename in selectedSongs)) {
								newSongFilename = $scope.pack.songs[i].filename;
								break;
							}
						}
						$scope.curM3u.songs.push({
							song: {
								filename: newSongFilename,
							},
							title: ''
						});
					};
					$scope.removeCurM3uSong = function(idx) {
						$scope.curM3u.songs.splice(idx, 1);
					};
					$scope.moveCurM3uSongUp = function(idx) {
						if(idx > 0) {
							[$scope.curM3u.songs[idx], $scope.curM3u.songs[idx - 1]] = [$scope.curM3u.songs[idx - 1], $scope.curM3u.songs[idx]];
						}
					}
					$scope.moveCurM3uSongDown = function(idx) {
						if(idx < $scope.curM3u.songs.length - 1) {
							[$scope.curM3u.songs[idx], $scope.curM3u.songs[idx + 1]] = [$scope.curM3u.songs[idx + 1], $scope.curM3u.songs[idx]];
						}
					}
					$scope.removeCurM3u = function() {
						for(var i in $scope.pack.pack_m3u) {
							if($scope.pack.pack_m3u[i] == $scope.curM3u) {
								$scope.pack.pack_m3u.splice(i, 1);
								if(i > 0) {
									$scope.curM3u = $scope.pack.pack_m3u[i-1];
								} else if(i < $scope.pack.pack_m3u.length) {
									$scope.curM3u = $scope.pack.pack_m3u[i];
								} else {
									$scope.curM3u = null;
								}
								break;
							}
						}
					}

					/* Saving */
					$scope.save = function() {
						if(!$scope.pack.title) {
							Notification.error('Please specify pack title');
						} else if(!$scope.pack.url) {
							Notification.error('Please specify pack URL slug');
						} else {
							var modal = $uibModal.open({
								template: require('./partials/packs/pack-save.html'),
								size: 'lg',
								backdrop: 'static',
								controller: [
									'$scope', '$uibModalInstance', 'api', 'Notification', 'user', '$state',
									function(modalScope, $uibModalInstance, api, Notification, user, $state) {
										modalScope.pack_edits = $scope.pack.pack_edits;
										var version = 0.0;
										for(var i in modalScope.pack_edits) {
											var ver = modalScope.pack_edits[i].version;
											if(ver && parseFloat(ver) > version)
												version = parseFloat(ver);
										}
										version += 1.0;
										version = Math.floor(version * 100).toString(10);
										version = version.substring(0, version.length - 2) + '.' + version.substring(version.length - 2, version.length);
										var newPack = getPackData($scope.pack);
										var beforeJson = JSON.stringify($scope.prevPack, null, 2);
										var afterJson = JSON.stringify(newPack, null, 2);

										modalScope.edit = {
											user: { id: user.getUserData().id, username: user.getUserData().username },
											version: version,
											description: '',
											before: $scope.prevPack,
											after: newPack,
											patch: Diff.structuredPatch($scope.prevPack&&$scope.prevPack.title||null, newPack.title, beforeJson, afterJson),
											diff: Diff.diffJson($scope.prevPack, newPack)
										};
										modalScope.view = 'diff';
										modalScope.ok = function() {
											if(!modalScope.edit.user || !modalScope.edit.user.id) {
												Notification.error('Please select a user for this update');
											} else if(!modalScope.edit.version) {
												Notification.error('Please input a version number for this update');
											} else if(!modalScope.edit.description) {
												Notification.error('Please enter a short description for this update');
											} else {
												api.post('pack_edits', {
													key: {
														id: null
													},
													data: {
														user_id: modalScope.edit.user.id,
														pack_id: $scope.pack.id,
														description: modalScope.edit.description,
														old: beforeJson,
														new: afterJson,
														version: modalScope.edit.version
													}
												}).then((result) => {
													Notification.success('Pack edit submitted to moderators');
													modalScope.edit.id = result.id;
													$uibModalInstance.close(modalScope.edit);
												}, (err) => {
													Notification.error(err);
												});
											}
										};
										modalScope.cancel = function() {
											$uibModalInstance.dismiss('cancel');
										};
									}
								]
							}).result.then((edit) => {
								$state.go('edit', { id: edit.id });
							}, (err) => {});
						}
					};
				}
			]
		})
		.state('pack-request', {
			url: '/pack-request?game',
			template: require('./partials/packs/request.html'),
			controller: [
				'$scope', '$stateParams', 'api',
				function($scope, $stateParams, api) {
					$scope.loadGame = function(url) {
						console.log('loadGame', url);
						api.get('games', {
							fields: [ 'id', 'url', 'name_en', 'name_jp', 'num_packs' ],
							foreign: {
								releases: {
									fields: [ 'id', 'order', 'title', 'title_en', 'territory_code', 'release_date' ],
									foreign: {
										platform: {
											fields: [ 'id', 'name', 'name_en', 'name_jp' ]
										},
										publisher: {
											fields: [ 'id', 'name', 'name_en', 'name_jp' ]
										}
									}
								}
							},
							where: {
								conditions: [ { field: 'url', value: url } ]
							}
						}).then(function(response) {
							if(response.results && response.results.length > 0) {
								$scope.game = response.results[0];
								if(!$scope.game.name)
									$scope.game.name = $scope.game.name_en
							}
						});
					};

					$scope.game = {
						name: '',
						releases: []
					};

					if($stateParams.game) {
						$scope.loadGame($stateParams.game);
					}

					$scope.getGames = function(val) {
						return api.get('games', {
							fields: [ 'id', 'url', 'name_en', 'name_jp' ],
							where: {
								conditions: [
									{ type: 'or', value: [
										{ field: 'name_jp', type: 'like', value: val + '%' },
										{ field: 'name_en', type: 'like', value: val + '%' }
									]}
								]
							},
							limit: 10
						}).then(function(response) {
							return response.results;
						});
					};

					$scope.addRelease = function() {
						$scope.game.releases.push({name:$scope.game.name});
					};
				}
			]
		})
		.state('edit', {
			url: '/edit/:id',
			template: require('./partials/edits/edit.html'),
			controller: [
				'$scope', '$stateParams', 'api',
				function($scope, $stateParams, api) {
					api.get('pack_edits', {
						fields: [
							'id',
							'description',
							'new',
							'old',
							'status',
							'version',
							'approval_date',
							'submission_date'
						],
						foreign: {
							user: {
								fields: [ 'id', 'username' ]
							},
							pack: {
								fields: [ 'id', 'url', 'title' ]
							}
						},
						where: {
							conditions: [
								{ field: 'id', value: $stateParams.id }
							]
						},
						limit: 1
					}).then(function(response) {
						var edit = response.results[0];
						var oldData = JSON.parse(edit.old);
						var newData = JSON.parse(edit.new);
						edit.patch = Diff.structuredPatch(oldData&&oldData.title, newData&&newData.title, edit.old||'', edit.new||'');
						$scope.edit = edit;
					});
				}
			]
		})
		.state('edits', {
			url: '/edits?user_id',
			template: require('./partials/edits/edits.html'),
			controller: [
				'$scope', '$stateParams', 'api',
				function($scope, $stateParams, api) {
					$scope.pagination = { curPage: 1, limit: 12, total : 0 };
					$scope.getEdits = function() {
						var where = { conditions: [], foreign: {} };
						if($scope.q) {
							where.conditions.push({
								type: 'or',
								value: [
									{ field: 'description', type: 'like', value: '%' + $scope.q + '%' },
									{ field: 'user.username', type: 'like', value: '%' + $scope.q + '%' },
									{ field: 'pack.title', type: 'like', value: '%' + $scope.q + '%' },
								]
							});
						}
						if($stateParams.user_id) {
							api.get('users', {
								fields: [ 'id', 'username' ],
								where: {
									conditions: [
										{ field: 'id', type: '=', value: $stateParams.user_id }
									]
								}
							}).then(function(response) {
								$scope.user = response.results[0];
							});
							where.foreign.user = {
								conditions: [
									{
										field: 'id',
										type: '=',
										value: $stateParams.user_id
									}
								]
							};
						}
						api.get('pack_edits', {
							fields: [
								'id',
								'description',
								'new',
								'old',
								'status',
								'version',
								'approval_date',
								'submission_date'
							],
							foreign: {
								user: {
									fields: [ 'id', 'username', 'avatar_ext' ]
								},
								pack: {
									fields: [ 'id', 'url', 'title' ]
								}
							},
							calc_found_rows: true,
							where: where,
							order_by: [ 'id:DESC' ],
							skip: ($scope.pagination.curPage - 1) * 12,
							limit: 12
						}).then(function(response) {
							$scope.edits = response.results;
							$scope.pagination.total = parseInt(response.total);
							$scope.pagination.skip = parseInt(response.skip);
							$scope.pagination.limit = parseInt(response.limit);
						});
					}
					$scope.$watch('pagination.curPage', function(a, b) {
						$scope.getEdits();
					});
					$scope.$watch('q', function(a, b) {
						if($scope.pagination.curPage != 1)
							$scope.pagination.curPage = 1;
						else
							$scope.getEdits();
					});
				}
			]
		})
		;
}])
;
