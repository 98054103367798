import angular from 'angular';
import 'angular-ui-router';
import 'angular-storage';
import 'angular-jwt';
import 'angular-ui-bootstrap';
import 'angular-ui-notification';
import 'ng-file-upload';
import 'angular-sanitize';
import sqlHilite from './sql';
import 'angular-json-tree';

import './less/style.less';
import 'angular-ui-notification/dist/angular-ui-notification.css';

angular.module('app', [
	'ui.router',
	'angular-storage',
	'angular-jwt',
	'ui.bootstrap',
	'ui-notification',
	'ngFileUpload',
	'ngSanitize',
	'angular-json-tree'
])
.service('api', [
	'$http', '$httpParamSerializerJQLike', '$q', 'Upload',
	function($http, $httpParamSerializerJQLike, $q, Upload) {
	var apiBase = '/api/';
	var processSuccess = function(response, d, call, params) {
		if(response.data.queries) {
			for(var q in response.data.queries) {
				var hilite = sqlHilite(response.data.queries[q].query);
				var args = [];
				args.push(hilite.str + '\n%c%s');
				args.push.apply(args, hilite.colors);
				args.push('color:#555');
				args.push(response.data.queries[q].time);
				console.debug.apply(null, args);
			}
		}
		if(response.data.output) {
			console.info('%c%s', 'color: #898', response.data.output.replace(/(^\s+|\s+$)/, ''));
		}
		if(response.data.errors && response.data.errors.length > 0) {
			for(var i in response.data.errors) {
				var error = response.data.errors[i];
				console.error('API Error: %s %c%s:%d\n', error.errstr, 'color: #777', error.file, error.line, error.stack);
			}
			d.reject(response.data.errors[0].errstr);
		} else {
			d.resolve(response.data);
		}
	};
	var processRejection = function(err, d) {
		d.reject(err.data&&err.data.errors&&err.data.errors[0].errstr||JSON.stringify(err));
	};
	return {
		get: function(call, params) {
			var d = $q.defer();
			if(!params) params = {};
			var paramsJson = JSON.stringify(params);
			params._p = call;
			$http({
				method: 'GET',
				url: apiBase+call,
				params: { query: JSON.stringify(params) }
			}).then(function(response) {
				processSuccess(response, d, call, params);
			}, function(err) {
				processRejection(err, d);
			});
			return d.promise;
		},
		post: function(call, params) {
			var d = $q.defer();
			$http({
				method: 'POST',
				url: apiBase+call,
				headers: {'Content-Type': 'application/json'},
				data: params
			}).then(function(response) {
				processSuccess(response, d);
			}, function(err) {
				processRejection(err, d);
			});
			return d.promise;
		},
		upload: function(call, params) {
			var d = $q.defer();
			Upload.upload({
				url: apiBase+call,
				data: params
			}).then(function(response) {
				processSuccess(response, d);
			}, function(err) {
				processRejection(err, d);
			});
			return d.promise;
		},
		delete: function(call, params) {
			var d = $q.defer();
			$http({
				method: 'DELETE',
				url: apiBase+call,
				params: params,
				paramSerializer: $httpParamSerializerJQLike
			}).then(function(response) {
				processSuccess(response, d);
			}, function(err) {
				processRejection(err, d);
			});
			return d.promise;
		},
		markLoaded: function() {
			// signal that data is loaded
			if(/PhantomJS/.test(window.navigator.userAgent)) {
				console.log('__markLoaded__');
			}
		}
	};
}])
.config([
	'$stateProvider', '$urlRouterProvider', '$locationProvider', 'jwtOptionsProvider',
	function($stateProvider, $urlRouterProvider, $locationProvider, jwtOptionsProvider) {
		$locationProvider.html5Mode(true);
		$urlRouterProvider.otherwise("/");
		jwtOptionsProvider.config({ whiteListedDomains: [ 'localhost' ] });
		$stateProvider
			.state('home', {
				url: '/',
				template: require('./partials/home.html'),
				controller: [
					'$scope', '$uibModal', '$stateParams', 'api', '$q',
					function($scope, $uibModal, $stateParams, api, $q) {
						$q.all([
							api.get('statistics', {}),
							api.get('topics', {
								fields: ['id', 'url', 'title', 'desc', 'posted_at', 'sticky', 'num_posts'],
								foreign: {
									forum: {
										fields: [ 'url', 'name' ]
									},
									poster: {
										fields: [ 'id', 'username' ]
									}
								},
								limit: 10,
								order_by: [ 'posted_at:DESC' ]
							}),
							api.get('topics', {
								fields: ['id', 'url', 'title', 'desc', 'posted_at', 'sticky', 'num_posts'],
								foreign: {
									forum: {
										fields: [ 'url', 'name' ]
									},
									poster: {
										fields: [ 'id', 'username' ]
									}
								},
								limit: 10,
								order_by: [ 'posted_at:DESC' ],
								where: {
									foreign: {
										forum: {
											conditions: [ { field: 'url', type: '=', value: 'news-and-updates' } ]
										}
									}
								}
							}),
							api.get('packs', {
								fields: [ 'url', 'title', 'num_ratings', 'rating', 'rating_sort', 'num_songs' ],
								order_by: [ 'rating_sort:DESC' ],
								limit: 10
							})
						]).then(function(responses) {
							$scope.statistics = responses[0].statistics;
							$scope.latestTopics = responses[1].results;
							$scope.newsTopics = responses[2].results;
							$scope.topPacks = responses[3].results;
							api.markLoaded();
						});
						$scope.postsToPage = function(p) { return Math.floor(p / 10); };
					}
				]
			})
			.state('directives', {
				url: '/directives',
				template: require('./partials/directives.html')
			})
			.state('statistics', {
				url: '/statistics',
				template: require('./partials/statistics.html'),
				controller: [
					'$scope', '$uibModal', '$stateParams', 'api',
					function($scope, $uibModal, $stateParams, api) {
						api.get('statistics/advanced', {
						}).then(function(response) {
							$scope.statistics = response.statistics;
						});
					}
				]
			})
			;
	}
])
.directive('eatClickIf', ['$parse', '$rootScope',
	function($parse, $rootScope) {
		return {
			// this ensure eatClickIf be compiled before ngClick
			priority: 100,
			restrict: 'A',
			compile: function($element, attr) {
				var fn = $parse(attr.eatClickIf);
				return {
					pre: function link(scope, element) {
						var eventName = 'click';
						element.on(eventName, function(event) {
							var callback = function() {
								if (fn(scope, {$event: event})) {
									// prevents ng-click to be executed
									event.stopImmediatePropagation();
									// prevents href
//									event.preventDefault();
									return false;
								}
							};
							if ($rootScope.$$phase) {
								scope.$evalAsync(callback);
							} else {
								scope.$apply(callback);
							}
						});
					},
					post: function() {}
				}
			}
		}
	}
])
.directive('pagination', function() {
	return {
		restrict: 'E',
		scope: {
			model: '=',
			total: '=',
			limit: '=',
			change: '='
		},
		controller: [ '$scope', '$http', function($scope, $http) {
			$scope.getPages = function() {
				return Math.floor((parseInt($scope.total) + parseInt($scope.limit) - 1) / parseInt($scope.limit));
			};
			$scope.next = function() {
				var p = $scope.model;
				p++;
				if(p > $scope.getPages()) p = $scope.getPages();
				$scope.model = p;
			};
			$scope.prev = function() {
				var p = $scope.model;
				p--;
				if(p < 1) p = 1;
				$scope.model = p;
			};
			$scope.first = function() {
				$scope.model = 1;
			};
			$scope.last = function() {
				$scope.model = $scope.getPages();
			};
		}],
		template: '\
			<div class="input-group"> \
				<span class="input-group-btn"> \
					<button class="btn btn-default" type="button" ng-click="first()">&nbsp;&laquo;&nbsp;</button> \
					<button class="btn btn-default" type="button" ng-click="prev()">&nbsp;&lsaquo;&nbsp;</button> \
				</span> \
				<input type="text" class="form-control" placeholder="#" ng-model="model"> \
				<span class="input-group-addon"> / {{getPages()}}</span> \
				<span class="input-group-btn"> \
					<button class="btn btn-default" type="button" ng-click="next()">&nbsp;&rsaquo;&nbsp;</button> \
					<button class="btn btn-default" type="button" ng-click="last()">&nbsp;&raquo;&nbsp;</button> \
				</span> \
			</div>'
	};
})
.run([
	'$rootScope', '$state', 'store', 'jwtHelper', 'Hotkeys', 'Random',
	function($rootScope, $state, store, jwtHelper, Hotkeys, Random) {
		$rootScope.$on('$stateChangeStart', function(e, to) {
			if (to.data && to.data.requiresLogin) {
				if (!store.get('jwt') || jwtHelper.isTokenExpired(store.get('jwt'))) {
					e.preventDefault();
					$state.go('login');
				}
			}
		});

		Hotkeys.register('r', () => { Random.goToRandomPack(); }, 'Go to random pack');
		Hotkeys.register('R', () => { Random.goToRandomGame(); }, 'Go to random game');
		Hotkeys.register('s', () => { document.getElementById('search').focus(); }, 'Search');
		Hotkeys.registerGo('c', () => { $state.go('companies'); }, 'Navigate to Companies');
		Hotkeys.registerGo('e', () => { $state.go('people'); }, 'Navigate to People');
		Hotkeys.registerGo('f', () => { $state.go('forums'); }, 'Navigate to Forum');
		Hotkeys.registerGo('g', () => { $state.go('games'); }, 'Navigate to Games');
		Hotkeys.registerGo('h', () => { $state.go('home'); }, 'Navigate to Home');
		Hotkeys.registerGo('i', () => { $state.go('chips'); }, 'Navigate to Chips');
		Hotkeys.registerGo('p', () => { $state.go('packs'); }, 'Navigate to Packs');
	}
])
.service('Random', [
	'api', '$state', (api, $state) => {
		return {
			goToRandomPack: () => {
				api.get('random/pack').then(function(response) {
					$state.go('pack', {url: response.url});
				});
			},
			goToRandomGame: () => {
				api.get('random/game').then(function(response) {
					$state.go('game', {url: response.url});
				});
			}
		}
	}
])
.service('Hotkeys', [
	'$uibModal', '$document',
	($uibModal, $document) => {
		var hotkeys = [];
		var goHotkeys = [];
		var modal = null;
		var goMode = false;
		$document.bind('keydown', function(ev) {
			if(ev.ctrlKey || ev.altKey)
				return;

			if(ev.target.tagName == 'INPUT' || ev.target.tagName == 'TEXTAREA')
				return;

			if(ev.key == '?') {
				if(modal) {
					modal.close();
					modal = null;
				} else {
					modal = $uibModal.open({
						size: 'md',
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="cancel()" aria-label="Close"><span aria-hidden="true">&times;</span></button> \
								<h3 class="modal-title">Keyboard Shortcuts</h3>\
							</div>\
							<form>\
							<table class="table table-compact">\
								<tr ng-repeat="hk in hotkeys">\
									<td><kbd>{{hk.key}}</kbd></td><td>{{hk.desc}}</td>\
								</tr> \
								<tr ng-repeat="hk in goHotkeys"><td><kbd>g</kbd>, <kbd>{{hk.key}}</kbd></td><td>{{hk.desc}}</td></tr> \
							</table>',
						controller: [ '$scope', ($scope) => {
							$scope.hotkeys = hotkeys;
							$scope.goHotkeys = goHotkeys;
						}]
					});
					modal.result.then(() => {}, () => {});
				}
				ev.preventDefault();
				ev.stopPropagation();
				return;
			}

			if(goMode) {
				for(var i in goHotkeys) {
					if(goHotkeys[i].key == ev.key) {
						goHotkeys[i].cb(ev.key);
					}
				}
				ev.preventDefault();
				ev.stopPropagation();
				goMode = false;

				if(modal) {
					modal.close();
					modal = null;
				}
			} else {
				var found = false;
				if(ev.key == 'g') {
					goMode = true;
				} else {
					for(var i in hotkeys) {
						if(hotkeys[i].key == ev.key) {
							found = true;
							hotkeys[i].cb(ev.key);
						}
					}
					if(found) {
						ev.preventDefault();
						ev.stopPropagation();
						if(modal) {
							modal.close();
							modal = null;
						}
					}
				}
			}
		});

		return {
			register: (key, cb, desc) => {
				hotkeys.push({ key: key, desc: desc, cb: cb });
			},
			registerGo: (key, cb, desc) => {
				goHotkeys.push({ key: key, desc: desc, cb: cb });
			}
		};
	}
])
.controller('navCtrl', [
	'$scope', '$rootScope', '$uibModal', 'user', 'api', '$state', 'Random',
	function($scope, $rootScope, $uibModal, user, api, $state, Random) {
	$scope.user = user.getUserData();
	$rootScope.$on('authorization:changed', function(ev, data) {
		$scope.user = user.getUserData();
	});

	$scope.goToRandomPack = () => Random.goToRandomPack();
	$scope.openLogin = function() {
		var modal = $uibModal.open({
			template: require('./partials/users/login.html'),
			backdrop: 'static',
			controller: [
				'$scope', 'user', '$uibModalInstance', 'Notification',
				function($scope, user, $uibModalInstance, Notification) {
					$scope.login = function() {
						user.login($scope.username, $scope.password).then(function() {
							$uibModalInstance.close();
						}, function(error) {
							Notification.error(error);
						});
					};

					$scope.cancel = function() {
						$uibModalInstance.dismiss('cancel');
					};
				}
			]
		}).result.then(function() {}, function() {});
	};
	$scope.openRegister = function() {
		var modal = $uibModal.open({
			template: require('./partials/users/register.html'),
			controller: ['$scope', 'user', '$uibModalInstance', function($scope, user, $uibModalInstance) {
				$scope.register = function() {
					user.register($scope.username, $scope.email, $scope.password).then(function() {
						$uibModalInstance.close();
					}, function(error) {
						$scope.error = error.data;
					});
				};

				$scope.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				};
			}]
		});
	};
	$scope.$watch('game', function(a, b) {
		if(a && a.url) {
			$state.go('game', { url: a.url });
			$scope.game = null;
			console.log('blurring');
			setTimeout(() => {
				document.getElementById('search').blur();
			}, 200);
		}
	});
	$scope.getGames = function(val) {
		return api.get('games', {
			fields: [ 'id', 'url', 'name_en', 'name_jp' ],
			where: {
				conditions: [
					{ type: 'or', value: [
						{ field: 'name_jp', type: 'like', value: val + '%' },
						{ field: 'name_en', type: 'like', value: val + '%' }
					]}
				]
			},
			limit: 10
		}).then(function(response) {
			return response.results;
		});
	};

	$scope.logout = function() {
		user.logout();
	};
}])
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('random', {
			url: "/random",
			controller: ['Random', function(Random) {
				Random.goToRandomPack();
			}],
		})
		.state('randomGame', {
			url: "/randomGame",
			controller: ['Random', function(Random) {
				Random.goToRandomGame();
			}],
		})
	;
}])
.directive('lipsum', function() {
	return {
		restrict: 'E',
		template: require('./partials/lipsum.html')
	};
})
.directive('starRating', function() {
	return {
		restrict: 'EA',
		template:
			'<ul class="star-rating" ng-class="{readonly: readonly}" uib-tooltip="{{ratingValue}}{{numRatings&&\', \'+numRatings+\' ratings\'}}" tooltip-placement="left" tooltip-class="success">' +
			'  <li class="clear" title="Clear" ng-click="clear()"><i class="glyphicon glyphicon-remove-sign"></i></li>' +
			'  <li ng-repeat="star in stars" class="star" ng-class="{filled: star.filled}" ng-click="toggle($index)">' +
			'    <i class="glyphicon glyphicon-star"></i>' + // or &#9733
			'  </li>' +
			'</ul>',
		scope: {
			ratingValue: '=ngModel',
			numRatings: '=?',
			max: '=?', // optional (default is 5)
			onRatingSelect: '&?',
			readonly: '=?'
		},
		link: function(scope, element, attributes) {
			if (typeof scope.max == 'undefined') {
				scope.max = 5;
			}
			function updateStars() {
				scope.stars = [];
				for (var i = 0; i < scope.max; i++) {
					scope.stars.push({
						filled: i < scope.ratingValue
					});
				}
			};
			scope.toggle = function(index) {
				if (scope.readonly == undefined || scope.readonly === false) {
					scope.ratingValue = index + 1;
					if(scope.onRatingSelect)
						scope.onRatingSelect({
							rating: index + 1
						});
				}
			};
			scope.clear = function() {
				if (scope.readonly == undefined || scope.readonly === false) {
					scope.ratingValue = 0;
				}
			};
			scope.$watch('ratingValue', function(oldValue, newValue) {
				updateStars();
			});
		}
	};
})
.filter('rawHtml', ['$sce', function($sce) {
	return function(val) {
		return $sce.trustAsHtml(val);
	};
}])
.filter('samples2time', [function() {
	return function(val) {
		if(typeof val === 'undefined') return '--:--';
		var secs = val / 44100;
		var h = Math.floor(secs / 3600);
		var m = Math.floor(secs / 60) % 60;
		var s = Math.floor(secs) % 60;

		if(h > 0)
			return h + ':' + ('00'+m).slice(-2) + ':' + ('00'+s).slice(-2);

		return m + ':' + ('00'+s).slice(-2);
	};
}])
.filter('urlEscape', [function() {
	return function(val) {
		return encodeURIComponent(val);
	};
}])
;

require('./chips');
require('./companies');
require('./files');
require('./forum');
require('./messages');
require('./pages');
require('./games');
require('./releases');
require('./staff-roles');
require('./packs');
require('./people');
require('./platforms');
require('./player');
require('./playlists');
require('./series');
require('./genres');
require('./homebrew');
require('./requests');
require('./users');
require('./roles');
require('./groups');
require('./updates');
require('./db');
