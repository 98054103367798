angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('groups', {
			url: "/groups",
			template: require('./partials/groups/groups.html'),
			controller: ['$scope', 'api', function($scope, api) {
				$scope.groups = [];
				$scope.pagination = { curPage: 1, limit: 10, total : 0 }
				$scope.loadPage = function() {
					var where = {
						conditions: []
					};
					if($scope.q) {
						where.conditions = [
							{ field: 'name', type: 'like', value: $scope.q + '%' },
						];
					}
					api.get('groups', {
						calc_found_rows: true,
						fields: [ 'id', 'name', 'description', 'num_users', 'icon_ext' ],
						order_by: [ 'num_users:DESC' ],
						where: where,
						skip: ($scope.pagination.curPage - 1) * 10,
						limit: 10
					}).then(function(response) {
						$scope.groups = response.results;
						$scope.pagination.total = parseInt(response.total);
						$scope.pagination.skip = parseInt(response.skip);
						$scope.pagination.limit = parseInt(response.limit);
					});
				};
				$scope.$watch('pagination.curPage', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						$scope.loadPage();
					}
				});
				$scope.$watch('q', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						if($scope.pagination.curPage != 1)
							$scope.pagination.curPage = 1;
						else
							$scope.loadPage();
					}
				});
				$scope.loadPage();
			}],
		})
		.state('group', {
			url: '/group/:id',
			template: require('./partials/groups/group.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$state',
				function($scope, $stateParams, api, $state) {
					$scope.loadGroup = function(id) {
						api.get('groups', {
							fields: [
								'id',
								'name',
								'description',
								'num_users',
								'icon_ext',
							],
							foreign: {
								group_users: {
									foreign: {
										user: {
											fields: [ 'id', 'username', 'num_pack_edits', 'avatar_ext' ],
										}
									},
									limit: 10,
									order_by: [ 'user.num_pack_edits:DESC' ]
								}
							},
							where: {
								conditions: [
									{
										field: 'id',
										type: '=',
										value: $stateParams.id
									}
								]
							}
						}).then(function(response) {
							$scope.group = response.results[0];
						});
					};
					$scope.loadGroup($stateParams.id);
					$scope.afterSave = function(person) {
						$scope.loadGroup($stateParams.id);
					};
					$scope.afterDelete = function(person) {
						$state.go('groups');
					};
				}
			]
		})
		;
}])
.directive('groupSelect', function() {
	return {
		restrict: 'E',
		template: require('./partials/groups/group-select.html'),
		scope: {
			model: '=?'
		},
		controller: [
			'$scope', 'api', '$uibModal',
			function($scope, api, $uibModal) {
				$scope.group = $scope.model;
				$scope.$watch('group', function() {
					$scope.model = $scope.group;
				});
				$scope.$watch('model', function() {
					if(!$scope.group)
						$scope.group = $scope.model;
				});
				$scope.clear = function() {
					$scope.group = null;
				};
				// Autocomplete
				$scope.getGroups = function(val) {
					return api.get('groups', {
						fields: [ 'id', 'name', 'description' ],
						where: {
							conditions: [
								{
									type: 'or', value: [
										{ field: 'name', type: 'like', value: val + '%' },
									]
								}
							]
						},
						limit: 10
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	};
})
.directive('groupList', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=?'
		},
		controller: [
			'$scope', '$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/groups/group-list.html'),
						size: 'md',
						controller: [
							'$scope', 'api', '$uibModalInstance',
							function(modalScope, api, $uibModalInstance) {
								modalScope.q = '';
								modalScope.pagination = { curPage: 1, limit: 10, total : 0 }
								modalScope.$watch('q', function() {
									modalScope.getGroups();
								});
								modalScope.$watch('pagination.curPage', function(a, b) {
									modalScope.getGroups();
								});
								modalScope.selectGroup = function(idx) {
									$uibModalInstance.close(modalScope.groups[idx]);
								};
								modalScope.ok = function() {
									$uibModalInstance.close();
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
								modalScope.getGroups = function() {
									var where = {};
									if(modalScope.q) {
										where.conditions = [
											{
												type: 'or',
												value: [
													{ field: 'name', type: 'like', value: modalScope.q + '%' },
												]
											}
										];
									}
									api.get('groups', {
										calc_found_rows: true,
										fields: [
											'id',
											'name',
											'description',
										],
										where: where,
										order_by: [ 'name:DESC' ],
										skip: (modalScope.pagination.curPage - 1) * 10,
										limit: 10
									}).then(function(response) {
										modalScope.groups = response.results;
										modalScope.pagination.total = parseInt(response.total);
										modalScope.pagination.skip = parseInt(response.skip);
										modalScope.pagination.limit = parseInt(response.limit);
									});

								};
								$uibModalInstance.opened.then(function() {
									modalScope.getGroups();
								});
							}
						]
					}).result.then(function() {}, function() {});
					modal.result.then(function (selectedItem) {
						$scope.model = selectedItem;
					}, function () {

					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('groupEdit', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(groupId) {
					var modal = $uibModal.open({
						template: require('./partials/groups/group-edit.html'),
						size: 'md',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								modalScope.aliasTypes = [ 'Alias', 'Alt spelling', 'Long name', 'Short name', 'Team member' ];
								modalScope.genders = [ 'Male', 'Female' ];
								modalScope.groupTypes = [ 'Group', 'Alias' ];
								if(groupId) {
									api.get('groups', {
										fields: [
											'id',
											'name',
											'description',
											'icon_ext'
										],
										where: {
											conditions: [
												{ field: 'id', type: '=', value: groupId }
											]
										},

									}).then(function(response) {
										modalScope.group = response.results[0];
									});
								} else {
									modalScope.group = {};
								}
								modalScope.ok = function() {
									api.post('groups', {
										id: modalScope.group.id,
										name: modalScope.group.name,
										description: modalScope.group.description,
										icon_id: modalScope.group.icon ? modalScope.group.icon.id : null,
									}).then(function(response) {
										if(response.status == 200) {
											Notification.success('Group saved');
											$uibModalInstance.close();
											$scope.success({ group: modalScope.group });
										} else {
											if(response.errors[0])
												Notification.error(response.errors[response.errors.length - 1].errstr);
											else
												Notificaion.error('Error '+response.status+' '+response.statusText+' saving group');
										}
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then(function() {}, function() {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
.directive('groupDelete', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(groupId) {
					var modal = $uibModal.open({
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
									<span aria-hidden="true">&times;</span>\
								</button>\
								<h3 class="modal-title" ng-if="group.id">Please confirm</h3>\
							</div>\
							<div class="modal-body">\
								Are you sure you want to delete {{group.name_en||group.name||group.name_jp}}?<br/>\
								This group has:\
								<ul>\
									<li>{{group.num_games_developed}} developed games</li>\
									<li>{{group.num_games_published}} published games</li>\
									<li>{{group.num_platforms}} platforms manufactured</li>\
									<li>{{group.num_chips}} chips manufactured</li>\
								</ul>\
							</div>\
							<div class="modal-footer">\
								<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
								<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
							</div>',
						size: 'md',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(groupId) {
									api.get('groups', {
										fields: [
											'id',
											'name',
										],
										foreign: {
											alias_of: {
												fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ]
											}
										},
										where: {
											conditions: [
												{ field: 'id', type: '=', value: groupId }
											]
										},

									}).then(function(response) {
										modalScope.group = response.results[0];
									});
								}
								modalScope.ok = function() {
									api.delete('groups', {
										id: modalScope.group.id,
									}).then(function(response) {
										Notification.success('Group deleted');
										$uibModalInstance.close();
										$scope.success({ group: modalScope.group });
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
;
