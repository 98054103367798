angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('roles', {
			url: "/roles",
			template: require('./partials/roles/roles.html'),
			controller: [ '$scope', '$stateParams', 'api', function($scope, $stateParams, api) {
				$scope.roles = [];
				$scope.pagination = { curPage: 1, limit: 10, total : 0 };
				$scope.loadPage = function() {
					var where = { conditions: [], foreign: {} };
					if($scope.q) {
						where.conditions.push({
							type: 'or',
							value: [
								{ field: 'name', type: 'like', value: $scope.q + '%' },
							]
						});
					}
					api.get('roles', {
						calc_found_rows: true,
						fields: [ 'id', 'name' ],
						where: where,
						limit: 10,
						skip: ($scope.pagination.curPage - 1) * 10
					}).then(function(response) {
						$scope.roles = response.results;
						$scope.pagination.total = parseInt(response.total);
						$scope.pagination.skip = parseInt(response.skip);
						$scope.pagination.limit = parseInt(response.limit);
					});
				}
				$scope.$watch('pagination.curPage', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						$scope.loadPage();
					}
				});
				$scope.$watch('q', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						if($scope.pagination.curPage != 1)
							$scope.pagination.curPage = 1;
						else
							$scope.loadPage();
					}
				});
				$scope.loadPage();
			}]
		})
		.state('role', {
			url: '/role/:id',
			template: require('./partials/roles/role.html'),
			controller: [
				'$scope', '$stateParams', 'api', 'Notification',
				function($scope, $stateParams, api, Notification) {
					$scope.loadRole = function(userId) {
						api.get('roles/role', {
							id: userId
						}).then(function(response) {
							$scope.role = response.role;
							for(var i in response.tables) {
								var tblData = response.tables[i];
								tblData.access = $scope.role.data[tblData.name]&&$scope.role.data[tblData.name].access||'';
								tblData.read = $scope.role.data[tblData.name]&&$scope.role.data[tblData.name].read||'no';
								tblData.create = $scope.role.data[tblData.name]&&$scope.role.data[tblData.name].create||'no';
								tblData.edit = $scope.role.data[tblData.name]&&$scope.role.data[tblData.name].edit||'no';
								tblData.delete = $scope.role.data[tblData.name]&&$scope.role.data[tblData.name].delete||'no';
							}
							$scope.tables = response.tables;
						});
					};

					$scope.accessOptions = { '': 'Not set', enabled: 'Enabled', disabled: 'Disabled' };
					$scope.createOptions = { 'no': 'No', 'yes': 'Yes' };
					$scope.readOptions = { 'no': 'No', 'yes': 'Yes', 'own': 'Own', 'group': 'Group' };
					$scope.editOptions = { 'no': 'No', 'yes': 'Yes', 'own': 'Own', 'group': 'Group' };
					$scope.deleteOptions = { 'no': 'No', 'yes': 'Yes', 'own': 'Own', 'group': 'Group' };
					$scope.loadRole($stateParams.id);
					$scope.saveRole = function() {
						var data = {};
						for(var i in $scope.tables) {
							var tbl = $scope.tables[i];
							if(tbl.access == 'enabled') {
								data[tbl.name] = {
									access: 'enabled',
									create: tbl.create,
									read: tbl.read,
									edit: tbl.edit,
									delete: tbl.delete
								};
							} else if(tbl.access=='disabled') {
								data[tbl.name] = {
									access: 'disabled'
								};
							}
						}
						api.post('roles', {
							key: {
								id: $scope.role.id||null
							},
							data: {
								name: $scope.role.name,
								data: data
							}
						}).then(function(response) {
							Notification.success('role saved');
						}, function(err) {
							Notification.error(err);
						});
					};
				}
			]
		})
		;
}])
/* Select Role */
.directive('roleSelect', function() {
	return {
		restrict: 'E',
		template: require('./partials/roles/role-select.html'),
		scope: {
			model: '=?'
		},
		controller: [
			'$scope', 'api', '$uibModal',
			function($scope, api, $uibModal) {
				$scope.role = $scope.model;
				$scope.$watch('role', function() {
					$scope.model = $scope.role;
				});
				$scope.$watch('model', function() {
					if(!$scope.role)
						$scope.role = $scope.model;
				});
				$scope.clear = function() {
					$scope.role = null;
				};
				// Autocomplete
				$scope.getRoles = function(val) {
					return api.get('roles', {
						fields: [ 'id', 'name' ],
						where: {
							conditions: [
								{
									type: 'or',
									value: [
										{ field: 'name', type: 'like', value: val + '%' },
									]
								}
							]
						},
						limit: 10
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	};
})
.directive('roleList', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=?'
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/roles/role-list.html'),
						controller: [
							'$scope', 'api', '$uibModalInstance',
							function(modalScope, api, $uibModalInstance) {
								modalScope.q = '';
								modalScope.pagination = { curPage: 1, limit: 10, total : 0 }
								modalScope.$watch('q', function() {
									modalScope.getRoles();
								});
								modalScope.$watch('pagination.curPage', function(a, b) {
									modalScope.getRoles();
								});
								modalScope.selectRole = function(idx) {
									$uibModalInstance.close(modalScope.roles[idx]);
								};
								modalScope.ok = function() {
									$uibModalInstance.close();
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
								modalScope.getRoles = function() {
									var where = {};
									if(modalScope.q) {
										where.conditions = [
											{
												type: 'or',
												value: [
													{ field: 'name', type: 'like', value: modalScope.q + '%' }
												]
											}
										];
									}
									api.get('roles', {
										calc_found_rows: true,
										fields: [ 'id', 'name' ],
										where: where,
										order_by: [ 'name:DESC' ],
										skip: (modalScope.pagination.curPage - 1) * 10,
										limit: 10
									}).then(function(response) {
										modalScope.roles = response.results;
										modalScope.pagination.total = parseInt(response.total);
										modalScope.pagination.skip = parseInt(response.skip);
										modalScope.pagination.limit = parseInt(response.limit);
									});

								};
								$uibModalInstance.opened.then(function() {
									modalScope.getRoles();
								});
							}
						]
					});
					modal.result.then(function (selectedItem) {
						$scope.model = selectedItem;
					}, function () {

					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('roleEdit', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(roleId) {
					var modal = $uibModal.open({
						template: require('./partials/roles/role-edit.html'),
						size: 'lg',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(roleId) {
									api.get('roles', {
										fields: [
											'id',
											'name',
										],
										where: {
											conditions: [
												{ field: 'id', type: '=', value: roleId }
											]
										},

									}).then(function(response) {
										modalScope.role = response.results[0];
									});
								} else {
									modalScope.role = {};
								}
								modalScope.ok = function() {
									api.post('roles', {
										key: {
											id: modalScope.role.id||null
										},
										data: {
											name: modalScope.role.name,
											data: '{}'
										}
									}).then(function(response) {
										Notification.success('role saved');
										$uibModalInstance.close();
										$scope.success({ role: modalScope.role });
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
.directive('roleDelete', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(roleId) {
					$uibModal.open({
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
									<span aria-hidden="true">&times;</span>\
								</button>\
								<h3 class="modal-title" ng-if="role.id">Please confirm</h3>\
							</div>\
							<div class="modal-body">\
								Are you sure you want to delete {{role.name}}?<br/>\
							</div>\
							<div class="modal-footer">\
								<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
								<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
							</div>',
						size: 'md',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(roleId) {
									api.get('roles', {
										fields: [
											'id',
											'name',
										],
										where: {
											conditions: [
												{ field: 'id', type: '=', value: roleId }
											]
										},

									}).then(function(response) {
										modalScope.role = response.results[0];
									});
								}
								modalScope.ok = function() {
									api.delete('roles', {
										id: modalScope.role.id,
									}).then(function(response) {
										Notification.success('Role deleted');
										$uibModalInstance.close();
										$scope.success({ role: modalScope.role });
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then(function() {}, function() {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
;