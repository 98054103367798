angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('release', {
			url: '/game/:gameUrl/release/:id',
			template: require('./partials/releases/release.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$uibModal', '$state', 'Notification',
				function($scope, $stateParams, api, $uibModal, $state, Notification) {
					$scope.loadRelease = function() {
						api.get('games', {
							fields: [ 'id', 'url', 'name_en', 'name_jp', 'description', 'num_packs' ],
							foreign: {
								releases: {
									fields: [
										'id',
										'order',
										'title',
										'title_en',
										'territory_code',
										'release_date',
										'num_packs_releases'
									],
									foreign: {
										platform: {
											fields: [ 'url', 'name', 'name_en', 'name_jp' ]
										},
										publisher: {
											fields: [ 'url', 'name', 'name_en', 'name_jp' ]
										},
										release_companies: {
											fields: [ 'role', 'role_other' ],
											foreign: {
												company: {
													fields: [ 'url', 'name' ]
												}
											}
										},
										release_staff: {
											fields: [ 'comment' ],
											foreign: {
												person: {
													fields: [ 'url', 'name', 'name_en', 'name_jp' ]
												},
												release_staff_roles: {
													fields: [ 'comment' ],
													foreign: {
														role: {
															fields: [ 'id', 'name' ]
														}
													}
												}
											}
										},
										screenshots: {
											fields: [ 'file_md5', 'order', 'filename', 'title' ],
											order_by: [ 'order' ]
										},
										packs_releases: {
											foreign: {
												pack: {
													fields: [
														'id',
														'url',
														'title',
														'description',
														'rating',
														'num_ratings',
														'rating_sort'
													],
													foreign: {
														game: {
															fields: [ 'id', 'url', 'name_en', 'name_jp', 'num_packs' ]
														},
														pack_chips: {
															foreign: {
																chip: {
																	fields: [ 'url', 'name' ]
																}
															}
														},
														pack_authors: {
															fields: [ 'role' ],
															foreign: {
																author: {
																	fields: [ 'id', 'username', 'avatar_ext' ]
																}
															}
														}
													},
													order_by: [ 'rating_sort:DESC' ]
												}
											}
										}
									},
									where: {
										conditions: [
											{
												field: 'id',
												value: $stateParams.id
											}
										]
									},
									order_by: [ 'order' ]
								},
							},
							where: {
								conditions: [
									{
										field: 'url',
										type: '=',
										value: $stateParams.gameUrl
									}
								]
							}
						}).then(function(response) {
							if(response.results && response.results[0]) {
								$scope.game = response.results[0];
								$scope.release = $scope.game.releases[0];
							} else {
								$scope.error = 'Not found';
							}
						}, function(err) {
							$scope.error = err;
						});
					}
					$scope.loadRelease();
					$scope.afterSave = function(game) {
						if(game.url != $stateParams.url)
							$state.go('game', { url: game.url });
						else
							$scope.loadGame();
					};
					$scope.afterDelete = function(game) {
						$state.go('games');
					};
					$scope.jp = false;

					var cleanTitle = (str) => { return str.replace(/\.[a-z]+$/, '').replace(/_/g, ' '); };
					$scope.addReleaseScreenshots = (files) => {
						if(files.length == 1) {
							$scope.openScreenshotEdit($scope.release.id, null, files[0]);
						} else {
							
						}
					};
					$scope.openScreenshotEdit = (releaseId, fileId, file) => {
						$uibModal.open({
							template: require('./partials/games/screenshot-edit.html'),
							backdrop: 'static',
							size: 'md',
							controller: [
								'$scope', '$uibModalInstance', 'Notification',
								function(modalScope, $uibModalInstance, Notification) {
									if(releaseId && fileId) {
										api.get('screenshots', {
											fields: [ 'release_id', 'order', 'title', 'description', 'filename', 'file_md5' ],
											foreign: {
												release: {
													fields: [ 'id' ],
													foreign: {
														game: {
															fields: [ 'id', 'url', 'name_en', 'name_jp' ],
															foreign: {
																releases: {
																	fields: [ 'id', 'order', 'release_date', 'title_en', 'title', 'territory_code' ],
																	foreign: {
																		platform: {
																			fields: [ 'url', 'name_en', 'name_jp', 'short_name' ]
																		},
																		publisher: {
																			fields: [ 'url', 'name_en', 'name_jp' ]
																		}
																	},
																	order_by: [ 'order' ]
																}
															}
														}
													}
												}
											},
											where: {
												conditions: [
													{ field: 'release_id', value: releaseId },
													{ field: 'file_md5', value: fileId }
												]
											}
										}).then(function(response) {
											console.log('got screenshot', response);
											modalScope.screenshot = response.results[0];
											if(file) {
												modalScope.screenshot.imageBlob = file;
												modalScope.screenshot.title = cleanTitle(file.name);
											}
											modalScope.initialScreenshotFilename = modalScope.screenshot.filename;
											modalScope.releases = modalScope.screenshot.release.game.releases;
										});
									} else if(releaseId) {
										api.get('releases', {
											fields: [ 'id' ],
											foreign: {
												screenshots: {
													fields: [ 'id', 'order' ]
												},
												game: {
													fields: [ 'id', 'url', 'name_en', 'name_jp' ],
													foreign: {
														releases: {
															fields: [ 'id', 'order', 'release_date', 'title_en', 'title', 'territory_code' ],
															foreign: {
																platform: {
																	fields: [ 'url', 'name_en', 'name_jp', 'short_name' ]
																},
																publisher: {
																	fields: [ 'url', 'name_en', 'name_jp' ]
																}
															},
															order_by: [ 'order' ]
														}
													}
												}
											},
											where: {
												conditions: [
													{ field: 'id', value: releaseId }
												]
											}
										}).then(function(response) {
											var release = response.results[0];
											var maxOrder = 1;
											for(var i in release.screenshots)
												if(release.screenshots[i].order > maxOrder)
													maxOrder = release.screenshots[i].order;
											modalScope.screenshot = {
												file_md5: null,
												order: maxOrder + 1,
												imageBlob: file || null,
												filename: file && file.name || null,
												title: file && file.name ? cleanTitle(file.name) : null
											};
											modalScope.releases = release.game.releases;
										});
									} else if(fileId) {
										var gameId = fileId;
										api.get('releases', {
											fields: [ 'id', 'order', 'release_date', 'title_en', 'title', 'territory_code' ],
											foreign: {
												platform: {
													fields: [ 'url', 'name_en', 'name_jp', 'short_name' ]
												},
												publisher: {
													fields: [ 'url', 'name_en', 'name_jp' ]
												}
											},
											order_by: [ 'order' ],
											where: {
												conditions: [
													{ field: 'game_id', value: gameId }
												]
											}
										}).then((response) => {
											modalScope.screenshot = {
												file_md5: null,
												order: 1,
												imageBlob: file || null,
												filename: file && file.name || null,
												title: file && file.name ? cleanTitle(file.name) : null
											}
											modalScope.releases = response.results;
										});
									}

									modalScope.ok = function() {
										if(!modalScope.screenshot.imageBlob && !modalScope.screenshot.file_md5) {
											Notification.error('Please select an image');
										} else if(!modalScope.screenshot.filename) {
											Notification.error('Please specify a filename for the image');
										} else {
											var saveScreenshot = function() {
												api.post('screenshots', {
													key: {
														release_id: releaseId,
														file_md5: fileId||modalScope.screenshot.file_md5
													},
													data: {
														release_id: modalScope.screenshot.release_id || null,
														file_md5: fileId||modalScope.screenshot.file_md5,
														title: modalScope.screenshot.title,
														description: modalScope.screenshot.description,
														filename: modalScope.screenshot.filename
													}
												}).then((response) => {
													Notification.success('Screenshot saved');
													$uibModalInstance.close(modalScope.screenshot);
												}, (err) => {
													Notification.error(err);
												});
											};
											if('imageBlob' in modalScope.screenshot) {
												if(modalScope.screenshot.imageBlob) {
													api.upload('files', {
														file: modalScope.screenshot.imageBlob
													}).then((response) => {
														modalScope.screenshot.file_md5 = response.file.id;
														saveScreenshot();
													}, (err) => {
														Notification.error(err);
													});
												}
											} else {
												saveScreenshot();
											}
										}
									};
									modalScope.cancel = function() {
										$uibModalInstance.dismiss('cancel');
									};
								}
							]
						}).result.then((screenshot) => { $scope.loadGame(); }, (err) => {});
					};
					$scope.addScreenshot = (file, gameId) => {
						if(file) {
							$scope.openScreenshotEdit(null, gameId, file);
						}
					};
					$scope.deleteScreenshot = (releaseId, fileId) => {
						$uibModal.open({
							template: '<div class="modal-header">\
									<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
										<span aria-hidden="true">&times;</span>\
									</button>\
									<h3 class="modal-title">Please confirm</h3>\
								</div>\
								<div class="modal-body">\
									Are you sure you want to delete this screenshot?<br/>\
								</div>\
								<div class="modal-footer">\
									<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
									<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
								</div>',
							backdrop: 'static',
							size: 'sm',
							controller: [
								'$scope', '$uibModalInstance', 'api', 'Notification',
								function(modalScope, $uibModalInstance, api, Notification) {
									modalScope.ok = function() {
										api.delete('screenshots', {
											release_id: releaseId,
											file_md5: fileId
										}).then(function(response) {
											Notification.success('Screenshot deleted');
											$uibModalInstance.close('success');
										}, function(err) {
											Notification.error(err);
										});
									};
									modalScope.cancel = function() {
										$uibModalInstance.dismiss('cancel');
									};
								}
							]
						}).result.then(() => { $scope.loadGame(); }, (err) => {});
					};
				}
			]
		})
		;
}])
.directive('releaseEdit', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			game: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(releaseId) {
					var modal = $uibModal.open({
						template: require('./partials/releases/release-edit.html'),
						backdrop: 'static',
						size: 'lg',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								modalScope.dateTypes = [ 'day', 'week', 'month', 'quarter', 'year', 'decade', 'unreleased' ];
								if(releaseId) {
									api.get('releases', {
										fields: [
											'id',
											'order',
											'release_date',
											'date_type',
											'title_en',
											'title',
											'about',
											'territory_code',
										],
										foreign: {
											game: {
												fields: [ 'id', 'url', 'name_en', 'name_jp' ]
											},
											platform: {
												fields: [ 'id', 'url', 'name' ]
											},
											publisher: {
												fields: [ 'id', 'url', 'name' ]
											}
										},
										where: {
											conditions: [
												{ field: 'id', type: '=', value: releaseId }
											]
										},

									}).then(function(response) {
										modalScope.release = response.results[0];
									});
								} else {
									modalScope.release = {
										game: $scope.game,
										date_type: 'day'
									};
								}
								modalScope.ok = function() {
									api.post('releases', {
										key: {
											id: modalScope.release.id||null
										},
										data: {
											order: modalScope.release.order,
											game_id: modalScope.release.game.id,
											publisher_id: modalScope.release.publisher&&modalScope.release.publisher.id,
											platform_id: modalScope.release.platform&&modalScope.release.platform.id,
											title: modalScope.release.title,
											title_en: modalScope.release.title_en,
											about: modalScope.release.about,
											territory_code: modalScope.release.territory_code,
											release_date: modalScope.release.release_date,
											date_type: modalScope.release.date_type
										}
									}).then(function(response) {
										Notification.success('Release saved');
										$uibModalInstance.close(modalScope.release);
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then((release) => {
						$scope.success({ release: release });
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
.directive('releaseSelect', function() {
	return {
		restrict: 'E',
		template: require('./partials/releases/release-select.html'),
		scope: {
			model: '=?',
			id: '=?modelId'
		},
		controller: [
			'$scope', '$http',
			function($scope, $http) {
				$scope.release = null;
				$scope.$watch('release', function() {
					$scope.model = $scope.release;
					$scope.id = $scope.release && $scope.release.id;
					$scope.name = $scope.release && $scope.release.name;
				});
				$scope.clear = function() {
					$scope.release = null;
				};
				$scope.getReleases = function(val) {
					return $http.get(app.apiBase+'/releases', {
						params: {
							q: val
						}
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	}
})
.directive('releaseDelete', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(releaseId) {
					var modal = $uibModal.open({
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
									<span aria-hidden="true">&times;</span>\
								</button>\
								<h3 class="modal-title">Please confirm</h3>\
							</div>\
							<div class="modal-body">\
								Are you sure you want to delete {{release.title_en||release.title}}?<br/>\
								This release has {{release.num_packs}} packs tied to it.\
							</div>\
							<div class="modal-footer">\
								<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
								<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
							</div>',
						size: 'md',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(releaseId) {
									api.get('releases', {
										fields: [
											'id',
											'title_en',
											'title',
											'num_packs'
										],
										where: {
											conditions: [
												{ field: 'id', type: '=', value: releaseId }
											]
										},

									}).then(function(response) {
										modalScope.release = response.results[0];
									});
								}
								modalScope.ok = function() {
									api.delete('releases', {
										id: modalScope.release.id,
									}).then(function(response) {
										Notification.success('Release deleted');
										$uibModalInstance.close();
										$scope.success({ release: modalScope.release });
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then(function() {}, function() {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
;
