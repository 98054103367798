angular.module('app')
.config(function($stateProvider) {
	$stateProvider
		.state('chips', {
			url: "/chips?manufacturer",
			template: require('./partials/chips/chips.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$state',
				function($scope, $stateParams, api, $state) {
					$scope.chips = [];
					$scope.pagination = { curPage: 1, limit: 10, total : 0 };
					$scope.loadPage = function() {
						var where = { conditions: [], foreign: {} };
						if($scope.q) {
							where.conditions.push({
								type: 'or',
								value: [
									{ field: 'name', type: 'like', value: $scope.q + '%' },
									{ field: 'friendly_name', type: 'like', value: $scope.q + '%' },
									{ field: 'tech_name', type: 'like', value: $scope.q + '%' },
									{ field: 'short_name', type: 'like', value: $scope.q + '%' },
									{ field: 'manufacturer.name', type: 'like', value: $scope.q + '%' }
								]
							});
						}

						if($stateParams.manufacturer) {
							api.get('companies', {
								fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ],
								where: {
									conditions: [
										{ field: 'url', type: '=', value: $stateParams.manufacturer }
									]
								}
							}).then(function(response) {
								$scope.manufacturer = response.results[0];
							});
							where.foreign.manufacturer = {
								conditions: [
									{
										field: 'url',
										type: '=',
										value: $stateParams.manufacturer
									}
								]
							};
						}

						api.get('chips', {
							calc_found_rows: true,
							fields: [
								'id',
								'url',
								'name',
								'friendly_name',
								'tech_name',
								'short_name',
								'num_packs',
								'icon_ext'
							],
							foreign: {
								manufacturer: {
									fields: [ 'id', 'name', 'url' ]
								},
								pack_chips: {
									foreign: {
										pack: {
											fields: [ 'url', 'title', 'rating', 'num_ratings', 'rating_sort' ],
										}
									},
									order_by: [ 'pack.rating_sort:DESC' ],
									limit: 3
								}
							},
							where: where,
							order_by: [ 'num_packs:DESC' ],
							skip: ($scope.pagination.curPage - 1) * 10,
							limit: 10
						}).then(function(response) {
							$scope.chips = response.results;
							$scope.pagination.total = parseInt(response.total);
							$scope.pagination.skip = parseInt(response.skip);
							$scope.pagination.limit = parseInt(response.limit);
							api.markLoaded();
						});
					};
					$scope.$watch('pagination.curPage', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							$scope.loadPage();
						}
					});
					$scope.$watch('q', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							if($scope.pagination.curPage != 1)
								$scope.pagination.curPage = 1;
							else
								$scope.loadPage();
						}
					});
					$scope.afterCreate = (chip) => {
						$state.go('chip', { url: chip.url });
					};
					$scope.loadPage();
				}
			]
		})
		.state('chip', {
			url: '/chip/:url',
			template: require('./partials/chips/chip.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$state',
				function($scope, $stateParams, api, $state) {
					$scope.loadChip = function(url) {
						api.get('chips', {
							fields: [
								'id',
								'url',
								'name',
								'friendly_name',
								'tech_name',
								'short_name',
								'comment',
								'specs',
								'num_packs',
								'icon_ext'
							],
							foreign: {
								manufacturer: {
									fields: [ 'id', 'name', 'url' ]
								},
								parent: {
									fields: [ 'id', 'name', 'url' ]
								},
								pack_chips: {
									foreign: {
										pack: {
											fields: [
												'id',
												'title',
												'url',
												'rating',
												'num_ratings',
												'rating_sort',
												'num_songs'
											]
										}
									},
									order_by: [ 'pack.rating_sort:DESC' ],
									limit: 10
								}
							},
							where: {
								conditions: [
									{
										field: 'url',
										type: '=',
										value: $stateParams.url
									}
								]
							}
						}).then((response) => {
							if(response.results && response.results[0]) {
								$scope.chip = response.results[0];
								$scope.chip.specs = JSON.parse($scope.chip.specs);
							} else {
								$scope.error = 'Not found';
							}
						});
					};
					$scope.loadChip($stateParams.url);
					$scope.afterSave = (chip) => {
						if(chip.url != $stateParams.url)
							$state.go('chip', { url: chip.url });
						else
						$scope.loadChip($stateParams.url);
					};
					$scope.afterDelete = (chip) => {
						$state.go('chips');
					};
				}
			]
		})
	;
})
.directive('chipEdit', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope', '$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(chipId) {
					$uibModal.open({
						template: require('./partials/chips/chip-edit.html'),
						size: 'lg',
						backdrop: 'static',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(chipId) {
									api.get('chips', {
										fields: [
											'id',
											'url',
											'name',
											'friendly_name',
											'tech_name',
											'short_name',
											'comment',
											'specs',
											'icon_ext',
											'icon_md5'
										],
										foreign: {
											parent: {
												fields: [ 'id', 'url', 'name' ]
											},
											manufacturer: {
												fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ]
											},
											icon: {
												fields: [ 'md5' ]
											}
										},
										where: {
											conditions: [
												{ field: 'id', type: '=', value: chipId }
											]
										},

									}).then(function(response) {
										var chip = response.results[0];
										var specs = JSON.parse(chip.specs);
										chip.specs = [];
										if(specs) {
											for(var i in specs) {
												chip.specs.push({ key: i, value: specs[i] });
											}
										}
										modalScope.chip = chip;
										modalScope.initialChipUrl = chip.url;
									});
								} else {
									modalScope.chip = {};
								}
								modalScope.addSpec = function() {
									modalScope.chip.specs.push({ key: '', value: '' });
								};
								modalScope.removeSpec = function(idx) {
									modalScope.chip.specs.splice(idx, 1);
								};
								modalScope.ok = function() {
									if(!modalScope.chip.name) {
										Notification.error('Please specify chip name');
									} else if(!modalScope.chip.url) {
										Notification.error('Please specify chip URL slug');
									} else {
										var saveChip = () => {
											var specs = {};
											for(var i in modalScope.chip.specs)
												specs[modalScope.chip.specs[i].key] = modalScope.chip.specs[i].value;
											api.post('chips', {
												key: {
													id: modalScope.chip.id,
												},
												data: {
													url: modalScope.chip.url,
													name: modalScope.chip.name,
													friendly_name: modalScope.chip.friendly_name,
													tech_name: modalScope.chip.tech_name,
													short_name: modalScope.chip.short_name,
													comment: modalScope.chip.comment,
													parent_chip_id: modalScope.chip.parent&&modalScope.chip.parent.id,
													manufacturer_id: modalScope.chip.manufacturer&&modalScope.chip.manufacturer.id,
													icon_md5: modalScope.chip.icon_md5,
													icon_ext: modalScope.chip.icon_ext,
													specs: JSON.stringify(specs)
												}
											}).then(function(response) {
												Notification.success('Chip saved');
												$uibModalInstance.close(modalScope.chip);
											}, function(err) {
												Notification.error(err);
											});
										};
										if('iconBlob' in modalScope.chip) {
											if(modalScope.chip.iconBlob) {
												api.upload('files', {
													file: modalScope.chip.iconBlob
												}).then((response) => {
													modalScope.chip.icon_md5 = response.file.md5;
													modalScope.chip.icon_ext = response.file.extension;
													saveChip();
												}, (err) => {
													Notification.error(err);
												});
											} else {
												modalScope.chip.icon_md5 = null;
												modalScope.chip.icon_ext = null;
												saveChip();
											}
										} else {
											saveChip();
										}
									}
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then((chip) => {
						$scope.success({ chip: chip });
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
.directive('chipSelect', function() {
	return {
		restrict: 'E',
		template: require('./partials/chips/chip-select.html'),
		scope: {
			model: '=?',
			disabled: '=?'
		},
		controller: [
			'$scope', 'api',
			function($scope, api) {
				$scope.clear = function() {
					$scope.model = null;
				};
				// Autocomplete
				$scope.getChips = function(val) {
					return api.get('chips', {
						fields: [ 'id', 'name', 'url', 'icon_ext' ],
						where: {
							conditions: [
								{
									type: 'or',
									value: [
										{ field: 'name', type: 'like', value: val + '%' },
									]
								}
							]
						},
						limit: 10
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	};
})
.directive('chipList', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=?',
			success: '&'
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/chips/chip-list.html'),
						backdrop: 'static',
						controller: [
							'$scope', 'api', '$uibModalInstance',
							function(modalScope, api, $uibModalInstance) {
								modalScope.q = '';
								modalScope.pagination = { curPage: 1, limit: 10, total : 0 }
								modalScope.$watch('q', function() {
									modalScope.getChips();
								});
								modalScope.$watch('pagination.curPage', function(a, b) {
									modalScope.getChips();
								});
								modalScope.selectChip = function(idx) {
									$uibModalInstance.close(modalScope.chips[idx]);
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
								modalScope.getChips = function() {
									var where = {};
									if(modalScope.q) {
										where.conditions = [
											{
												type: 'or',
												value: [
													{ field: 'name', type: 'like', value: modalScope.q + '%' }
												]
											}
										];
									}
									api.get('chips', {
										calc_found_rows: true,
										fields: [ 'id', 'url', 'name', 'num_packs', 'icon_ext' ],
										where: where,
										order_by: [ 'num_packs:DESC' ],
										skip: (modalScope.pagination.curPage - 1) * 10,
										limit: 10
									}).then(function(response) {
										modalScope.chips = response.results;
										modalScope.pagination.total = parseInt(response.total);
										modalScope.pagination.skip = parseInt(response.skip);
										modalScope.pagination.limit = parseInt(response.limit);
									});
								};
								$uibModalInstance.opened.then(() => {
									modalScope.getChips();
								});
							}
						]
					});
					modal.result.then((selectedItem) => {
						$scope.model = selectedItem;
						$scope.success({ chip: selectedItem });
					}, () => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('chipDelete', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(chipId) {
					var modal = $uibModal.open({
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
									<span aria-hidden="true">&times;</span>\
								</button>\
								<h3 class="modal-title" ng-if="chip.id">Please confirm</h3>\
							</div>\
							<div class="modal-body">\
								Are you sure you want to delete {{chip.name}}?<br/>\
								{{chip.num_packs}} packs use this chip.\
							</div>\
							<div class="modal-footer">\
								<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
								<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
							</div>',
						size: 'md',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(chipId) {
									api.get('chips', {
										fields: [
											'id',
											'url',
											'name',
											'num_packs'
										],
										foreign: {
											alias_of: {
												fields: [ 'id', 'url', 'name' ]
											}
										},
										where: {
											conditions: [
												{ field: 'id', type: '=', value: chipId }
											]
										},

									}).then(function(response) {
										modalScope.chip = response.results[0];
									});
								}
								modalScope.ok = function() {
									api.delete('chips', {
										id: modalScope.chip.id,
									}).then(function(response) {
										Notification.success('Chip deleted');
										$uibModalInstance.close();
										$scope.success({ chip: modalScope.chip });
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
;
