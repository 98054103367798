angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('db', {
			url: "/db",
			template: require('./partials/db/tables.html'),
			controller: ['$scope', 'api', function($scope, api) {
				api.get('db/tables').then(function(response) {
					$scope.tables = response.results;
				});
				api.get('db/charsets').then(function(response) {
					$scope.charsets = response.charsets;
					$scope.collations = response.collations;
				});
				function quoteSqlString(s) {
					return JSON.stringify(''+s);
				}
				$scope.saveTable = function() {
					var alter = [];
					if($scope.table.TABLE_NAME != $scope.initTable.TABLE_NAME)
						alter.push('RENAME '+$scope.table.TABLE_NAME);
					if($scope.table.AUTO_INCREMENT != $scope.initTable.AUTO_INCREMENT)
						alter.push('MODIFY COLUMN '+$scope.table.autoIncrementColumn);
					if($scope.table.TABLE_COLLATION != $scope.initTable.TABLE_COLLATION) {
						var charset = $scope.collations[$scope.table.TABLE_COLLATION].CHARACTER_SET_NAME;
						alter.push('CONVERT TO CHARACTER SET '+charset+' COLLATE '+$scope.table.TABLE_COLLATION);
					}
					if($scope.table.TABLE_COMMENT != $scope.initTable.TABLE_COMMENT)
						alter.push('COMMENT = ' + quoteSqlString($scope.table.TABLE_COMMENT));

					/* Generate column ordering mutations */
					/* Try two strategies: */
					/* - start at first column whose index is less than the previous one */
					/* - start at the very first column */
					/* Then, eliminate mutations that do nothing */
					/* Compare results of both strategies, which are lists of mutations, and take the shortest */

					/* First strategy */
					var mutations = [], mutations2 = [], prevIdx = null, begin2 = false;
					for(var i in $scope.table.columns) {
						var col = $scope.table.columns[i];
						var ord = parseInt(col.ORDINAL_POSITION);
						mutations.push( prevIdx === null ? [ ord ] : [ ord, prevIdx ]);
						if(prevIdx !== null && !begin2 && ord < prevIdx)
							begin2 = true;
						if(begin2)
							mutations2.push([ ord, prevIdx ]);
						prevIdx = ord;
					}

					function simplifyMutations(columns, mutations, existing) {
						var curCols = [];
						var sortedCols = [];
						for(var i in columns) {
							curCols.push(parseInt(i)+1);
							sortedCols[parseInt(columns[i].ORDINAL_POSITION) - 1] = columns[i];
						}

						var ret = [];
						if(existing)
							for(var i in existing)
								ret.push(existing[i]);

						for(var i in mutations) {
							var mut = mutations[i];
							var newOrder = [];
							if(mut.length == 1) {
								newOrder.push(mut[0]);
								for(var j in curCols) {
									if(curCols[j] != mut[0])
										newOrder.push(curCols[j]);
								}
							} else {
								for(var j in curCols) {
									if(curCols[j] != mut[0])
										newOrder.push(curCols[j]);

									if(curCols[j] == mut[1])
										newOrder.push(mut[0]);
								}
							}
							if(curCols.join(',') != newOrder.join(',')) {
								for(var j in ret)
									if(ret[j].col.ORDINAL_POSITION == mut[0])
										ret.splice(j, 1);
								if(mut.length == 1)
									ret.push({ col: sortedCols[mut[0]-1], order: 'FIRST' });
								else
									ret.push({ col: sortedCols[mut[0]-1], order: 'AFTER ' + sortedCols[mut[1]-1].COLUMN_NAME });
								curCols = newOrder;
							}
						}
						return ret;
					}

					var colAlters = [];
					for(var i in $scope.table.columns) {
						var col = $scope.table.columns[i];
						if(col.initial.name != col.COLUMN_NAME || col.initial.type != col.COLUMN_TYPE)
							colAlters.push({ col: col });
					}

					var result1 = simplifyMutations($scope.table.columns, mutations, colAlters);
					var result2 = simplifyMutations($scope.table.columns, mutations2, colAlters);
					var result = result1.length > result2.length ? result2 : result1;
					if(result) {
						for(var i in result) {
							var col = result[i];
							var q = col.col.initial.name == col.col.COLUMN_NAME ? 'MODIFY COLUMN ' + col.col.COLUMN_NAME : 'CHANGE COLUMN ' + col.col.initial.name + ' ' + col.col.COLUMN_NAME;
							q += ' ' + col.col.COLUMN_TYPE + (col.order ? ' ' + col.order : '');
							alter.push(q);
						}
					}

					if(alter.length > 0) {
						var sql = 'ALTER TABLE '+$scope.initTable.TABLE_NAME+'\n'+alter.join(',\n');
						console.log(sql);
					} else {
						console.log('No changes detected');
					}
				};

				$scope.loadTable = function(tbl) {
					api.get('db/table', { table: tbl }).then(function(response) {
						for(var i in response.table.columns) {
							var col = response.table.columns[i];
							response.table.columns[i].isNullable = col.IS_NULLABLE == 'YES';
							response.table.columns[i].isPrimary = col.COLUMN_KEY == 'PRI';
							if(col.EXTRA.indexOf('auto_increment') !== -1)
								response.table.autoIncrementColumn = col.COLUMN_NAME;
							response.table.columns[i].isUnsigned = col.COLUMN_TYPE.indexOf('unsigned') != -1;
							response.table.columns[i].initial = { name: col.COLUMN_NAME, type: col.COLUMN_TYPE };
						}
						$scope.table = response.table;
						$scope.initTable = JSON.parse(JSON.stringify(response.table));;
						$scope.column = null;
						$scope.constraint = null;
						$scope.index = null;
					});
				};
				$scope.setAutoIncrementColumn = function(col, $event) {
					console.log('ai', col, $event);
				};
				$scope.openColumn = function(idx) {
					$scope.column = $scope.table.columns[idx];
				};
				$scope.addColumn = function() {
					$scope.table.columns.push({ COLUMN_NAME: 'column'+$scope.table.columns.length });
				};
				$scope.removeColumn = function(idx) {
					var col = $scope.table.columns[idx];
					if(col.COLUMN_NAME == $scope.table.autoIncrementColumn)
						$scope.table.autoIncrementColumn = null;
					if($scope.column && col.COLUMN_NAME == $scope.column.COLUMN_NAME)
						$scope.column = null;
					$scope.table.columns.splice(idx, 1);
				};
				$scope.moveColumnUp = function(idx) {
					if(idx < 1) return;
					var tmp = $scope.table.columns[idx-1];
					$scope.table.columns[idx-1] = $scope.table.columns[idx];
					$scope.table.columns[idx] = tmp;
				};
				$scope.moveColumnDown = function(idx) {
					if(idx >= $scope.table.columns.length - 1) return;
					var tmp = $scope.table.columns[idx+1];
					$scope.table.columns[idx+1] = $scope.table.columns[idx];
					$scope.table.columns[idx] = tmp;
				};

				$scope.constraintActions = [ 'RESTRICT', 'CASCADE', 'SET NULL', 'NO ACTION' ];
				$scope.openConstraint = function(idx) {
					$scope.constraintIdx = idx;
					$scope.constraint = $scope.table.constraints[idx];
				};
				$scope.addConstraint = function() {
					$scope.table.constraints.push({ CONSTRAINT_NAME: $scope.table.TABLE_NAME + '_', columns: [], UPDATE_RULE: 'RESTRICT', DELETE_RULE: 'RESTRICT' });
				};
				$scope.removeConstraint = function(idx) {
					if($scope.constraint && $scope.table.constraints[idx].CONSTRAINT_NAME == $scope.constraint.CONSTRAINT_NAME)
						$scope.constraint = null;
					$scope.table.constraints.splice(idx, 1);
				};
				$scope.addConstraintColumn = function(idx) {
					$scope.table.constraints[idx].columns.push({});
					$scope.constraint = $scope.table.constraints[idx];
				};
				$scope.removeConstraintColumn = function(cidx, idx) {
					$scope.table.constraints[cidx].columns.splice(idx, 1);
					$scope.constraint = $scope.table.constraints[cidx];
				};
				$scope.indexTypes = [ 'INDEX', 'UNIQUE', 'FULLTEXT', 'SPATIAL' ];
				$scope.openIndex = function(i) {
					$scope.indexIdx = i;
					$scope.index = $scope.table.indices[i];
				};
				$scope.addIndex = function() {
					$scope.table.indices.push({});
				};
				$scope.removeIndex = function(idx) {
					if($scope.index && $scope.index.INDEX_NAME == $scope.table.indices[idx].INDEX_NAME)
						$scope.index = null;
					$scope.table.indices.splice(idx, 1);
				};
				$scope.addIndexColumn = function(idx) {
					$scope.table.indices[idx].columns.push({});
					$scope.index = $scope.table.indices[idx];
				};
				$scope.removeIndexColumn = function(iidx, idx) {
					$scope.table.indices[iidx].columns.splice(idx, 1);
					$scope.index = $scope.table.indices[iidx];
				};
			}]
		})
		;
}])
.directive('checkIcon', function() {
	return {
		restrict: 'E',
		template: '<span class="glyphicon" ng-class="{ \'glyphicon-check\': model, \'glyphicon-unchecked\': !model }"></span>',
		scope: {
			model: '=',
		}
	}
})
;
