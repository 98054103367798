angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('genres', {
			url: "/genres",
			template: require('./partials/genres/genres.html'),
			controller: [
				'$scope', 'api', '$state',
				function($scope, api, $state) {
					$scope.genres = [];
					$scope.pagination = { curPage: 1, limit: 10, total : 0 }
					$scope.loadPage = function() {
						var where = { conditions: [], foreign: {} };
						if($scope.q) {
							where.conditions.push({
								type: 'or',
								value: [
									{ field: 'name', type: 'like', value: $scope.q + '%' }
								]
							});
						}
						api.get('genres', {
							calc_found_rows: true,
							fields: [ 'id', 'url', 'name', 'num_games' ],
							order_by: [ 'num_games:DESC' ],
							where: where,
							skip: ($scope.pagination.curPage - 1) * 10,
							limit: 10
						}).then(function(response) {
							$scope.genres = response.results;
							$scope.pagination.total = parseInt(response.total);
							$scope.pagination.skip = parseInt(response.skip);
							$scope.pagination.limit = parseInt(response.limit);
						});
					};
					$scope.$watch('pagination.curPage', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							$scope.loadPage();
						}
					});
					$scope.$watch('q', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							if($scope.pagination.curPage != 1)
								$scope.pagination.curPage = 1;
							else
								$scope.loadPage();
						}
					});
					$scope.loadPage();
					$scope.afterCreate = function(genre) {
						$state.go('genre', { url: genre.url });
					};
				}
			]
		})
		.state('genre', {
			url: '/genre/:url',
			template: require('./partials/genres/genre.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$state',
				function($scope, $stateParams, api, $state) {
					$scope.loadGenre = function() {
						api.get('genres', {
							fields: [ 'id', 'url', 'name', 'num_games' ],
							foreign: {
								game_genres: {
									foreign: {
										game: {
											fields: [ 'url', 'name_en', 'name_jp', 'num_releases', 'num_packs' ],
											foreign: {
												top_rated_pack: {
													fields: [ 'rating', 'num_ratings', 'rating_sort' ]
												}
											}
										},
									},
									limit: 10,
									order_by: [ 'game.top_rated_pack.rating_sort:DESC' ]
								}
							},
							where: {
								conditions: [
									{
										field: 'url',
										type: '=',
										value: $stateParams.url
									}
								]
							}
						}).then(function(response) {
							if(response.results && response.results[0])
								$scope.genre = response.results[0];
							else
								$scope.error = 'Not found';
						});
					};
					$scope.loadGenre();
					$scope.afterSave = function(genre) {
						if(genre.url != $stateParams.url)
							$state.go('genre', { url: genre.url });
						else
							$scope.loadGenre();
					};
					$scope.afterDelete = function(genre) {
						$state.go('genres');
					};
				}
			]
		})
		;
}])
.directive('genreEdit', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(genreId) {
					$uibModal.open({
						template: require('./partials/genres/genre-edit.html'),
						size: 'sm',
						backdrop: 'static',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(genreId) {
									api.get('genres', {
										fields: [
											'id',
											'url',
											'name'
										],
										where: {
											conditions: [
												{ field: 'id', type: '=', value: genreId }
											]
										},

									}).then(function(response) {
										modalScope.genre = response.results[0];
									});
								} else {
									modalScope.genre = {};
								}
								modalScope.ok = function() {
									if(!modalScope.genre.name) {
										Notification.error('Please specify genre name');
									} else if(!modalScope.genre.url) {
										Notification.error('Please specify genre URL slug');
									} else {
										api.post('genres', {
											key: {
												id: modalScope.genre.id||null
											},
											data: {
												url: modalScope.genre.url,
												name: modalScope.genre.name
											}
										}).then(function(response) {
											Notification.success('Genre saved');
											$uibModalInstance.close(modalScope.genre);
										}, function(err) {
											Notification.error(err);
										});
									}
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then((genre) => {
						$scope.success({ genre: genre });
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
.directive('genreSelect', function() {
	return {
		restrict: 'E',
		template: '<div class="input-group"> \
				<input type="text" placeholder="Select genre..." uib-typeahead="genre as genre.name for genre in getGenres($viewValue)" typeahead-loading="loadingGenres" typeahead-no-results="noResults" class="form-control" ng-model="genre" ng-model-options="{ updateOn: \'default blur\', debounce: { \'default\': 200, \'blur\': 0 } }"> \
				<span class="input-group-btn"> \
					<button genre-list type="button" class="btn btn-default" uib-tooltip="Select or create genre" model="genre"><span class="glyphicon glyphicon-th-list"></span></button> \
					<button ng-click="clear()" type="button" class="btn btn-danger" uib-tooltip="Clear"><span class="glyphicon glyphicon-remove"></span></button> \
				</span> \
			</div> \
			<i ng-show="loadingLocations" class="glyphicon glyphicon-refresh"></i> \
			<div ng-show="noResults"> \
				<i class="glyphicon glyphicon-remove"></i> No Results Found \
			</div>',
		scope: {
			model: '=?'
		},
		controller: [
			'$scope', 'api', '$uibModal',
			function($scope, api, $uibModal) {
				$scope.genre = $scope.model;
				$scope.$watch('genre', function() {
					$scope.model = $scope.genre;
				});
				$scope.$watch('model', function() {
					if(!$scope.genre)
						$scope.genre = $scope.model;
				});
				$scope.clear = function() {
					$scope.genre = null;
				};
				// Autocomplete
				$scope.getGenres = function(val) {
					return api.get('genres', {
						fields: [ 'id', 'url', 'name' ],
						where: {
							conditions: [
								{
									type: 'or',
									value: [
										{ field: 'name', type: 'like', value: val + '%' },
									]
								}
							]
						},
						limit: 10
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	};
})
.directive('genreList', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=?'
		},
		controller: [
			'$scope', '$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/genres/genre-list.html'),
						backdrop: 'static',
						controller: [
							'$scope', 'api', '$uibModalInstance',
							function(modalScope, api, $uibModalInstance) {
								modalScope.q = '';
								modalScope.pagination = { curPage: 1, limit: 10, total : 0 }
								modalScope.$watch('q', function() {
									modalScope.getGenres();
								});
								modalScope.$watch('pagination.curPage', function(a, b) {
									modalScope.getGenres();
								});
								modalScope.selectGenre = function(idx) {
									$uibModalInstance.close(modalScope.genres[idx]);
								};
								modalScope.ok = function() {
									$uibModalInstance.close();
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
								modalScope.getGenres = function() {
									var where = {};
									if(modalScope.q) {
										where.conditions = [
											{
												type: 'or',
												value: [
													{ field: 'name', type: 'like', value: modalScope.q + '%' }
												]
											}
										];
									}
									api.get('genres', {
										calc_found_rows: true,
										fields: [ 'id', 'url', 'name', 'num_games' ],
										where: where,
										order_by: [ 'num_games:DESC' ],
										skip: (modalScope.pagination.curPage - 1) * 10,
										limit: 10
									}).then(function(response) {
										modalScope.genres = response.results;
										modalScope.pagination.total = parseInt(response.total);
										modalScope.pagination.skip = parseInt(response.skip);
										modalScope.pagination.limit = parseInt(response.limit);
									});

								};
								$uibModalInstance.opened.then(function() {
									modalScope.getGenres();
								});
							}
						]
					});
					modal.result.then(function (selectedItem) {
						$scope.model = selectedItem;
					}, function () {

					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('genreDelete', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope', '$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(genreId) {
					var modal = $uibModal.open({
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
									<span aria-hidden="true">&times;</span>\
								</button>\
								<h3 class="modal-title" ng-if="genre.id">Please confirm</h3>\
							</div>\
							<div class="modal-body">\
								Are you sure you want to delete {{genre.name}}?<br/>\
								There are {{genre.num_games}} games in this genre.\
							</div>\
							<div class="modal-footer">\
								<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
								<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
							</div>',
						size: 'md',
						backdrop: 'static',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(genreId) {
									api.get('genres', {
										fields: [
											'id',
											'url',
											'name',
											'num_games'
										],
										where: {
											conditions: [
												{ field: 'id', type: '=', value: genreId }
											]
										},

									}).then(function(response) {
										modalScope.genre = response.results[0];
									});
								}
								modalScope.ok = function() {
									api.delete('genres', {
										id: modalScope.genre.id,
									}).then(function(response) {
										Notification.success('Genre deleted');
										$uibModalInstance.close();
										$scope.success({ genre: modalScope.genre });
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
;
