angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('platforms', {
			url: "/platforms?manufacturer_url",
			template: require('./partials/platforms/platforms.html'),
			controller: [
				'$scope', 'api', '$stateParams', '$state',
				function($scope, api, $stateParams, $state) {
					$scope.platforms = [];
					$scope.pagination = { curPage: 1, limit: 12, total : 0 }
					$scope.loadPage = function() {
						var where = {
							conditions: []
						};
						if($scope.q) {
							where.conditions.push({
								type: 'or',
								value: [
									{ field: 'name', type: 'like', value: $scope.q + '%' },
									{ field: 'name_en', type: 'like', value: $scope.q + '%' },
									{ field: 'name_jp', type: 'like', value: $scope.q + '%' }
								]
							});
						}
						if($stateParams.manufacturer_url) {
							api.get('companies', {
								fields: [ 'id', 'name', 'url' ],
								where: {
									conditions: [
										{ field: 'url', type: '=', value: $stateParams.manufacturer_url }
									]
								}
							}).then(function(response) {
								$scope.manufacturer = response.results[0];
							});
							where.conditions.push({
								field: 'manufacturer.url',
								type: '=',
								value: $stateParams.manufacturer_url
							});
						}
						api.get('platforms', {
							calc_found_rows: true,
							fields: [
								'url',
								'name',
								'name_en',
								'name_jp',
								'is_standard',
								'clean_name',
								'short_name',
								'num_releases',
								'num_games',
								'picture_ext'
							],
							foreign: {
								manufacturer: {
									fields: [ 'url', 'name', 'name_en', 'name_jp' ]
								},
								type: {
									fields: [ 'id', 'name' ]
								},
								group: {
									fields: [ 'id', 'name_en', 'name_jp' ]
								}
							},
							order_by: [ 'num_games:DESC' ],
							where: where,
							skip: ($scope.pagination.curPage - 1) * 12,
							limit: 12
						}).then(function(response) {
							$scope.platforms = response.results;
							$scope.pagination.total = parseInt(response.total);
							$scope.pagination.skip = parseInt(response.skip);
							$scope.pagination.limit = parseInt(response.limit);
						});
					};
					$scope.$watch('pagination.curPage', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							$scope.loadPage();
						}
					});
					$scope.$watch('q', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							if($scope.pagination.curPage != 1)
								$scope.pagination.curPage = 1;
							else
								$scope.loadPage();
						}
					});
					$scope.afterCreate = (platform) => {
						$state.go('platform', { url: platform.url });
					};
					$scope.loadPage();
				}
			]
		})
		.state('platform', {
			url: '/platform/:url',
			template: require('./partials/platforms/platform.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$state',
				function($scope, $stateParams, api, $state) {
					$scope.loadPlatform = function() {
						api.get('platforms', {
							fields: [
								'id',
								'url',
								'name',
								'name_en',
								'name_jp',
								'is_standard',
								'clean_name',
								'short_name',
								'description',
								'num_releases',
								'num_games',
								'picture_ext'
							],
							foreign: {
								manufacturer: {
									fields: [ 'url', 'name', 'name_en', 'name_jp' ]
								},
								releases: {
									fields: [],
									foreign: {
										game: {
											fields: [ 'url', 'name_en', 'name_jp', 'num_releases', 'num_packs' ],
											foreign: {
												top_rated_pack: {
													fields: [ 'rating', 'num_ratings', 'rating_sort' ]
												}
											}
										}
									},
									order_by: [ 'game.top_rated_pack.rating_sort:DESC' ],
									group_by: [ 'game_id' ],
									limit: 10
								},
								type: {
									fields: [ 'id', 'name' ]
								},
								group: {
									fields: [ 'id', 'name_en', 'name_jp' ]
								}
							},
							where: {
								conditions: [
									{
										field: 'url',
										type: '=',
										value: $stateParams.url
									}
								]
							}
						}).then(function(response) {
							if(response.results && response.results[0])
								$scope.platform = response.results[0];
							else
								$scope.error = 'Not found';
						});
					};
					$scope.loadPlatform();
					$scope.afterSave = (platform) => {
						if(platform.url != $stateParams.url)
							$state.go('platform', { url: platform.url});
						else
							$scope.loadPlatform();
					};
					$scope.afterDelete = (platform) => {
						$state.go('platforms');
					};
				}
			]
		})
		;
}])
.directive('platformEdit', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(platformId) {
					var modal = $uibModal.open({
						template: require('./partials/platforms/platform-edit.html'),
						backdrop: 'static',
						size: 'lg',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								api.get('platform_types', {
									fields: [ 'id', 'name' ]
								}).then(function(response) {
									modalScope.platformTypes = response.results;
								});
								if(platformId) {
									api.get('platforms', {
										fields: [
											'id',
											'url',
											'name',
											'name_en',
											'name_jp',
											'is_standard',
											'clean_name',
											'short_name',
											'description',
											'type_id',
											'picture_ext',
											'picture_md5',
										],
										foreign: {
											manufacturer: {
												fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ]
											}
										},
										where: {
											conditions: [
												{ field: 'id', type: '=', value: platformId }
											]
										}
									}).then(function(response) {
										modalScope.platform = response.results[0];
										modalScope.platform.is_standard = modalScope.platform.is_standard == '1' ? true : false;
										modalScope.initialPlatformUrl = modalScope.platform.url;
										modalScope.initialManufacturerUrl = modalScope.platform.manufacturer.url || '';
									});
								} else {
									modalScope.platform = {
										platformType: '1'
									};
								}
								modalScope.ok = function() {
									if(!modalScope.platform.name) {
										Notification.error('Please specify platform name');
									} else if(!modalScope.platform.url) {
										Notification.error('Please specify URL slug');
									} else {
										var savePlatform = function() {
											api.post('platforms', {
												key: {
													id: modalScope.platform.id||null
												},
												data: {
													url: modalScope.platform.url,
													name: modalScope.platform.name,
													name_en: modalScope.platform.name_en,
													name_jp: modalScope.platform.name_jp,
													description: modalScope.platform.description,
													type_id: modalScope.platform.type_id,
													is_standard: modalScope.platform.is_standard ? '1' : '0',
													manufacturer_id: modalScope.platform.manufacturer ? modalScope.platform.manufacturer.id : null,
													picture_md5: modalScope.platform.picture_md5,
													picture_ext: modalScope.platform.picture_ext
												}
											}).then(function(response) {
												Notification.success('Platform saved');
												$uibModalInstance.close(modalScope.platform);
											}, function(err) {
												Notification.error(err);
											});
										}
										if('pictureBlob' in modalScope.platform) {
											if(modalScope.platform.pictureBlob) {
												api.upload('files', {
													file: modalScope.platform.pictureBlob
												}).then((response) => {
													modalScope.platform.picture_md5 = response.file.md5;
													modalScope.platform.picture_ext = response.file.extension;
													savePlatform();
												}, (err) => {
													Notification.error(err);
												});
											} else {
												modalScope.platform.picture_md5 = null;
												modalScope.platform.picture_ext = null;
												savePlatform();
											}
										} else {
											savePlatform();
										}
									}
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then((platform) => {
						$scope.success({ platform: platform });
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
.directive('platformSelect', function() {
	return {
		restrict: 'E',
		template: require('./partials/platforms/platform-select.html'),
		scope: {
			model: '=?'
		},
		controller: [
			'$scope', 'api', '$uibModal',
			function($scope, api, $uibModal) {
				$scope.platform = $scope.model;
				$scope.$watch('platform', function() {
					$scope.model = $scope.platform;
				});
				$scope.$watch('model', function() {
					if(!$scope.platform)
						$scope.platform = $scope.model;
				});
				$scope.clear = function() {
					$scope.platform = null;
				};
				// Autocomplete
				$scope.getPlatforms = function(val) {
					return api.get('platforms', {
						fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ],
						where: {
							conditions: [
								{
									type: 'or', value: [
										{ field: 'name', type: 'like', value: val + '%' },
										{ field: 'name_jp', type: 'like', value: val + '%' },
										{ field: 'name_en', type: 'like', value: val + '%' }
									]
								}
							]
						},
						limit: 10
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	};
})
.directive('platformList', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=?'
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/platforms/platform-list.html'),
						controller: [
							'$scope', 'api', '$uibModalInstance',
							function(modalScope, api, $uibModalInstance) {
								modalScope.q = '';
								modalScope.pagination = { curPage: 1, limit: 10, total : 0 }
								modalScope.$watch('q', function() {
									modalScope.getPlatforms();
								});
								modalScope.$watch('pagination.curPage', function(a, b) {
									modalScope.getPlatforms();
								});
								modalScope.selectPlatform = function(idx) {
									$uibModalInstance.close(modalScope.platforms[idx]);
								};
								modalScope.ok = function() {
									$uibModalInstance.close();
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
								modalScope.getPlatforms = function() {
									var where = {};
									if(modalScope.q) {
										where.conditions = [
											{
												type: 'or',
												value: [
													{ field: 'name', type: 'like', value: modalScope.q + '%' },
													{ field: 'name_en', type: 'like', value: modalScope.q + '%' },
													{ field: 'name_jp', type: 'like', value: modalScope.q + '%' }
												]
											}
										];
									}
									api.get('platforms', {
										calc_found_rows: true,
										fields: [
											'id',
											'url',
											'name',
											'name_en',
											'name_jp',
										],
										where: where,
										order_by: [ 'name:DESC' ],
										skip: (modalScope.pagination.curPage - 1) * 10,
										limit: 10
									}).then(function(response) {
										modalScope.platforms = response.results;
										modalScope.pagination.total = parseInt(response.total);
										modalScope.pagination.skip = parseInt(response.skip);
										modalScope.pagination.limit = parseInt(response.limit);
									});

								};
								$uibModalInstance.opened.then(function() {
									modalScope.getPlatforms();
								});
							}
						]
					});
					modal.result.then(function (selectedItem) {
						$scope.model = selectedItem;
					}, function () {

					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('platformDelete', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(platformId) {
					var modal = $uibModal.open({
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
									<span aria-hidden="true">&times;</span>\
								</button>\
								<h3 class="modal-title" ng-if="platform.id">Please confirm</h3>\
							</div>\
							<div class="modal-body">\
								Are you sure you want to delete {{platform.name_en||platform.name||platform.name_jp}}?<br/>\
								This platform has:\
								<ul>\
									<li>{{platform.num_releases}} game releases</li>\
									<li>{{platform.num_games}} games</li>\
								</ul>\
							</div>\
							<div class="modal-footer">\
								<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
								<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
							</div>',
						size: 'md',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(platformId) {
									api.get('platforms', {
										fields: [
											'id',
											'url',
											'name',
											'name_en',
											'name_jp',
											'num_releases',
											'num_games'
										],
										where: {
											conditions: [
												{ field: 'id', type: '=', value: platformId }
											]
										},

									}).then(function(response) {
										modalScope.platform = response.results[0];
									});
								}
								modalScope.ok = function() {
									api.delete('platforms', {
										id: modalScope.platform.id,
									}).then(function(response) {
										Notification.success('Platform deleted');
										$uibModalInstance.close();
										$scope.success({ platform: modalScope.platform });
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then(function() {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
;
