angular.module('app')
.filter('kb', [function() {
	return function(val) {
		var b = parseInt(val) || 0;
		if(b > 1024 * 1024) {
			return Math.floor(b / 1024 / 1024)+'M';
		}
		if(b > 1024) {
			return Math.floor(b / 1024)+'k';
		}
		return b;
	}
}])
.directive('imageSelect', function() {
	return {
		restrict: 'E',
		scope: {
			blob: '=?',
			ext: '=?',
			defaultImage: '@',
			filenameModel: '=?',
			accept: '@',
			pattern: '@',
			noClear: '@'
		},
		template: require('./partials/files/image-select.html'),
		controller: [
			'$scope',
			function($scope) {
				if(!$scope.pattern) $scope.pattern = 'image/*';
				if(!$scope.accept) $scope.accept = 'image/*';
				$scope.changed = function($file) {
					$scope.blob = $file;
					$scope.filenameModel = $file.name;
				};
				$scope.clearImage = function() {
					$scope.blob = null;
					$scope.defaultImage = null;
					$scope.defaultImageCond = false;
					$scope.filenameModel = null;
				};
			}
		]
	};
})
;
