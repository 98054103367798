angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('playlists', {
			url: "/playlists?user_id",
			template: require('./partials/playlists/playlists.html'),
			controller: [ '$scope', '$stateParams', 'api', function($scope, $stateParams, api) {
				$scope.playlists = [];
				$scope.pagination = { curPage: 1, limit: 12, total : 0 }
				$scope.loadPage = function() {
					var foreign = {
						owner: {
							fields: [ 'id', 'username' ]
						}
					};

					var where = { conditions: [], foreign: {} };

					if($scope.q) {
						where.conditions = [
							{
								type: 'or',
								value: [
									{ field: 'name', type: 'like', value: $scope.q + '%' },
									{ field: 'owner.username', type: 'like', value: $scope.q + '%' }
								]
							}
						];
					}

					if($stateParams.user_id) {
						api.get('users', {
							fields: [ 'id', 'username' ],
							where: {
								conditions: [
									{ field: 'id', type: '=', value: $stateParams.user_id }
								]
							}
						}).then(function(response) {
							$scope.owner = response.results[0];
						});
						where.conditions.push({ field: 'owner_id', type: '=', value: $stateParams.user_id });
					}

					api.get('playlists', {
						calc_found_rows: true,
						fields: [ 'id', 'name', 'num_songs' ],
						foreign: foreign,
						where: where,
						order_by: [ 'num_songs:DESC' ],
						limit: 12,
						skip: ($scope.pagination.curPage - 1) * 12
					}).then(function(response) {
						$scope.playlists = response.results;
						$scope.pagination.total = parseInt(response.total);
						$scope.pagination.skip = parseInt(response.skip);
						$scope.pagination.limit = parseInt(response.limit);
					});
				}
				$scope.$watch('pagination.curPage', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						$scope.loadPage();
					}
				});
				$scope.$watch('q', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						if($scope.pagination.curPage != 1)
							$scope.pagination.curPage = 1;
						else
							$scope.loadPage();
					}
				});
				$scope.loadPage();
			}]
		})
		.state('playlist', {
			url: '/playlist/:id',
			template: require('./partials/playlists/playlist.html'),
			controller: [
				'$scope',
				'$stateParams',
				'api',
				function($scope, $stateParams, api) {
					api.get('playlists', {
						fields: [ 'id', 'name', 'public' ],
						foreign: {
							owner: {
								fields: [ 'id', 'username', 'password', 'email', 'regdate' ]
							},
							playlist_songs: {
								fields: [ 'order' ],
								foreign: {
									song: {
										fields: [
											'id',
											'order',
											'filename',
											'track',
											'total_samples',
											'loop_samples',
											'track_name_en',
											'track_name_jp'
										],
										foreign: {
											pack: {
												fields: [ 'url', 'title' ],
												foreign: {
													game: {
														fields: [ 'url', 'name_en', 'name_jp' ],
													}
												}
											}
										}
									}
								},
								order_by: [ 'order:ASC' ]
							}
						},
						where: {
							conditions: [
								{
									field: 'id',
									type: '=',
									value: $stateParams.id
								}
							]
						}
					}).then(function(response) {
						$scope.playlist = response.results[0];
					});
				}
			]
		})
		;
}])
.directive('playlistSelect', function() {
	return {
		restrict: 'E',
		template: require('./partials/playlists/playlist-select.html'),
		scope: {
			model: '=?',
			id: '=?modelId',
		},
		controller: [
			'$scope', '$http', '$uibModal',
			function($scope, $http, $uibModal) {
				console.log('playlistSelect', $scope.model);
				$scope.playlist = $scope.model;
				$scope.$watch('playlist', function() {
					$scope.model = $scope.playlist;
					$scope.id = $scope.playlist && $scope.playlist.id;
				});
				$scope.clear = function() {
					$scope.playlist = null;
				};
				$scope.getPlaylists = function(val) {
					return $http.get(app.apiBase+'/playlists', {
						params: {
							q: val
						}
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	};
})
.directive('playlistList', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=?'
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/playlists/playlists-list.html'),
						controller: [
							'$scope',
							'$http',
							'$uibModalInstance',
							function($scope, $http, $uibModalInstance) {
								$scope.q = '';
								$scope.$watch('q', function() {
									$scope.getPlaylists();
								});
								$scope.selectPlaylist = function(idx) {
									$uibModalInstance.close($scope.playlists[idx]);
								};
								$scope.ok = function() {
									$uibModalInstance.close();
								};
								$scope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
								$scope.getPlaylists = function() {
									$http({ method: 'GET', url: app.apiBase+'/playlists?limit=10'+($scope.q ? '&q='+$scope.q : '') }).then(function(response) {
										$scope.playlists = response.results;
									});
								};
								$uibModalInstance.opened.then(function() {
									$scope.getPlaylists();
								});
							}
						]
					});
					modal.result.then(function (selectedItem) {
						$scope.model = selectedItem;
					}, function () {

					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('playlistEdit', function() {
	return {
		restrict: 'A',
		scope: true,
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/playlists/playlist-edit.html'),
						controller: [
							'$scope', '$uibModalInstance',
							function($scope, $uibModalInstance) {
								$scope.playlistTypes = [ 'Playlist', 'Alias' ];
								$scope.tinymceOptions = {
									skin: 'lightgray',
									theme: 'modern',
									menubar: false,
									statusbar: false,
									toolbar: 'undo redo | bold italic | link image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent blockquote'
								};
								$scope.ok = function() {
									$uibModalInstance.close();
								};
								$scope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
;
