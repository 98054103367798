angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('staffRoles', {
			url: "/staff-roles",
			template: require('./partials/staff-roles/staff-roles.html'),
			controller: [ '$scope', '$stateParams', 'api', function($scope, $stateParams, api) {
				$scope.staffRoles = [];
				$scope.pagination = { curPage: 1, limit: 10, total : 0 };
				$scope.loadPage = function() {
					var where = { conditions: [], foreign: {} };
					if($scope.q) {
						where.conditions.push({
							type: 'or',
							value: [
								{ field: 'name', type: 'like', value: $scope.q + '%' },
							]
						});
					}
					api.get('staff_roles', {
						calc_found_rows: true,
						fields: [ 'id', 'name' ],
						where: where,
						limit: 10,
						skip: ($scope.pagination.curPage - 1) * 10
					}).then(function(response) {
						$scope.staffRoles = response.results;
						$scope.pagination.total = parseInt(response.total);
						$scope.pagination.skip = parseInt(response.skip);
						$scope.pagination.limit = parseInt(response.limit);
					});
				}
				$scope.$watch('pagination.curPage', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						$scope.loadPage();
					}
				});
				$scope.$watch('q', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						if($scope.pagination.curPage != 1)
							$scope.pagination.curPage = 1;
						else
							$scope.loadPage();
					}
				});
				$scope.loadPage();
			}]
		})
		.state('staffRole', {
			url: '/staff-role/:id',
			template: require('./partials/staff-roles/staff-role.html'),
			controller: [
				'$scope', '$stateParams', 'api', 'Notification',
				function($scope, $stateParams, api, Notification) {
					$scope.loadRole = function(userId) {
						api.get('staff_roles', {
							fields: [ 'id', 'name' ],
							where: {
								conditions: [
									{ field: 'id', value: $stateParams.id }
								]
							}
						}).then(function(response) {
							$scope.staffRole = response.results[0];
						});
					};

					$scope.loadRole();
				}
			]
		})
		;
}])
/* Select Role */
.directive('staffRoleSelect', function() {
	return {
		restrict: 'E',
		template: require('./partials/staff-roles/staff-role-select.html'),
		scope: {
			model: '=?',
			disabled: '=?',
			change: '&?'
		},
		controller: [
			'$scope', 'api', '$uibModal',
			function($scope, api, $uibModal) {
				$scope.$watch('model', function() {
					$scope.change({ staffRole: $scope.model });
				});
				$scope.clear = function() {
					$scope.model = null;
				};
				// Autocomplete
				$scope.getStaffRoles = function(val) {
					return api.get('staff_roles', {
						fields: [ 'id', 'name' ],
						where: {
							conditions: [
								{
									type: 'or',
									value: [
										{ field: 'name', type: 'like', value: val + '%' },
									]
								}
							]
						},
						limit: 10
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	};
})
.directive('staffRoleList', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=?'
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/staff-roles/staff-role-list.html'),
						backdrop: 'static',
						controller: [
							'$scope', 'api', '$uibModalInstance',
							function(modalScope, api, $uibModalInstance) {
								modalScope.q = '';
								modalScope.pagination = { curPage: 1, limit: 10, total : 0 }
								modalScope.$watch('q', function() {
									modalScope.getStaffRoles();
								});
								modalScope.$watch('pagination.curPage', function(a, b) {
									modalScope.getStaffRoles();
								});
								modalScope.selectStaffRole = function(idx) {
									$uibModalInstance.close(modalScope.staffRoles[idx]);
								};
								modalScope.ok = function() {
									$uibModalInstance.close();
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
								modalScope.getStaffRoles = function() {
									var where = {};
									if(modalScope.q) {
										where.conditions = [
											{
												type: 'or',
												value: [
													{ field: 'name', type: 'like', value: modalScope.q + '%' }
												]
											}
										];
									}
									api.get('staff_roles', {
										calc_found_rows: true,
										fields: [ 'id', 'name' ],
										where: where,
										order_by: [ 'name:DESC' ],
										skip: (modalScope.pagination.curPage - 1) * 10,
										limit: 10
									}).then(function(response) {
										modalScope.staffRoles = response.results;
										modalScope.pagination.total = parseInt(response.total);
										modalScope.pagination.skip = parseInt(response.skip);
										modalScope.pagination.limit = parseInt(response.limit);
									});
								};
								modalScope.getStaffRoles();
							}
						]
					}).result.then((selectedItem) => {
						$scope.model = selectedItem;
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('staffRoleEdit', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(staffRoleId) {
					var modal = $uibModal.open({
						template: require('./partials/staff-roles/staff-role-edit.html'),
						backdrop: 'static',
						size: 'sm',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(staffRoleId) {
									api.get('staff_roles', {
										fields: [
											'id',
											'name',
										],
										where: {
											conditions: [
												{ field: 'id', type: '=', value: staffRoleId }
											]
										},

									}).then(function(response) {
										modalScope.staffRole = response.results[0];
									});
								} else {
									modalScope.staffRole = {};
								}
								modalScope.ok = function() {
									if(!modalScope.staffRole.name) {
										Notification.error('Please specify staff role name');
									} else {
										api.post('staff_roles', {
											key: {
												id: modalScope.staffRole.id||null
											},
											data: {
												name: modalScope.staffRole.name,
											}
										}).then(function(response) {
											Notification.success('Staff role saved');
											$uibModalInstance.close(modalScope.staffRole);
										}, function(err) {
											Notification.error(err);
										});
									}
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then((staffRole) => {
						console.log('sucessing');
						$scope.success({ staffRole: staffRole });
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
.directive('staffRoleDelete', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(staffRoleId) {
					$uibModal.open({
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
									<span aria-hidden="true">&times;</span>\
								</button>\
								<h3 class="modal-title" ng-if="staff-role.id">Please confirm</h3>\
							</div>\
							<div class="modal-body">\
								Are you sure you want to delete {{staff-role.name}}?<br/>\
							</div>\
							<div class="modal-footer">\
								<button class="btn btn-danger" type="submit" ng-click="ok()">Delete</button>\
								<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
							</div>',
						backdrop: 'static',
						size: 'md',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(staffRoleId) {
									api.get('staff_roles', {
										fields: [
											'id',
											'name',
										],
										where: {
											conditions: [
												{ field: 'id', type: '=', value: staffRoleId }
											]
										},

									}).then(function(response) {
										modalScope.staffRole = response.results[0];
									});
								}
								modalScope.ok = function() {
									api.delete('staff_roles', {
										id: modalScope.staffRole.id,
									}).then((response) => {
										Notification.success('Staff role deleted');
										$uibModalInstance.close(modalScope.staffRole);
									}, (err) => {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then((staffRole) => {
						$scope.success({ staffRole: staffRole });
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
;