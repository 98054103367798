angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('requests', {
			url: "/requests",
			template: require('./partials/requests/requests.html'),
			controller: ['$scope', '$uibModal', 'api', '$state', function($scope, $uibModal, api, $state) {
				$scope.requests = [];
				$scope.pagination = { curPage: 1, limit: 12, total : 0 }
				$scope.loadPage = function() {
					var where = { conditions: [], foreign: {} };
					if($scope.q) {
						where.conditions.push({
							type: 'or',
							value: [
								{ field: 'game.name_en', type: 'like', value: $scope.q + '%' },
								{ field: 'game.name_jp', type: 'like', value: $scope.q + '%' },
								{ field: 'title', type: 'like', value: $scope.q + '%' }
							]
						});
					}
					api.get('requests', {
						calc_found_rows: true,
						fields: [ 'id', 'title', 'num_request_votes' ],
						foreign: {
							game: {
								fields: [ 'name_en', 'name_jp', 'url' ]
							}
						},
						order_by: [ 'num_request_votes:DESC' ],
						where: where,
						skip: ($scope.pagination.curPage - 1) * 12,
						limit: 12
					}).then(function(response) {
						$scope.requests = response.results;
						$scope.pagination.total = parseInt(response.total);
						$scope.pagination.skip = parseInt(response.skip);
						$scope.pagination.limit = parseInt(response.limit);
					});
				};
				$scope.$watch('pagination.curPage', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						$scope.loadPage();
					}
				});
				$scope.$watch('q', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						if($scope.pagination.curPage != 1)
							$scope.pagination.curPage = 1;
						else
							$scope.loadPage();
					}
				});
				$scope.loadPage();
				$scope.openRequest = function(request) {
					$state.go('request', { url: request.url });
				};
			}]
		})
		.state('request', {
			url: '/request/:url',
			template: require('./partials/requests/request.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$state',
				function($scope, $stateParams, api, $state) {
					$scope.loadRequests = function(url) {
						api.get('requests', {
							fields: [ 'id', 'url', 'name', 'num_games' ],
							foreign: {
								game_requests: {
									foreign: {
										game: {
											fields: [ 'url', 'name_en', 'name_jp', 'num_releases', 'num_packs' ],
											foreign: {
												top_rated_pack: {
													fields: [ 'rating', 'num_ratings', 'rating_sort' ]
												}
											}
										},
									},
									limit: 10,
									order_by: [ 'game_top_rated_pack_rating_sort:DESC' ]
								}
							},
							where: {
								conditions: [
									{
										field: 'url',
										type: '=',
										value: url
									}
								]
							}
						}).then(function(response) {
							$scope.request = response.results[0];
						});
					};
					$scope.loadRequests($stateParams.url);
					$scope.afterSave = function(chip) {
						$scope.loadRequests($stateParams.url);
					};
					$scope.afterDelete = function(chip) {
						$state.go('requests');
					};
				}
			]
		})
	;
}])
.directive('requestEdit', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			game: '=',
			success: '&'
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(requestId, game) {
					$uibModal.open({
						template: require('./partials/requests/request-edit.html'),
						backdrop: 'static',
						size: 'lg',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(requestId) {
									api.get('requests', {
										fields: [
											'id',
											'name'
										],
										foreign: {
											game: {
												fields: [ 'id', 'title', 'title_en', 'title_jp' ],
												foreign: {
													releases: {
														fields: [
															'id',
															'release_date',
															'title_en',
															'title',
															'territory_code'
														],
														foreign: {
															platform: {
																fields: [ 'url', 'name', 'name_en', 'name_jp', 'short_name' ]
															},
															publisher: {
																fields: [ 'url', 'name_en', 'name_jp' ]
															}
														}
													}
												}
											},
											request_releases: {
												fields: [ 'release_id' ]
											}
										},
										where: {
											conditions: [
												{ field: 'id', type: '=', value: requestId }
											]
										}
									}).then(function(response) {
										modalScope.request = response.results[0];
									});
								} else if(game) {
									modalScope.request = {
										game: game
									};
									if(!game.releases) {
										modalScope.loadGame(game);
									}
								}
								modalScope.loadGame = function(game) {
									if(!game || !game.id) {
										modalScope.request.game = {};
										return;
									}

									api.get('games', {
										fields: [
											'id',
											'name_en',
											'name_jp',
											'url'
										],
										foreign: {
											releases: {
												fields: [
													'id',
													'order',
													'release_date',
													'title_en',
													'title',
													'territory_code'
												],
												foreign: {
													platform: {
														fields: [ 'name', 'name_en', 'name_jp', 'short_name' ]
													},
													publisher: {
														fields: [ 'name', 'name_en', 'name_jp' ]
													}
												}
											}
										},
										where: {
											conditions: [ { field: 'id', value: game.id } ]
										}
									}).then(function(response) {
										if(response.results.length > 0)
											modalScope.request.game = response.results[0];
									});
								};
								modalScope.$watch('request.game.id', (newVal, oldVal) => {
									modalScope.loadGame(modalScope.request.game);
								});

								modalScope.ok = function() {
									var releases = [];
									for(var i in modalScope.request.game.releases) {
										if(modalScope.request.game.releases[i].selected) {
											releases.push(modalScope.request.game.releases[i].id);
										}
									}
									if(!modalScope.request.game.id) {
										Notification.error('Please select a game');
									} else if(!modalScope.request.title) {
										Notification.error('Please input a name for the pack');
									} else if(releases.length == 0) {
										Notification.error('Please select one or more releases');
									} else {
										console.log('posting request', modalScope.request);
										api.post('requests', {
											key: {
												id: modalScope.request.id || null
											},
											data: {
												title: modalScope.request.title,
												description: modalScope.request.description,
												game_id: modalScope.request.game && modalScope.request.game.id,
												releases: releases
											}
										}).then(function(response) {
											Notification.success('Request saved');
											$uibModalInstance.close(modalScope.request);
										}, function(err) {
											Notification.error(err);
										});
									}
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then((request) => {
						$scope.success({ request: request });
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id, scope.game);
			});
		}
	};
})
.directive('requestSelect', function() {
	return {
		restrict: 'E',
		template: '<div class="input-group"> \
				<input type="text" placeholder="Select request..." uib-typeahead="request as request.name for request in getRequests($viewValue)" typeahead-loading="loadingLocations" typeahead-no-results="noResults" class="form-control" ng-model="requests" ng-model-options="{ updateOn: \'default blur\', debounce: { \'default\': 200, \'blur\': 0 } }"> \
				<span class="input-group-btn"> \
					<button request-list class="btn btn-default" uib-tooltip="Select or create request" model="requests"><span class="glyphicon glyphicon-th-list"></span></button> \
					<button ng-click="clear()" class="btn btn-danger" uib-tooltip="Clear"><span class="glyphicon glyphicon-remove"></span></button> \
				</span> \
			</div> \
			<i ng-show="loadingLocations" class="glyphicon glyphicon-refresh"></i> \
			<div ng-show="noResults"> \
				<i class="glyphicon glyphicon-remove"></i> No Results Found \
			</div>',
		scope: {
			model: '=?'
		},
		controller: [
			'$scope', 'api', '$uibModal',
			function($scope, api, $uibModal) {
				$scope.request = $scope.model;
				$scope.$watch('request', function() {
					$scope.model = $scope.request;
				});
				$scope.$watch('model', function() {
					if(!$scope.request)
						$scope.request = $scope.model;
				});
				$scope.clear = function() {
					$scope.request = null;
				};
				// Autocomplete
				$scope.getRequests = function(val) {
					return api.get('requests', {
						fields: [ 'id', 'url', 'name' ],
						where: {
							conditions: [
								{
									type: 'or',
									value: [
										{ field: 'name', type: 'like', value: val + '%' },
									]
								}
							]
						},
						limit: 10
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	};
})
.directive('requestList', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=?'
		},
		controller: [
			'$scope', '$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/requests/request-list.html'),
						controller: [
							'$scope', 'api', '$uibModalInstance',
							function(modalScope, api, $uibModalInstance) {
								modalScope.q = '';
								modalScope.pagination = { curPage: 1, limit: 10, total : 0 }
								modalScope.$watch('q', function() {
									modalScope.getRequests();
								});
								modalScope.$watch('pagination.curPage', function(a, b) {
									modalScope.getRequests();
								});
								modalScope.selectRequest = function(idx) {
									$uibModalInstance.close(modalScope.requests[idx]);
								};
								modalScope.ok = function() {
									$uibModalInstance.close();
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
								modalScope.getRequests = function() {
									var where = {};
									if(modalScope.q) {
										where.conditions = [
											{
												type: 'or',
												value: [
													{ field: 'name', type: 'like', value: modalScope.q + '%' }
												]
											}
										];
									}
									api.get('requests', {
										calc_found_rows: true,
										fields: [ 'id', 'url', 'name', 'num_games' ],
										where: where,
										order_by: [ '_num_games:DESC' ],
										skip: (modalScope.pagination.curPage - 1) * 10,
										limit: 10
									}).then(function(response) {
										modalScope.requests = response.results;
										modalScope.pagination.total = parseInt(response.total);
										modalScope.pagination.skip = parseInt(response.skip);
										modalScope.pagination.limit = parseInt(response.limit);
									});

								};
								$uibModalInstance.opened.then(function() {
									modalScope.getRequests();
								});
							}
						]
					});
					modal.result.then(function (selectedItem) {
						$scope.model = selectedItem;
					}, function () {

					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('requestVote', function() {
	return {
		restrict: 'A',
		scope: {
			requestVote: '=',
			success: '&'
		},
		controller: [
			'$scope', 'api', 'Notification',
			function($scope, api, Notification) {
				$scope.voteRequest = function(requestId) {
					console.log('voteRequest', requestId);
					api.post('requests/vote', {
						request_id: requestId
					}).then(function(response) {
						if(response.voted)
							Notification.success('Vote submitted');
						else
							Notification.success('Vote retracted');
						$scope.success();
					}, function(err) {
						Notification.error(err);
					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				console.log('click', scope, element, attr);
				scope.voteRequest(scope.requestVote);
			});
		}
	};
})
;
