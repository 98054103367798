angular.module('app')
.controller('PlayerCtrl', [
	'$scope', 'api', '$rootScope', 'vgmPlayer', '$uibModal', '$q', '$timeout', 'songFavorite',
	function($scope, api, $rootScope, vgmPlayer, $uibModal, $q, $timeout, songFavorite) {
		$scope.showing = false;

		$scope.$watch('volume', function() {
			vgmPlayer.getPlayer().then(function(player) {
				player.setVolume($scope.volume / 100.0);
				localStorage.vgmPlayerVolume = $scope.volume;
			});
		});
		$scope.volume = localStorage.vgmPlayerVolume ? parseInt(localStorage.vgmPlayerVolume) : 80;

		$rootScope.$on('player:timeUpdate', function(ev, data) {
			localStorage.vgmPlayerTime = data.samples;
			$scope.position = data.samples / $scope.totalTime;
			$scope.time = data.samples;
			$scope.$apply();
		});

		$rootScope.$on('favoritedSong', function(ev, data) {
			if($scope.song.id == data.song_id) {
				$scope.song.favorite = data.favorite;
			}
		});
		$rootScope.$on('player:favoriteCurSong', function(ev, data) {
			if($scope.song && $scope.song.id) {
				songFavorite.favorite($scope.song.id);
			}
		});

		var setPlaying = function(playing) {
			$timeout(function() {
				$scope.playing = playing;
				localStorage.vgmPlayerPlaying = playing ? 'true' : 'false';
				$rootScope.$emit('player:setPlaying', { playing: $scope.playing, song_id: $scope.song&&$scope.song.id });
			});
		};
		$scope.loadQueueSong = function(songNum) {
			return $q(function(resolve, reject) {
				if(!$scope.queue || !$scope.queue[songNum]) {
					console.error('Song', songNum, 'not found in queue', $scope.queue);
					reject();
				}
				var songId = $scope.queue[songNum].id;
				api.get('songs', {
					fields: [ 'id', 'filename', 'track', 'track_name_en', 'track_name_jp', 'favorite' ],
					foreign: {
						pack: {
							fields: [ 'id', 'url', 'title' ]
						}
					},
					where: { conditions: [ { field: 'id', type: '=', value: songId } ] }
				}).then(function(response) {
					var song = response.results[0];
					localStorage.vgmPlayerCurSong = songNum;
					vgmPlayer.getPlayer().then(function(player) {
						player.load(song.pack.url + '/' + encodeURIComponent(song.filename), function(vgmData) {
							$scope.curSong = songNum;
							var loopSamples = parseInt(vgmData.loopSamples);
							var totalSamples = parseInt(vgmData.totalSamples);
							var fadeSeconds = 4;
							$scope.totalTime = loopSamples + totalSamples + (loopSamples > 0 ? fadeSeconds * 44100 : 0);
							$scope.position = 0;
							$scope.time = 0;
							var ticks = [];
							if(loopSamples > 0) {
								for(var i = totalSamples - loopSamples; i < totalSamples + loopSamples + fadeSeconds * 44100; i += loopSamples)
									ticks.push(i / $scope.totalTime);
							}
							$scope.ticks = ticks;
							$scope.song = song;
							$scope.showing = true;
							resolve();
						}, reject);
					}, reject);
				}, reject);
			});
		};
		$scope.seek = function(ticks) {
			vgmPlayer.getPlayer().then(function(player) {
				player.seek(ticks);
			});
		};
		$scope.play = function() {
			vgmPlayer.getPlayer().then(function(player) {
				player.play(setPlaying);
			});
		};
		$rootScope.$on('player:beginPlaying', function(ev, data) {
			$scope.play();
			$scope.$apply();
		});
		$scope.togglePause = function() {
			if($scope.playing)
				$scope.pause();
			else
				$scope.unpause();
		};
		$rootScope.$on('player:togglePause', function(ev, data) {
			$scope.togglePause();
			$scope.$apply();
		});
		$scope.playPause = function() {
			if($scope.playing)
				$scope.pause();
			else
				$scope.unpause();
		};
		$scope.pause = function() {
			vgmPlayer.getPlayer().then(function(player) {
				player.pause(setPlaying);
			});
		};
		$scope.unpause = function() {
			vgmPlayer.getPlayer().then(function(player) {
				player.unpause(setPlaying);
			});
		};
		$scope.stop = function() {
			vgmPlayer.getPlayer().then(function(player) {
				player.stop(setPlaying);
			});
		};
		$rootScope.$on('player:stopPlaying', function(ev, data) {
			$scope.stop();
			$scope.$apply();
		});

		$scope.next = function() {
			if($scope.queue && $scope.queue.length > $scope.curSong + 1) {
				$scope.curSong++;
				$scope.loadQueueSong($scope.curSong).then(function() {
					if($scope.playing)
						$scope.play();
				});
			} else {
				$scope.stop();
			}
		};
		$rootScope.$on('player:playNextSong', function(ev) {
			$scope.next();
			$scope.$apply();
		});

		$scope.prev = function() {
			if($scope.curSong > 0) {
				$scope.curSong--;
				$scope.loadQueueSong($scope.curSong).then(function() {
					if($scope.playing)
						$scope.play();
				});
			}
		};
		$rootScope.$on('player:playPrevSong', function(ev) {
			$scope.prev();
			$scope.$apply();
		});

		$rootScope.$on('player:songEnded', function(ev) {
			$scope.next();
		});

		/* Queue stuff */
		var saveQueue = function() {
			var ids = [];
			for(i in $scope.queue) {
				ids.push($scope.queue[i].id);
			}
			localStorage.vgmPlayerQueue = ids.join(',');
		};

		$scope.clearQueue = function() {
			$scope.queue = [];
			saveQueue();
		};

		$scope.appendSongsToQueue = function(ids) {
			return $q(function(resolve, reject) {
				if(ids && ids.length > 0) {
					api.get('songs', {
						fields: [
							'id',
							'track_name_en',
							'track_name_jp',
							'total_samples',
							'loop_samples',
							'favorite'
						],
						foreign: {
							pack: {
								fields: [ 'url', 'title' ]
							}
						},
						where: {
							conditions: [
								{
									field: 'id',
									type: 'in',
									value: ids
								}
							]
						}
					}).then(function(response) {
						var queue = $scope.queue;
						for(var j in response.results) {
							queue.push(response.results[j]);
						}
						$scope.queue = queue;
						saveQueue();
						resolve();
					}, reject);
				} else {
					resolve();
				}
			});
		};
		$rootScope.$on('player:enqueueSong', function(ev, data) {
			if(data.clearQueue)
				$scope.clearQueue();
			$scope.appendSongIdsToQueue([data.songId]).then(function() {
				if($scope.startPlayback) {
					$scope.loadQueueSong($scope.length - 1).then(function() { $scope.play(); });
				}
			});
			$scope.$apply();
		});

		$scope.appendPackToQueue = function(packId) {
			return $q(function(resolve, reject) {
				api.get('packs', {
					fields: [ 'id', 'url', 'title' ],
					foreign: {
						songs: {
							fields: [
								'id',
								'track_name_en',
								'track_name_jp',
								'total_samples',
								'loop_samples',
								'favorite'
							]
						}
					},
					where: {
						conditions: [
							{
								field: 'id',
								type: '=',
								value: packId
							}
						]
					}
				}).then(function(response) {
					var pack = response.results[0];
					for(var i in pack.songs) {
						var song = pack.songs[i];
						song.pack = { id: pack.id, url: pack.url, title: pack.title };
						$scope.queue.push(song);
					}
					saveQueue();
					resolve();
				}, reject);
			});
		};
		$rootScope.$on('player:enqueuePack', function(ev, data) {
			if(data.clearQueue)
				$scope.clearQueue();
			var prevLength = $scope.queue.length;
			$scope.appendPackToQueue(data.packId).then(function() {
				var playSong = data.playSong;
				if(playSong !== null) {
					$scope.loadQueueSong(prevLength + playSong).then(function() {
						$scope.play();
					});
				}
			});
			$scope.$apply();
		});

		$scope.appendPlaylistToQueue = function(id) {
			return $q(function(resolve, reject) {
				api.get('playlists', {
					fields: [ 'id' ],
					foreign: {
						playlist_songs: {
							foreign: {
								song: {
									fields: [
										'id',
										'track_name_en',
										'track_name_jp',
										'total_samples',
										'loop_samples',
										'favorite'
									],
									foreign: {
										pack: {
											fields: ['id', 'url', 'title']
										}
									}
								}
							},
							order_by: 'order:ASC'
						}
					},
					where: {
						conditions: [
							{
								field: 'id',
								type: '=',
								value: id
							}
						]
					}
				}).then(function(response) {
					var pl = response.results[0];
					for(var i in pl.playlist_songs) {
						var song = pl.playlist_songs[i].song;
						$scope.queue.push(song);
					}
					saveQueue();
					resolve();
				}, reject);
			});
		};
		$rootScope.$on('player:enqueuePlaylist', function(ev, data) {
			if(data.clearQueue)
				$scope.clearQueue();
			var prevLength = $scope.queue.length;
			$scope.appendPlaylistToQueue(data.playlistId).then(function() {
				var playSong = data.playSong;
				if(playSong !== null) {
					$scope.loadQueueSong(prevLength + playSong).then(function() {
						$scope.play();
					});
				}
			});
			$scope.$apply();
		});

		$scope.appendPackM3uToQueue = function(id) {
			return $q(function(resolve, reject) {
				api.get('pack_m3u', {
					fields: [ 'id' ],
					foreign: {
						songs: {
							foreign: {
								song: {
									fields: [
										'id',
										'track_name_en',
										'track_name_jp',
										'total_samples',
										'loop_samples',
										'favorite'
									],
									foreign: {
										pack: {
											fields: ['id', 'url', 'title']
										}
									}
								}
							},
							order_by: 'order:ASC'
						}
					},
					where: {
						conditions: [
							{
								field: 'id',
								type: '=',
								value: id
							}
						]
					}
				}).then(function(response) {
					var pl = response.results[0];
					for(var i in pl.songs) {
						var song = pl.songs[i].song;
						$scope.queue.push(song);
					}
					saveQueue();
					resolve();
				}, reject);
			});
		};
		$rootScope.$on('player:enqueuePackM3u', function(ev, data) {
			if(data.clearQueue)
				$scope.clearQueue();
			var prevLength = $scope.queue.length;
			$scope.appendPackM3uToQueue(data.m3uId).then(function() {
				var playSong = data.playSong;
				if(playSong !== null) {
					$scope.loadQueueSong(prevLength + playSong).then(function() {
						$scope.play();
					});
				}
			});
			$scope.$apply();
		});

		var queueModal;
		$scope.openQueue = function() {
			if(queueModal) {
				queueModal.close();
				queueModal = false;
				return;
			}

			queueModal = $uibModal.open({
				template: require('./partials/queue.html'),
				scope: $scope,
				size: 'lg',
				controller: [
					'$scope', '$uibModalInstance', '$rootScope',
					function($scope, $uibModalInstance, $rootScope) {
						$scope.cancel = function() {
							$uibModalInstance.dismiss('cancel');
						};
						$rootScope.$on('favoritedSong', (ev, data) => {
							for(var i in $scope.queue) {
								if($scope.queue[i].id == data.song_id) {
									$scope.queue[i].favorite = data.favorite;
								}
							}
						});
					}
				]
			});
			queueModal.result.then(function() {
				queueModal = false;
			}, function() {
				queueModal = false;
			});
		};
		$rootScope.$on('player:openQueue', function(ev, data) {
			$scope.openQueue();
			$scope.$apply();
		});

		$scope.playQueueSong = function(idx) {
			$scope.curSong = idx;
			$scope.loadQueueSong(idx).then(function() {
				$scope.play();
			});
		};

		$scope.curSong = null;
		if(localStorage.vgmPlayerQueue) {
			var ids = localStorage.vgmPlayerQueue.split(',');
			$scope.clearQueue();
			$scope.appendSongsToQueue(ids).then(function() {
				$scope.curSong = localStorage.vgmPlayerCurSong && localStorage.vgmPlayerCurSong.match(/[0-9]+/) ? parseInt(localStorage.vgmPlayerCurSong) : null;
				if($scope.curSong && $scope.curSong !== null) {
					$scope.loadQueueSong($scope.curSong).then(function() {
						if(localStorage.vgmPlayerTime)
							$scope.seek(localStorage.vgmPlayerTime);
						if(localStorage.vgmPlayerPlaying == 'true')
							$scope.play();
					});
				}
			});
		}
	}
])
.factory('vgmPlayer', [
	'$q', 'user', '$rootScope',
	function($q, user, $rootScope) {
		var player = null, loading = false, loadingQueue = [];
		return {
			getPlayer: function() {
				var d = $q.defer();
				if(loading) {
					loadingQueue.push(d);
				} else if(player) {
					d.resolve(player);
				} else {
					loading = true;
					var type = 'emu';
					if(user.getUserData().preferences && user.getUserData().preferences.playerType) {
						type = user.getUserData().preferences.playerType;
					}
					var resolveLoad = function(getPlayerFunc) {
						getPlayerFunc.default((playerResult) => {
							player = playerResult;
							player.onSongEnded = (pl) => $rootScope.$emit('player:songEnded');
							player.onTimeUpdate = (pl, samples, loop) => $rootScope.$emit('player:timeUpdate', { samples: samples, loop: loop});
							for(var i in loadingQueue)
								loadingQueue[i].resolve(player);
							d.resolve(player);
							loadingQueue = [];
							loading = false;
						}, (err) => {
							for(var i in loadingQueue)
								loadingQueue[i].reject(err);
							d.reject(err);
							loadingQueue = [];
							loading = false;
						});
					}.bind(this);
					switch(type) {
						case 'emu':
							import(/* webpackChunkName: "vgmEmuPlayer" */ './vgmEmuPlayer').then(resolveLoad);
							break;
//						case 'emuWorker':
//							import(/* webpackChunkName: vgmEmuWorkerPlayer */ './vgmEmuWorkerPlayer').then(resolveLoad);
//							break;
//						case 'emuWorklet':
//							import(/* webpackChunkName: vgmEmuWorkletPlayer */ './vgmEmuWorkletPlayer').then(resolveLoad);
//							break;
//						case 'mp3':
//							import(/* webpackChunkName: vgmMp3Player */ 'vgmMp3Player').then(resolveLoad);
//							break;
						default:
							d.reject('Unknown player type '+type);
					}
				}
				return d.promise;
			}
		};
	}
])
.run([
	'Hotkeys', '$rootScope',
	function(Hotkeys, $rootScope) {
		Hotkeys.register('p', () => { $rootScope.$emit('player:openQueue'); }, 'Open play queue');
		Hotkeys.register('f', () => { $rootScope.$emit('player:favoriteCurSong'); }, 'Add currently playing song to favorites');
		/* winamp style play control ZXCVB */
		Hotkeys.register('z', () => { $rootScope.$emit('player:playPrevSong'); }, 'Previous song in queue');
		Hotkeys.register('x', () => { $rootScope.$emit('player:beginPlaying'); }, 'Play / Resume');
		Hotkeys.register('c', () => { $rootScope.$emit('player:togglePause'); }, 'Pause');
		Hotkeys.register('v', () => { $rootScope.$emit('player:stopPlaying'); }, 'Stop');
		Hotkeys.register('b', () => { $rootScope.$emit('player:playNextSong'); }, 'Next song in queue');
	}
])
.directive('volumeButton', [
	function() {
		return {
			restrict: 'E',
			scope: {
				model: '='
			},
			template: '<span class="volumeButton btn btn-primary"> \
				<i class="glyphicon glyphicon-volume-up"></i> \
				<span class="volPopup"><div class="cursor"></div></span> \
			</span>',
			link: function(scope, element, attr) {
				var cursor = element.find('div');
				cursor.css('top', 100 - scope.model + 'px');
				var initialY = null, initialValue = null;
				var cursorMouseMove = function(ev) {
					ev.preventDefault();
					ev.stopPropagation();
					var m = initialValue + initialY - ev.clientY;
					if(m < 0) m = 0;
					if(m > 100) m = 100;
					scope.model = m;
					cursor.css('top', 100 - m + 'px');
					scope.$apply();
				};
				docElem = angular.element(document);
				cursor.on('mousedown', function(ev) {
					element.addClass('dragging');
					initialY = ev.clientY;
					initialValue = scope.model;
					docElem.on('mouseup', function(ev) {
						element.removeClass('dragging');
						docElem.off('mousemove', cursorMouseMove);
					});
					docElem.on('mousemove', cursorMouseMove);
					ev.preventDefault();
					ev.stopPropagation();
				});
				element.on('wheel', function(ev) {
					var delta = ev.deltaY < 0 ? 10 : -10;
					var m = scope.model;
					m += delta;
					if(m > 100) m = 100;
					if(m < 0) m = 0;
					cursor.css('top', 100 - m + 'px');
					scope.model = m;
					scope.$apply();
					ev.preventDefault();
					ev.stopPropagation();
				});
				element.on('mousedown', function(ev) {
					if(ev.button == 0) {
						element.addClass('dragging');
						var m = 100 - ev.offsetY + 10;
						if(m < 0) m = 0;
						if(m > 100) m = 100;
						cursor.css('top', 100 - m + 'px');
						scope.model = m;
						scope.$apply();
						initialY = ev.clientY;
						initialValue = m;
						docElem.on('mouseup', function(ev) {
							element.removeClass('dragging');
							docElem.off('mousemove', cursorMouseMove);
						});
						docElem.on('mousemove', cursorMouseMove);
						ev.preventDefault();
						ev.stopPropagation();
					}
				});
			}
		};
	}
])
.directive('seekBar', [
	'$filter',
	function($filter) {
		return {
			restrict: 'E',
			scope: {
				model: '=',
				time: '=',
				ticks: '='
			},
			template: '<span class="seekBar"><div class="cursor"><span class="timePopup"></span><span class="timePopupArrow"></span></div></span>',
			link: function(scope, element, attr) {
				var seekBarElem = element[0].childNodes[0],
					cursor = element.find('div'),
					maxX = null,
					initialX = null,
					initialValue = null;
				var sizeChanged = function() {
					maxX = element[0].childNodes[0].offsetWidth - 20;
					cursor.css('left', scope.model * maxX + 'px');
					if(scope.ticks.length > 0) {
						var bs = seekBarElem.getElementsByTagName('b');
						for(var i in scope.ticks)
							bs[i].style = 'left: '+(maxX * scope.ticks[i]) + 'px';
					}
				};
				var checkVisible = function() {
					if(element[0].childNodes[0].offsetWidth > 0 && typeof scope.model != 'undefined') {
						sizeChanged();
						return;
					}
					visibleTimeout = setTimeout(checkVisible, 100);
				};
				checkVisible();
				angular.element(window).on('resize', function(ev) {
					checkVisible();
				});

				scope.$watch('model', function() {
					if(typeof scope.model == 'undefined') return;
					if(initialValue === null) {
						element[0].getElementsByClassName('timePopup')[0].innerHTML = scope.time;
						maxX = element[0].childNodes[0].offsetWidth - 20;
						var m = parseFloat(scope.model) * maxX;
						if(m > maxX) m = maxX;
						if(m < 0) m = 0;
						cursor.css('left', m + 'px');
					}
				});
				scope.$watch('ticks', function() {
					maxX = element[0].childNodes[0].offsetWidth - 20;
					element.find('b').remove();
					if(scope.ticks && scope.ticks.length > 0) {
						for(var i in scope.ticks) {
							var b = element[0].childNodes[0].appendChild(document.createElement('b'));
							b.style = 'left: '+(maxX * scope.ticks[i]) + 'px';
						}
					}
				});
				var cursorMouseMove = function(ev) {
					var m = initialValue + ev.clientX - initialX;
					if(m < 0) m = 0;
					if(m > maxX) m = maxX;
					scope.model = m / maxX;
					cursor.css('left', m + 'px');
					scope.$apply();
					ev.preventDefault();
					ev.stopPropagation();
				}
				docElem = angular.element(document);
				cursor.on('mousedown', function(ev) {
					maxX = seekBarElem.offsetWidth - 20;
					element.addClass('dragging');
					initialX = ev.clientX;
					initialValue = scope.model * maxX;
					docElem.on('mouseup', function(ev) {
						element.removeClass('dragging');
						docElem.off('mousemove', cursorMouseMove);
						initialValue = null;
					});
					docElem.on('mousemove', cursorMouseMove);
					ev.preventDefault();
					ev.stopPropagation();
				});
				element.on('mousedown', function(ev) {
					maxX = seekBarElem.offsetWidth - 20;
					element.addClass('dragging');
					var m = ev.layerX - 10;
					if(m < 0) m = 0;
					if(m > maxX) m = maxX;
					cursor.css('left', m + 'px');
					scope.model = m / maxX;
					scope.$apply();
					initialX = ev.clientX;
					initialValue = m;
					docElem.on('mouseup', function(ev) {
						element.removeClass('dragging');
						docElem.off('mousemove', cursorMouseMove);
						initialValue = null;
					});
					docElem.on('mousemove', cursorMouseMove);
					ev.preventDefault();
					ev.stopPropagation();
				});
			}
		};
	}
])
.directive('enqueueSong', [
	'$rootScope',
	function($rootScope) {
		return {
			restrict: 'A',
			scope: {
				enqueueSong: '&',
				clearQueue: '=',
				startPlayback: '=',
			},
			link: function(scope, element, attr) {
				element.bind('click', function(ev) {
					if(ev.target.tagName != 'BUTTON' && ev.target.parentNode.tagName != 'BUTTON' && ev.target.tagName != 'A') {
						$rootScope.$emit('player:enqueueSong', {
							songId: scope.playSong(),
							clearQueue: scope.clearQueue,
							startPlayback: scope.startPlayback
						});
						ev.preventDefault();
						ev.stopPropagation();
					}
				});
			}
		};
	}
])
.directive('enqueuePack', [ '$rootScope', function($rootScope) {
	return {
		restrict: 'A',
		scope: {
			enqueuePack: '&',
			clearQueue: '=',
			playSong: '='
		},
		link: function(scope, element, attr) {
			element.bind('click', function(ev) {
				if(ev.target == element[0] || (ev.target.nodeName != 'A' && ev.target.nodeName != 'BUTTON')) {
					$rootScope.$emit('player:enqueuePack', {
						packId: scope.enqueuePack(),
						clearQueue: scope.clearQueue,
						playSong: scope.playSong
					});
					ev.preventDefault();
					ev.stopPropagation();
				}
			});
		}
	};
}])
.directive('enqueuePlaylist', [ '$rootScope', function($rootScope) {
	return {
		restrict: 'A',
		scope: {
			enqueuePlaylist: '&',
			clearQueue: '=',
			playSong: '='
		},
		link: function(scope, element, attr) {
			element.bind('click', function(ev) {
				if(ev.target == element[0] || (ev.target.nodeName != 'A' && ev.target.nodeName != 'BUTTON')) {
					$rootScope.$emit('player:enqueuePlaylist', {
						playlistId: scope.enqueuePlaylist(),
						clearQueue: scope.clearQueue,
						playSong: scope.playSong
					});
					ev.preventDefault();
					ev.stopPropagation();
				}
			});
		}
	};
}])
.directive('enqueuePackM3u', [ '$rootScope', function($rootScope) {
	return {
		restrict: 'A',
		scope: {
			enqueuePackM3u: '&',
			clearQueue: '=',
			playSong: '='
		},
		link: function(scope, element, attr) {
			element.bind('click', function(ev) {
				if(ev.target == element[0] || (ev.target.nodeName != 'A' && ev.target.nodeName != 'BUTTON')) {
					$rootScope.$emit('player:enqueuePackM3u', {
						m3uId: scope.enqueuePackM3u(),
						clearQueue: scope.clearQueue,
						playSong: scope.playSong
					});
					ev.preventDefault();
					ev.stopPropagation();
				}
			});
		}
	};
}])
.factory('songFavorite', [
	'api', '$rootScope', 'Notification',
	(api, $rootScope, Notification) => {
		return {
			favorite: (song_id) => {
				var p = api.post('playlists/favorite', {
					song_id: song_id
				});
				p.then(function(response) {
					Notification.success('Song '+(response.favorite?'added to':'removed from')+' favorites');
					$rootScope.$emit('favoritedSong', { favorite: response.favorite, song_id: response.song_id });
				}, function(err) {
					Notification.error('Could not add song to favorites: ' + err);
				});
				return p;
			}
		};
	}
])
.directive('heart', [
	function() {
		return {
			restrict: 'E',
			scope: {
				model: '='
			},
			template: '<button class="heart btn btn-default"><span class="glyphicon glyphicon-{{model.favorite?\'heart\':\'heart-empty\'}}"></span></button>',
			controller: [ '$scope', 'songFavorite', function($scope, songFavorite) {
				$scope.heart = function(id) {
					songFavorite.favorite(id).then((response) => {
						$scope.model.favorite = response.favorite;
					});
				};
			}],
			link: function(scope, element) {
				element.bind('click', function(ev) {
					scope.heart(scope.model.id);
					ev.preventDefault();
					ev.stopPropagation();
				});
			}
		}
	}
])
;
