angular.module('app')
.config(function($stateProvider) {
	$stateProvider
		.state('homebrew', {
			url: "/homebrew",
			template: require('./partials/homebrew/homebrew.html'),
			controller: [
				'$scope', '$uibModal', '$stateParams', 'api',
				function($scope, $uibModal, $stateParams, api) {
					$scope.songs = [];
					$scope.pagination = { curPage: 1, limit: 10, total : 0 };
					$scope.loadPage = function() {
						var where = { conditions: [], foreign: {} };
						if($scope.q) {
							where.conditions.push({
								type: 'or',
								value: [
									{ field: 'track_name_en', type: 'like', value: $scope.q + '%' },
								]
							});
						}

						api.get('songs', {
							calc_found_rows: true,
							fields: [ 'id', 'track_name_en', 'track_name_jp' ],
							foreign: {
							},
							where: where,
							order_by: [ '_id:DESC' ],
							skip: ($scope.pagination.curPage - 1) * 10,
							limit: 10
						}).then(function(response) {
							$scope.songs = response.results;
							$scope.pagination.total = parseInt(response.total);
							$scope.pagination.skip = parseInt(response.skip);
							$scope.pagination.limit = parseInt(response.limit);
						});
					};
					$scope.$watch('pagination.curPage', function(a, b) {
						$scope.loadPage();
					});
					$scope.$watch('q', function(a, b) {
						if($scope.pagination.curPage != 1)
							$scope.pagination.curPage = 1;
						else
							$scope.loadPage();
					});
				}
			]
		})
		// .state('file', {
		// 	url: '/file/:id',
		// 	template: require('./partials/files/file.html'),
		// 	controller: [
		// 		'$scope', '$stateParams', 'api', '$state',
		// 		function($scope, $stateParams, api, $state) {
		// 			$scope.loadFile = function(url) {
		// 				api.get('files', {
		// 					fields: [ 'id', 'path', 'name', 'md5', 'size', 'created', 'modified' ],
		// 					where: {
		// 						conditions: [
		// 							{
		// 								field: 'id',
		// 								type: '=',
		// 								value: $stateParams.id
		// 							}
		// 						]
		// 					}
		// 				}).then(function(response) {
		// 					$scope.file = response.results[0];
		// 					$scope.file.specs = JSON.parse($scope.file.specs);
		// 				});
		// 			};
		// 			$scope.loadFile($stateParams.url);
		// 			$scope.afterSave = function(file) {
		// 				$scope.loadFile($stateParams.url);
		// 			};
		// 			$scope.afterDelete = function(file) {
		// 				$state.go('files');
		// 			};
		// 		}
		// 	]
		// })
	;
})
;