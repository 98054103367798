angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('people', {
			url: "/people",
			template: require('./partials/people/people.html'),
			controller: ['$scope', 'api', function($scope, api) {
				$scope.people = [];
				$scope.pagination = { curPage: 1, limit: 10, total : 0 }
				$scope.loadPage = function() {
					var where = {
						conditions: [ { field: 'url', type: '<>', value: 'unknown' } ]
					};
					if($scope.q) {
						where.conditions = [
							{
								type: 'or',
								value: [
									{ field: 'name', type: 'like', value: $scope.q + '%' },
									{ field: 'name_en', type: 'like', value: $scope.q + '%' },
									{ field: 'name_jp', type: 'like', value: $scope.q + '%' }
								]
							}
						];
					}
					api.get('people', {
						calc_found_rows: true,
						fields: [ 'id', 'url', 'name', 'name_en', 'name_jp', 'num_games', 'rating', 'mugshot_ext' ],
						order_by: [ 'num_games:DESC', 'rating:DESC' ],
						where: where,
						skip: ($scope.pagination.curPage - 1) * 10,
						limit: 10
					}).then(function(response) {
						$scope.people = response.results;
						$scope.pagination.total = parseInt(response.total);
						$scope.pagination.skip = parseInt(response.skip);
						$scope.pagination.limit = parseInt(response.limit);
					});
				};
				$scope.$watch('pagination.curPage', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						$scope.loadPage();
					}
				});
				$scope.$watch('q', function(newVal, oldVal) {
					if(newVal !== oldVal) {
						if($scope.pagination.curPage != 1)
							$scope.pagination.curPage = 1;
						else
							$scope.loadPage();
					}
				});
				$scope.afterCreate = (person) => {
					$state.go('person', { url: person.url });
				};
				$scope.loadPage();
			}]
		})
		.state('person', {
			url: '/person/:url',
			template: require('./partials/people/person.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$state',
				function($scope, $stateParams, api, $state) {
					$scope.loadPerson = function() {
						$scope.person = { alias_of: null };
						api.get('people', {
							fields: [
								'id',
								'url',
								'name',
								'name_en',
								'name_jp',
								'description',
								'alias_type',
								'alias_comment',
								'from',
								'birth_date',
								'death_date',
								'website',
								'vgmdb_id',
								'gmb100_id',
								'gender',
								'num_games',
								'mugshot_ext'
							],
							foreign: {
								alias_of: {
									fields: [ 'url', 'name', 'name_en', 'name_jp' ]
								},
								game_staff: {
									foreign: {
										game: {
											fields: [ 'id', 'url', 'name_en', 'name_jp', 'num_releases', 'num_packs' ],
											foreign: {
												top_rated_pack: {
													fields: [ 'rating', 'num_ratings', 'rating_sort' ]
												}
											}
										}
									},
									order_by: [ 'game.top_rated_pack.rating_sort:DESC' ],
									limit: 10
								},
								people: {
									fields: [ 'url', 'name', 'name_en', 'name_jp' ]
								}
							},
							where: {
								conditions: [
									{
										field: 'url',
										type: '=',
										value: $stateParams.url
									}
								]
							}
						}).then((response) => {
							if(response.results && response.results[0])
								$scope.person = response.results[0];
							else
								$scope.error = 'Not found';
						});
					};
					$scope.loadPerson();
					$scope.afterSave = function(person) {
						if(person.url != $stateParams.url)
							$state.go('person', { url: person.url});
						else
							$scope.loadPerson();
					};
					$scope.afterDelete = function(person) {
						$state.go('people');
					};
				}
			]
		})
		;
}])
.directive('personEdit', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(personId) {
					var modal = $uibModal.open({
						template: require('./partials/people/person-edit.html'),
						backdrop: 'static',
						size: 'lg',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								modalScope.aliasTypes = [ 'Alias', 'Alt spelling', 'Long name', 'Short name', 'Team member' ];
								modalScope.genders = [ 'Male', 'Female' ];
								modalScope.personTypes = [ 'Person', 'Alias' ];
								if(personId) {
									api.get('people', {
										fields: [
											'id',
											'url',
											'name',
											'name_en',
											'name_jp',
											'description',
											'alias_type',
											'alias_comment',
											'from',
											'birth_date',
											'death_date',
											'website',
											'vgmdb_id',
											'gmb100_id',
											'gender',
											'mugshot_md5',
											'mugshot_ext'
										],
										foreign: {
											alias_of: {
												fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ]
											}
										},
										where: {
											conditions: [
												{ field: 'id', type: '=', value: personId }
											]
										},

									}).then(function(response) {
										modalScope.person = response.results[0];
										modalScope.initialPersonUrl = modalScope.person.url;
									});
								} else {
									modalScope.person = {
										alias_of: null
									};
								}
								modalScope.ok = function() {
									if(!modalScope.person.name_en) {
										Notification.error('Please specify the person\'s english name');
									} else if(!modalScope.person.url) {
										Notification.error('Please specify URL slug');
									} else {
										var savePerson = function() {
											api.post('people', {
												key: {
													id: modalScope.person.id||null
												},
												data: {
													url: modalScope.person.url,
													birth_date: modalScope.person.birth_date,
													death_date: modalScope.person.death_date,
													from: modalScope.person.from,
													gender: modalScope.person.gender,
													gmb100_id: modalScope.person.gmb100_id,
													name: modalScope.person.name,
													name_en: modalScope.person.name_en,
													name_jp: modalScope.person.name_jp,
													vgmdb_id: modalScope.person.vgmdb_id,
													website: modalScope.person.website,
													description: modalScope.person.description,
													alias_of: modalScope.person.alias_of ? modalScope.person.alias_of.id : null,
													alias_type: modalScope.person.alias_type,
													alias_comment: modalScope.person.alias_comment,
													mugshot_md5: modalScope.person.mugshot_md5,
													mugshot_ext: modalScope.person.mugshot_ext
												}
											}).then(function(response) {
												Notification.success('Person saved');
												$uibModalInstance.close(modalScope.person);
											}, function(err) {
												Notification.error(err);
											});
										};
										if('mugshotBlob' in modalScope.person) {
											if(modalScope.person.mugshotBlob) {
												api.upload('files', {
													file: modalScope.person.mugshotBlob
												}).then((response) => {
													modalScope.person.mugshot_md5 = response.file.md5;
													modalScope.person.mugshot_ext = response.file.extension;
													savePerson();
												}, (err) => {
													Notification.error(err);
												});
											} else {
												modalScope.person.mugshot_md5 = null;
												modalScope.person.mugshot_ext = null;
												savePerson();
											}
										} else {
											savePerson();
										}
									}
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then((person) => {
						$scope.success({ person: person });
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
.directive('personSelect', function() {
	return {
		restrict: 'E',
		template: require('./partials/people/person-select.html'),
		scope: {
			model: '=?',
			disabled: '=?'
		},
		controller: [
			'$scope', 'api', '$uibModal',
			function($scope, api, $uibModal) {
				$scope.clear = function() {
					$scope.model = null;
				};
				// Autocomplete
				$scope.getPeople = function(val) {
					return api.get('people', {
						fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ],
						where: {
							conditions: [
								{
									type: 'or', value: [
										{ field: 'name', type: 'like', value: val + '%' },
										{ field: 'name_jp', type: 'like', value: val + '%' },
										{ field: 'name_en', type: 'like', value: val + '%' }
									]
								}
							]
						},
						limit: 10
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	};
})
.directive('personList', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=?',
			success: '&'
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/people/people-list.html'),
						controller: [
							'$scope', 'api', '$uibModalInstance',
							function(modalScope, api, $uibModalInstance) {
								modalScope.q = '';
								modalScope.pagination = { curPage: 1, limit: 10, total : 0 }
								modalScope.$watch('q', function() {
									modalScope.getPeople();
								});
								modalScope.$watch('pagination.curPage', function(a, b) {
									modalScope.getPeople();
								});
								modalScope.selectPerson = function(idx) {
									$uibModalInstance.close(modalScope.people[idx]);
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
								modalScope.getPeople = function() {
									var where = {};
									if(modalScope.q) {
										where.conditions = [
											{
												type: 'or',
												value: [
													{ field: 'name', type: 'like', value: modalScope.q + '%' },
													{ field: 'name_en', type: 'like', value: modalScope.q + '%' },
													{ field: 'name_jp', type: 'like', value: modalScope.q + '%' }
												]
											}
										];
									}
									api.get('people', {
										calc_found_rows: true,
										fields: [ 'id', 'url', 'name', 'name_en', 'name_jp', 'num_games' ],
										where: where,
										order_by: [ 'num_games:DESC' ],
										skip: (modalScope.pagination.curPage - 1) * 10,
										limit: 10
									}).then(function(response) {
										modalScope.people = response.results;
										modalScope.pagination.total = parseInt(response.total);
										modalScope.pagination.skip = parseInt(response.skip);
										modalScope.pagination.limit = parseInt(response.limit);
									});

								};
								$uibModalInstance.opened.then(function() {
									modalScope.getPeople();
								});
							}
						]
					});
					modal.result.then((selectedItem) => {
						$scope.model = selectedItem;
						$scope.success({ person: selectedItem });
					}, () => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('personDelete', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(personId) {
					var modal = $uibModal.open({
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
									<span aria-hidden="true">&times;</span>\
								</button>\
								<h3 class="modal-title" ng-if="person.id">Please confirm</h3>\
							</div>\
							<div class="modal-body">\
								Are you sure you want to delete {{person.name_en||person.name||person.name_jp}}?<br/>\
								{{person.num_games}} games have contributions by this person.\
							</div>\
							<div class="modal-footer">\
								<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
								<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
							</div>',
						size: 'md',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(personId) {
									api.get('people', {
										fields: [
											'id',
											'url',
											'name',
											'name_en',
											'name_jp',
											'num_games',
											'rating'
										],
										foreign: {
											alias_of: {
												fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ]
											}
										},
										where: {
											conditions: [
												{ field: 'id', type: '=', value: personId }
											]
										},

									}).then(function(response) {
										modalScope.person = response.results[0];
									});
								}
								modalScope.ok = function() {
									api.delete('people', {
										id: modalScope.person.id,
									}).then(function(response) {
										Notification.success('Person deleted');
										$uibModalInstance.close();
										$scope.success({ person: modalScope.person });
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
;
