angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('series', {
			url: "/series?series_url&sort",
			template: require('./partials/series/series.html'),
			controller: [
				'$scope', '$uibModal', 'api', '$state',
				function($scope, $uibModal, api, $state) {
					$scope.series = [];
					$scope.pagination = { curPage: 1, limit: 12, total : 0 }
					$scope.loadPage = function() {
						var where = { conditions: [], foreign: {} };
						if($scope.q) {
							where.conditions.push({
								type: 'or',
								value: [
									{ field: 'name', type: 'like', value: $scope.q + '%' }
								]
							});
						}
						api.get('series', {
							calc_found_rows: true,
							fields: [ 'url', 'name', 'num_games', 'logo_ext' ],
							order_by: [ 'num_games:DESC' ],
							where: where,
							skip: ($scope.pagination.curPage - 1) * 12,
							limit: 12
						}).then(function(response) {
							$scope.series = response.results;
							$scope.pagination.total = parseInt(response.total);
							$scope.pagination.skip = parseInt(response.skip);
							$scope.pagination.limit = parseInt(response.limit);
						});
					};
					$scope.$watch('pagination.curPage', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							$scope.loadPage();
						}
					});
					$scope.$watch('q', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							if($scope.pagination.curPage != 1)
								$scope.pagination.curPage = 1;
							else
								$scope.loadPage();
						}
					});
					$scope.afterCreate = function(serie) {
						$state.go('serie', { url: serie.url });
					};
					$scope.loadPage();
				}
			]
		})
		.state('serie', {
			url: '/series/:url',
			template: require('./partials/series/serie.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$state',
				function($scope, $stateParams, api, $state) {
					$scope.loadSerie = function() {
						$scope.serie = null;
						api.get('series', {
							fields: [ 'id', 'name', 'about', 'url', 'num_games', 'logo_ext' ],
							foreign: {
								parent: {
									fields: [ 'id', 'url', 'name' ]
								},
								series: {
									fields: [ 'id', 'url', 'name' ]
								},
								series_games: {
									foreign: {
										game: {
											fields: [ 'url', 'name_en', 'name_jp', 'num_releases', 'num_packs' ],
											foreign: {
												top_rated_pack: {
													fields: [ 'rating', 'num_ratings', 'rating_sort' ]
												}
											}
										},
									},
									limit: 10,
									order_by: [ 'game.top_rated_pack.rating_sort:DESC' ]
								}
							},
							where: {
								conditions: [
									{
										field: 'url',
										type: '=',
										value: $stateParams.url
									}
								]
							}
						}).then(function(response) {
							if(response.results)
								$scope.serie = response.results[0];
							else
								$scope.error = 'Not found';

						});
					};
					$scope.loadSerie();
					$scope.afterSave = function(serie) {
						if(serie.url != $stateParams.url)
							$state.go('serie', { url: serie.url });
						else
							$scope.loadSerie();
					};
					$scope.afterDelete = function() {
						$state.go('series');
					};
				}
			]
		})
	;
}])
.directive('seriesEdit', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&'
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(seriesId) {
					$uibModal.open({
						template: require('./partials/series/series-edit.html'),
						size: 'md',
						backdrop: 'static',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								modalScope.seriesTypes = [ 'Series', 'Alias' ];
								if(seriesId) {
									api.get('series', {
										fields: [
											'id',
											'url',
											'name',
											'about',
											'logo_ext',
											'logo_md5'
										],
										foreign: {
											parent: {
												fields: [ 'id', 'url', 'name' ]
											}
										},
										where: {
											conditions: [
												{ field: 'id', type: '=', value: seriesId }
											]
										},

									}).then(function(response) {
										modalScope.series = response.results[0];
										modalScope.initialSeriesUrl = modalScope.series.url;
									});
								} else {
									modalScope.series = { parent: null };
								}
								modalScope.ok = function() {
									if(!modalScope.series.url) {
										Notification.error('Please specify an URL slug');
									} else if(!modalScope.series.name) {
										Notification.error('Please specify a name for the series');
									} else {
										var saveSeries = function() {
											api.post('series', {
												key: {
													id: modalScope.series.id || null
												},
												data: {
													url: modalScope.series.url,
													name: modalScope.series.name,
													about: modalScope.series.about,
													parent_series_id: modalScope.series.parent?modalScope.series.parent.id:null,
													logo_md5: modalScope.series.logo_md5,
													logo_ext: modalScope.series.logo_ext
												}
											}).then(function(response) {
												Notification.success('Series saved');
												$uibModalInstance.close(modalScope.series);
											}, function(err) {
												Notification.error(err);
											});
										};
										if('logoBlob' in modalScope.series) {
											if(modalScope.series.logoBlob) {
												api.upload('files', {
													file: modalScope.series.logoBlob
												}).then((response) => {
													modalScope.series.logo_md5 = response.file.md5;
													modalScope.series.logo_ext = response.file.extension;
													saveSeries();
												}, (err) => {
													Notification.error(err);
												});
											} else {
												modalScope.series.logo_md5 = null;
												modalScope.series.logo_ext = null;
												saveSeries();
											}
										} else {
											saveSeries();
										}
									}
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then((serie) => {
						$scope.success({ serie: serie });
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
.directive('seriesSelect', function() {
	return {
		restrict: 'E',
		template: '<div class="input-group"> \
				<input type="text" placeholder="Select series..." uib-typeahead="series as series.name for series in getSeries($viewValue)" typeahead-loading="loadingLocations" typeahead-no-results="noResults" class="form-control" ng-model="series" ng-model-options="{ updateOn: \'default blur\', debounce: { \'default\': 200, \'blur\': 0 } }"> \
				<span class="input-group-btn"> \
					<button series-list class="btn btn-default" type="button" uib-tooltip="Select or create series" model="series"><span class="glyphicon glyphicon-th-list"></span></button> \
					<button ng-click="clear()" class="btn btn-danger" type="button" uib-tooltip="Clear"><span class="glyphicon glyphicon-remove"></span></button> \
				</span> \
			</div> \
			<i ng-show="loadingLocations" class="glyphicon glyphicon-refresh"></i> \
			<div ng-show="noResults"> \
				<i class="glyphicon glyphicon-remove"></i> No Results Found \
			</div>',
		scope: {
			model: '=?'
		},
		controller: [
			'$scope', 'api', '$uibModal',
			function($scope, api, $uibModal) {
				$scope.series = $scope.model;
				$scope.$watch('series', function() {
					$scope.model = $scope.series;
				});
				$scope.$watch('model', function() {
					if(!$scope.series)
						$scope.series = $scope.model;
				});
				$scope.clear = function() {
					$scope.series = null;
				};
				// Autocomplete
				$scope.getSeries = function(val) {
					return api.get('series', {
						fields: [ 'id', 'url', 'name' ],
						where: {
							conditions: [
								{
									type: 'or',
									value: [
										{ field: 'name', type: 'like', value: val + '%' },
									]
								}
							]
						},
						limit: 10
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	};
})
.directive('seriesList', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=?'
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/series/series-list.html'),
						backdrop: 'static',
						controller: [
							'$scope', 'api', '$uibModalInstance',
							function(modalScope, api, $uibModalInstance) {
								modalScope.q = '';
								modalScope.pagination = { curPage: 1, limit: 10, total : 0 }
								modalScope.$watch('q', function() {
									modalScope.getSeries();
								});
								modalScope.$watch('pagination.curPage', function(a, b) {
									modalScope.getSeries();
								});
								modalScope.selectSeries = function(idx) {
									$uibModalInstance.close(modalScope.series[idx]);
								};
								modalScope.ok = function() {
									$uibModalInstance.close();
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
								modalScope.getSeries = function() {
									var where = {};
									if(modalScope.q) {
										where.conditions = [
											{
												type: 'or',
												value: [
													{ field: 'name', type: 'like', value: modalScope.q + '%' }
												]
											}
										];
									}
									api.get('series', {
										calc_found_rows: true,
										fields: [ 'id', 'url', 'name', 'num_games' ],
										where: where,
										order_by: [ 'num_games:DESC' ],
										skip: (modalScope.pagination.curPage - 1) * 10,
										limit: 10
									}).then(function(response) {
										modalScope.series = response.results;
										modalScope.pagination.total = parseInt(response.total);
										modalScope.pagination.skip = parseInt(response.skip);
										modalScope.pagination.limit = parseInt(response.limit);
									});

								};
								$uibModalInstance.opened.then(function() {
									modalScope.getSeries();
								});
							}
						]
					});
					modal.result.then(function (selectedItem) {
						$scope.model = selectedItem;
					}, function () {

					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('seriesDelete', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(seriesId) {
					var modal = $uibModal.open({
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
									<span aria-hidden="true">&times;</span>\
								</button>\
								<h3 class="modal-title" ng-if="series.id">Please confirm</h3>\
							</div>\
							<div class="modal-body">\
								Are you sure you want to delete {{series.name}}?<br/>\
								There are {{series.num_series_games}} games in this series.\
							</div>\
							<div class="modal-footer">\
								<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
								<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
							</div>',
						size: 'md',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(seriesId) {
									api.get('series', {
										fields: [
											'id',
											'url',
											'name',
											'num_series_games'
										],
										where: {
											conditions: [
												{ field: 'id', type: '=', value: seriesId }
											]
										}
									}).then(function(response) {
										modalScope.series = response.results[0];
									});
								}
								modalScope.ok = function() {
									api.delete('series', {
										id: modalScope.series.id,
									}).then(function(response) {
										Notification.success('Series deleted');
										$uibModalInstance.close();
										$scope.success({ series: modalScope.series });
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
;
