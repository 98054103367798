import marked from 'marked';

marked.setOptions({
	gfm: true,
	tables: true,
	sanitize: false,
	baseUrl: 'page/'
});

angular.module('app')
.config(function($stateProvider) {
	$stateProvider
		.state('pages', {
			url: '/pages',
			template: require('./partials/pages/page.html'),
			controller: [
				'$scope', '$stateParams', 'api',
				function($scope, $stateParams, api) {
					$scope.loadPage = function() {
						api.get('pages', {
							fields: [ 'title', 'content' ],
							where: {
								conditions: [
									{
										field: 'title',
										type: '=',
										value: 'Wiki'
									}
								]
							}
						}).then(function(response) {
							$scope.pageTitle = 'Wiki';
							if(response.results.length > 0)
								$scope.page = response.results[0];
							else
								$scope.error = "Page not found!";
						});
					};
					$scope.loadPage();
				}
			]
		})
		.state('page', {
			url: "/page/:title",
			template: require('./partials/pages/page.html'),
			controller: [
				'$scope', '$stateParams', 'api',
				function($scope, $stateParams, api) {
					$scope.loadPage = function() {
						$scope.error = null;
						api.get('pages', {
							fields: [ 'title', 'content' ],
							where: {
								conditions: [
									{
										field: 'title',
										type: '=',
										value: $stateParams.title
									}
								]
							}
						}).then(function(response) {
							$scope.pageTitle = $stateParams.title;
							if(response.results && response.results[0]) {
								$scope.page = response.results[0];
							} else {
								$scope.page = { title: $stateParams.title, content: '' };
								$scope.error = "Page not found!";
							}
						});
					};
					$scope.loadPage();
				}
			]
		})
		;
})
.filter('wikititle', [ function() {
	return function(raw) {
		return raw&&raw.replace(/_/g, ' ');
	};
}])
.filter('markdown', [ '$sce', function($sce) {
	return function(raw) {
		if(!raw) return '';
		return $sce.trustAsHtml(marked(raw));
	};
}])
.directive('pageEdit', function() {
	return {
		restrict: 'A',
		scope: {
			pageTitle: '=',
			success: '&',
		},
		controller: [
			'$scope', '$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(pageTitle) {
					var modal = $uibModal.open({
						template: require('./partials/pages/page-edit.html'),
						size: 'lg',
						backdrop: 'static',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification', 'user',
							function(modalScope, $uibModalInstance, api, Notification, user) {
								if(pageTitle) {
									api.get('pages', {
										fields: [
											'id',
											'title',
											'content'
										],
										foreign: {
											author: {
												fields: [ 'id', 'username' ]
											}
										},
										where: {
											conditions: [
												{ field: 'title', type: '=', value: pageTitle }
											]
										}
									}).then(function(response) {
										if(response.results && response.results[0])
											modalScope.page = response.results[0];
										else
											modalScope.page = {
												title: pageTitle,
												content: '',
												author: {
													id: user.getUserData().id,
													username: user.getUserData().username
												}
											};
									});
								} else {
									modalScope.page = {
										title: pageTitle,
										content: '',
										author: {
											id: user.data.data.id,
											username: user.data.data.username
										}
									};
								}
								modalScope.ok = function() {
									api.post('pages', {
										key: {
											id: modalScope.page.id || null
										},
										data: {
											title: modalScope.page.title,
											content: modalScope.page.content,
											author_id: modalScope.page.author?modalScope.page.author.id:user.getUserData().id
										}
									}).then(function(response) {
										Notification.success('Page saved');
										$uibModalInstance.close(modalScope.page);
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then((page) => {
						$scope.success({ page: page });
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.pageTitle);
			});
		}
	};
})
;
