import moment from 'moment';
const yabbcode = require('ya-bbcode');

angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('forums', {
			url: "/forum",
			template: require('./partials/forum/forums.html'),
			controller: ['$scope', 'api', function($scope, api) {
				$scope.forums = [];
				api.get('forums', {
					fields: [ 'id', 'url', 'name' ],
					foreign: {
						forums: {
							fields: [ 'id', 'url', 'name', 'num_topics', 'num_posts' ]
						}
					},
					where: { conditions: [ { field: 'parent_id', type: 'isNull' } ] }
				}).then(function(response) {
					$scope.forums = response.results;
				});
			}]
		})
		.state('forum', {
			url: '/forum/:url',
			template: require('./partials/forum/forum.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$state',
				function($scope, $stateParams, api, $state) {
					api.get('forums', {
						fields: [
							'id',
							'url',
							'name',
						],
						foreign: {
							parent: {
								fields: [ 'id', 'url', 'name' ]
							}
						},
						where: {
							conditions: [
								{
									field: 'url',
									type: '=',
									value: $stateParams.url
								}
							]
						}
					}).then(function(response) {
						$scope.forum = response.results[0];
						$scope.pagination = { curPage: 1, limit: 10, total : 0 };
						$scope.loadPage = function() {
							var where = { conditions: [ { field: 'forum_id', type: '=', value: $scope.forum.id } ], foreign: {} };
							if($scope.q) {
								where.conditions.push({
									type: 'or',
									value: [
										{ field: 'title', type: 'like', value: $scope.q + '%' }
									]
								});
							}
							api.get('topics', {
								calc_found_rows: true,
								fields: [ 'id', 'url', 'title', 'desc', 'posted_at', 'sticky', 'num_posts' ],
								foreign: {
									poster: {
										fields: [ 'id', 'username' ]
									}
								},
								order_by: [ 'sticky:DESC' , 'posted_at:DESC' ],
								where: where,
								skip: ($scope.pagination.curPage - 1) * 10,
								limit: 10
							}).then(function(response) {
								$scope.topics = response.results;
								$scope.pagination.total = parseInt(response.total);
								$scope.pagination.skip = parseInt(response.skip);
								$scope.pagination.limit = parseInt(response.limit);
							});
						};
						$scope.$watch('pagination.curPage', function(a, b) {
							$scope.loadPage();
						});
						$scope.$watch('q', function(a, b) {
							if($scope.pagination.curPage != 1)
								$scope.pagination.curPage = 1;
							else
								$scope.loadPage();
						});
						$scope.loadPage();
						$scope.afterSave = function(person) {
							$scope.loadPage();
						};
						$scope.afterDelete = function(person) {
							$state.go('forum');
						};
						$scope.postsToPage = function(p) { return Math.floor(p / 10); };
					});
				}
			]
		})
		.state('topic', {
			url: '/forum/:forumUrl/:url?page',
			template: require('./partials/forum/topic.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$state',
				function($scope, $stateParams, api, $state) {
					var topicId = $stateParams.url.replace(/^([0-9]+)-.*$/, '$1');
					$scope.loadTopic = function() {
						api.get('topics', {
							fields: [
								'id',
								'url',
								'title',
								'num_posts'
							],
							foreign: {
								forum: {
									fields: [ 'url', 'name' ]
								},
								posts: {
									fields: [ 'id', 'content', 'post_time', 'result_number' ],
									foreign: {
										user: {
											fields: [ 'id', 'username', 'signature', 'avatar_ext' ],
											foreign: {
												group_users: {
													foreign: {
														group: {
															fields: [ 'id', 'name', 'description', 'icon_ext' ]
														}
													}
												}
											}
										},
										post_attachments: {
											fields: [ 'comment', 'filename' ],
											foreign: {
												file: {
													fields: [ 'mime_major' ]
												}
											}
										}
									},
									limit: 10,
									skip: parseInt($stateParams.page) * 10
								}
							},
							where: {
								conditions: [{
									field: 'id',
									type: '=',
									value: topicId
								}]
							},
						}).then(function(response) {
							$scope.topic = response.results[0];
							for(var i in $scope.topic.posts) {
								var post = $scope.topic.posts[i];
								for(var j in post.post_attachments) {
									var att = post.post_attachments[j];
									if(att.file.mime_major == 'image')
										att.isImage = true;
									function escapeRegExp(string) {
										return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
									}
									var re = new RegExp('\\[attachment='+escapeRegExp(encodeURIComponent(att.filename))+'\\]', 'g');
									if(post.content.match(re))
										att.used = true;
									else post.hasAttachments = true;
									if(att.isImage)
										post.content = post.content.replace(re, '[img]/files/forum/'+$scope.topic.forum.url+'/'+$scope.topic.id+'-'+$scope.topic.url+'/'+post.id+'/'+att.filename+'[/img]');
									else
										post.content = post.content.replace(re, '[url=/files/forum/'+$scope.topic.forum.url+'/'+$scope.topic.id+'-'+$scope.topic.url+'/'+post.id+'/'+att.filename+']<span class="glyphicon glyphicon-file"></span> '+att.filename+'[/url]');
								}
							}
							$scope.pagination = {
								curPage: 1 + parseInt($stateParams.page),
								limit: 10,
								total : Math.floor(($scope.topic.num_posts + 9) / 10)
							};
							$scope.$watch('pagination.curPage', function() {
								$state.go('topic', {
									forumUrl: $stateParams.forumUrl,
									url: $stateParams.url,
									page: $scope.pagination.curPage - 1
								}, {
									notify: false,
									reload: false,
									location: 'replace',
									inherit: true
								});
							});
						});
					};
					$scope.loadTopic();
					$scope.afterSave = function(person) {
						$scope.loadTopic();
					};
					$scope.afterDelete = function(person) {
						$state.go('forum', { url: $stateParams.forumUrl });
					};
					$scope.replyPost = {
						content: '',
						attachments: []
					};
					$scope.postReply = function() {
						var form = new FormData;
						form.append('topicId', $scope.topic.id);
						form.append('content', $scope.replyPost.content);
						for(var i = 0; i < $scope.replyPost.attachments.length; i++) {
							form.append('attachments['+$scope.replyPost.attachments[i].name+']', $scope.replyPost.attachments[i].blob);
						}
						api.post('topics', form).then(function(response) {
							$scope.replyPost = {
								content: '',
								attachments: []
							};
							$scope.loadTopic();
						});
					};
				}
			]
		})
		;
}])
.directive('forumSelect', function() {
	return {
		restrict: 'E',
		template: require('./partials/forum/forum-select.html'),
		scope: {
			model: '=?'
		},
		controller: [
			'$scope', 'api', '$uibModal',
			function($scope, api, $uibModal) {
				$scope.forum = $scope.model;
				$scope.$watch('forum', function() {
					$scope.model = $scope.forum;
				});
				$scope.$watch('model', function() {
					if(!$scope.forum)
						$scope.forum = $scope.model;
				});
				$scope.clear = function() {
					$scope.forum = null;
				};
				// Autocomplete
				$scope.getForums = function(val) {
					return api.get('forums', {
						fields: [ 'id', 'url', 'name' ],
						where: {
							conditions: [
								{
									type: 'or',
									value: [
										{ field: 'name', type: 'like', value: val + '%' },
									]
								}
							]
						},
						limit: 10
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	};
})
.directive('forumList', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=?'
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/forum/forum-list.html'),
						controller: [
							'$scope',
							'$http',
							'$uibModalInstance',
							function($scope, $http, $uibModalInstance) {
								$scope.q = '';
								$scope.$watch('q', function() {
									$scope.getForums();
								});
								$scope.selectForum = function(idx) {
									$uibModalInstance.close($scope.forums[idx]);
								};
								$scope.ok = function() {
									$uibModalInstance.close();
								};
								$scope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
								$scope.getForums = function() {
									$http({ method: 'GET', url: app.apiBase+'/forums?limit=10'+($scope.q ? '&q='+$scope.q : '') }).then(function(response) {
										$scope.forums = response.data;
									});
								};
								$uibModalInstance.opened.then(function() {
									$scope.getForums();
								});
							}
						]
					});
					modal.result.then(function (selectedItem) {
						$scope.model = selectedItem;
					}, function () {

					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('forumEdit', function() {
	return {
		restrict: 'A',
		scope: true,
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/forum/forum-edit.html'),
						controller: [
							'$scope', '$uibModalInstance',
							function($scope, $uibModalInstance) {
								$scope.forumTypes = [ 'Forum', 'Section' ];
								$scope.ok = function() {
									$uibModalInstance.close();
								};
								$scope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.filter('bbcode', [ '$sce', function($sce) {
	return function(raw) {
		if(!raw) return raw;
		var bb = raw.replace(/:[0-9a-zA-Z]+\]/g, ']');
		let parser = new yabbcode();
		bb = parser.parse(bb);
		bb = bb.replace(/\{SMILIES_PATH\}/g, 'files/smilies');
		bb = bb.replace(/\[table[^\]]*\]\s*/g, '<table class="table">');
		bb = bb.replace(/\[(\/?(table|tr|th|td))[^\]]*\]\s*/g, '<$1>');
		bb = bb.replace(/\[align=([^\]]+)\](.*?)\[\/align\]/g, '<div style="text-align: $1">$2</div>');
		bb = bb.replace(/\[timg\](.*?)\[\/timg\]/g, '<img src="files/packs/$1" alt="">');
		bb = bb.replace(/\[zip\](.*?)\[\/zip\]/g, '<a href="files/zip/$1">$1</a>');
		bb = bb.replace(/\[topic=([0-9]+)\](.*?)\[\/topic\]/g, '<a href="topic/$1">$2</a>');
		bb = bb.replace(/\n/g, '<br />');
		bb = bb.replace(/(https?:\/\/)?(www\.)?vgmrips\.net\/forum\/viewtopic\.php\?([a-z=0-9]+)/g, 'forum/viewtopic?$3');
		return $sce.trustAsHtml(bb);
	}
}])
.filter('momentFromNow', [ function() {
	return function(raw) {
		return raw ? moment(raw, 'YYYY-MM-DD hh:mm:ss').fromNow() : '-';
	};
}])
.filter('momentFormat', [ function() {
	return function(raw) {
		return raw ? moment(raw, 'YYYY-MM-DD hh:mm:ss').format('LLL') : '-';
	};
}])
.directive('topicEdit', function() {
	return {
		restrict: 'A',
		scope: {
			forum: '='
		},
		controller: [
			'$scope', '$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/forum/topic-edit.html'),
						size: 'lg',
						backdrop: 'static',
						controller: [
							'$scope', '$uibModalInstance', 'Notification', 'api',
							function(modalScope, $uibModalInstance, Notification, api) {
								modalScope.topic = {
									forum: $scope.forum,
									title: '',
									desc: '',
								};
								modalScope.ok = function() {
									console.log(modalScope.topic);
									var form = new FormData;
									form.append('forumId', modalScope.topic.forum.id);
									form.append('title', modalScope.topic.title);
									form.append('desc', modalScope.topic.desc);
									form.append('content', modalScope.topic.post.content);
									for(var i = 0; i < modalScope.topic.post.attachments.length; i++) {
										form.append('attachments['+modalScope.topic.post.attachments[i].name+']', modalScope.topic.post.attachments[i].blob);
									}
									api.post('topics', form).then(function(response) {
										$uibModalInstance.close();
										Notification.success('Topic posted');
									}, function() {
										Notification.danger('Could not post!');
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then(function() {}, function() {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('postEdit', function() {
	return {
		template: require('./partials/forum/post-edit.html'),
		restrict: 'E',
		scope: {
			model: '='
		},
		controller: [
			'$scope', '$element',
			function($scope, $element) {
				function getInputSelection(el) {
					var start = 0, end = 0, normalizedValue, range,
						textInputRange, len, endRange;

					if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
						start = el.selectionStart;
						end = el.selectionEnd;
					} else {
						range = document.selection.createRange();

						if (range && range.parentElement() == el) {
							len = el.value.length;
							normalizedValue = el.value.replace(/\r\n/g, "\n");

							// Create a working TextRange that lives only in the input
							textInputRange = el.createTextRange();
							textInputRange.moveToBookmark(range.getBookmark());

							// Check if the start and end of the selection are at the very end
							// of the input, since moveStart/moveEnd doesn't return what we want
							// in those cases
							endRange = el.createTextRange();
							endRange.collapse(false);

							if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
								start = end = len;
							} else {
								start = -textInputRange.moveStart("character", -len);
								start += normalizedValue.slice(0, start).split("\n").length - 1;

								if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
									end = len;
								} else {
									end = -textInputRange.moveEnd("character", -len);
									end += normalizedValue.slice(0, end).split("\n").length - 1;
								}
							}
						}
					}

					return {
						start: start,
						end: end
					};
				}

				function setInputSelection(e, start, end){
					e.focus();
					if(e.setSelectionRange)
						e.setSelectionRange(start, end);
					else if(e.createTextRange) {
						e = e.createTextRange();
						e.collapse(true);
						e.moveEnd('character', end);
						e.moveStart('character', start);
						e.select();
					}
				}

				function wrapSelectionWithTag(tag, endTag) {
					var textarea = $element.find('textarea')[0];
					var txt = textarea.value;
					var sel = getInputSelection(textarea);
					txt = txt.substr(0, sel.start) + tag + txt.substr(sel.start, sel.end - sel.start) + endTag + txt.substr(sel.end);
					setInputSelection(textarea, sel.start, sel.end + tag.length + endTag.length);
					$scope.model.content = txt;
				}

				$scope.fontSize = 'normal';
				if(!$scope.model) {
					$scope.model = {
						attachments: [],
						content: '',
					};
				}

				$scope.editBold = function() {
					wrapSelectionWithTag('[b]', '[/b]');
				};
				$scope.editItalic = function() {
					wrapSelectionWithTag('[i]', '[/i]');
				};
				$scope.editUnderline = function() {
					wrapSelectionWithTag('[u]', '[/u]');
				};
				$scope.editQuote = function() {
					wrapSelectionWithTag('[quote]', '[/quote]');
				};
				$scope.editCode = function() {
					wrapSelectionWithTag('[code]', '[/code]');
				};
				$scope.editList = function(open, close) {
					if(typeof open == 'undefined') open = '[list]';
					if(typeof close == 'undefined') close = '[/list]';
					var textarea = $element.find('textarea')[0];
					var txt = textarea.value;
					var sel = getInputSelection(textarea);
					var items = txt.substr(sel.start, sel.end - sel.start).trim().split('\n').map(function(x) { return '[*]' + x; });
					var newTxt = txt.substr(0, sel.start) + '[list]\n' + items.join('\n') + '\n[/list]' + txt.substr(sel.end);
					$scope.model.content = newTxt;
					setInputSelection(textarea, sel.start, sel.end + open.length + close.length + 1 + items.length * 4);
				};
				$scope.editOrdered = function() {
					$scope.editList('[list=]');
				};
				$scope.editListItem = function() {
					$scope.editList('', '');
				};
				$scope.insertHtml = function(html, select) {
					var textarea = $element.find('textarea')[0];
					var txt = textarea.value;
					var sel = getInputSelection(textarea);
					txt = txt.substr(0, sel.start) + html + txt.substr(sel.end);
					if(select)
						setInputSelection(textarea, sel.start, sel.start + html.length);
					$scope.model.content = txt;
				};
				$scope.addAttachment = function(filename, blob, url) {
					console.log(blob);
					for(var i = 2; i < 1024; i++) {
						var found = false;
						for(var j = 0; j < $scope.model.attachments.length; j++) {
							if($scope.model.attachments[j].name == filename) {
								found = true;
								break;
							}
						}
						if(!found)
							break;
						if(filename.match(/ \([0-9]+\)(\.[a-zA-Z0-9_-]+)?$/)) {
							filename = filename.replace(/ \(([0-9]+)\)\.([a-zA-Z0-9_-]+)$/, ' ('+i+').$2');
						} else {
							filename = filename.replace(/\.([a-zA-Z0-9_-]+)$/, ' ('+i+').$1');
						}
					}
					$scope.model.attachments.push({ name: filename, isImage: blob.type.match(/^image\//), blob: blob, url: url });
					return encodeURIComponent(filename);
				};
				$scope.removeAttachment = function(idx) {
					$scope.model.attachments.splice(idx, 1);
				};
				$scope.insertAttachment = function(idx) {
					$scope.insertHtml('[attachment='+encodeURIComponent($scope.model.attachments[idx].name)+']');
				};
				$scope.editImage = function() {
					wrapSelectionWithTag('[img]', '[/img]');
				};
				$scope.editURL = function() {
					wrapSelectionWithTag('[url]', '[/url]');
				};
				$scope.setFontSize = function(size) {
					var sizes = { tiny: 50, small: 85, large: 150, huge: 200 };
					wrapSelectionWithTag('[font size='+sizes[size]+']', '[/font]');
				};
			}
		],
		link: function(scope, element, attr, controller) {
			var textarea = element.find('textarea')[0];
			textarea.addEventListener('keydown', function(ev) {
				if(!ev.ctrlKey || ev.altKey || ev.shiftKey)
					return;

				switch(ev.key) {
					case 'b':
						scope.editBold();
						break;
					case 'i':
						scope.editItalic();
						break;
					case 'u':
						scope.editUnderline();
						break;
					case 'q':
						scope.editQuote();
						break;
					case 'k':
						scope.editCode();
						break;
					case 'l':
						scope.editList();
						break;
					default:
						// return before stoping propagation
						return;
				}
				ev.stopPropagation();
				ev.preventDefault();
				scope.$apply();
			});
			textarea.addEventListener('keyup', function(ev) {
				scope.model.content = textarea.value;
				scope.$apply();
			});
			var handleTransferBlob = function(blob) {
				var reader = new FileReader();
				reader.onload = function(event) {
					var reader = new FileReader();
					var attachmentName = scope.addAttachment(blob.name, blob, event.target.result);
					scope.insertHtml('[attachment='+attachmentName+']');
					scope.$apply();
				};
				reader.readAsDataURL(blob);
			};
			var handleTransfer = function(transfer) {
				var items = transfer.items;
				for(var index in items) {
					var item = items[index];
					if (item.kind === 'file') {
						var blob = item.getAsFile();
						handleTransferBlob(blob);
					}
				}
			};
			textarea.addEventListener('paste', function(event) {
				handleTransfer(event.clipboardData || event.originalEvent.clipboardData);
			});
			textarea.addEventListener('drop', function(event) {
				event.preventDefault();
				handleTransfer(event.dataTransfer);
			});
			var fileInput = element.find('input')[0];
			fileInput.addEventListener('change', function(event) {
				for(var i = 0; i < this.files.length; i++) {
					handleTransferBlob(this.files[i]);
				}
			}, false);
		}
	};
})
;
