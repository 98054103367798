angular.module('app')
.config(['$stateProvider', function($stateProvider) {
	$stateProvider
		.state('games', {
			url: "/games?developer_url&publisher_url&platform_url&staff_url&series_url",
			template: require('./partials/games/games.html'),
			controller: [
				'$scope', '$stateParams', 'api', '$state',
				function($scope, $stateParams, api, $state) {
					$scope.games = [];
					$scope.pagination = { curPage: 1, limit: 10, total : 0 }
					$scope.loadPage = function() {
						var foreign = {
							series_games: {
								fields: [],
								foreign: {
									series: {
										fields: [ 'id', 'name', 'url' ]
									}
								}
							},
							top_rated_pack: {
								fields: [ 'rating', 'num_ratings', 'rating_sort' ]
							},
							game_genres: {
								foreign: {
									genre: {
										fields: [ 'id', 'name', 'url' ]
									}
								}
							},
							packs: {
								fields: [ 'url', 'title', 'rating', 'num_ratings' ],
								order_by: 'rating_sort:DESC',
								limit: 3
							}
						};

						var where = { conditions: [], foreign: {} };
						var group_by = [];

						if($scope.q) {
							where.conditions.push({
								type: 'or',
								value: [
									{ field: 'name_en', type: 'like', value: $scope.q + '%' },
									{ field: 'name_jp', type: 'like', value: $scope.q + '%' }
								]
							});
						}
						if($stateParams.series_url) {
							api.get('series', {
								fields: [ 'id', 'name', 'url' ],
								where: {
									conditions: [
										{ field: 'url', type: '=', value: $stateParams.series_url }
									]
								}
							}).then(function(response) {
								$scope.series = response.results[0];
							});
							where.foreign.series_games = {
								foreign: {
									series: {
										conditions: [
											{
												field: 'url',
												type: '=',
												value: $stateParams.series_url
											}
										]
									}
								}
							};
						}

						if($stateParams.developer_url) {
							api.get('companies', {
								fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ],
								where: {
									conditions: [
										{ field: 'url', type: '=', value: $stateParams.developer_url }
									]
								}
							}).then(function(response) {
								$scope.developer = response.results[0];
							});
							where.foreign.game_developers = {
								foreign: {
									company: {
										conditions: [
											{
												field: 'url',
												type: '=',
												value: $stateParams.developer_url
											}
										]
									}
								}
							};
						}
						if($stateParams.publisher_url) {
							api.get('companies', {
								fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ],
								where: {
									conditions: [
										{ field: 'url', type: '=', value: $stateParams.publisher_url }
									]
								}
							}).then(function(response) {
								$scope.publisher = response.results[0];
							});
							where.foreign.releases = {
								foreign: {
									publisher: {
										conditions: [
											{
												field: 'url',
												type: '=',
												value: $stateParams.publisher_url
											}
										]
									}
								}
							};
							group_by.push('id');
						}
						if($stateParams.staff_url) {
							api.get('people', {
								fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ],
								where: {
									conditions: [
										{ field: 'url', type: '=', value: $stateParams.staff_url }
									]
								}
							}).then(function(response) {
								$scope.staff = response.results[0];
							});
							where.foreign.game_staff = {
								foreign: {
									person: {
										conditions: [
											{
												field: 'url',
												type: '=',
												value: $stateParams.staff_url
											}
										]
									}
								}
							};
						}
						if($stateParams.platform_url) {
							api.get('platforms', {
								fields: [ 'id', 'name_en', 'name_jp' ],
								where: {
									conditions: [
										{ field: 'url', type: '=', value: $stateParams.platform_url }
									]
								}
							}).then(function(response) {
								$scope.platform = response.results[0];
							});
							where.foreign.releases = {
								foreign: {
									platform: {
										conditions: [
											{
												field: 'url',
												type: '=',
												value: $stateParams.platform_url
											}
										]
									}
								}
							};
							group_by.push('id');
						}

						api.get('games', {
							calc_found_rows: true,
							fields: [ 'id', 'url', 'name_en', 'name_jp', 'release_date', 'num_packs', 'num_releases' ],
							foreign: foreign,
							where: where,
							order_by: [ 'top_rated_pack.rating_sort:DESC' ],
							group_by: group_by,
							limit: 10,
							skip: ($scope.pagination.curPage - 1) * 10
						}).then((response) => {
							$scope.games = response.results;
							$scope.pagination.total = parseInt(response.total);
							$scope.pagination.skip = parseInt(response.skip);
							$scope.pagination.limit = parseInt(response.limit);
						});
					}
					$scope.$watch('pagination.curPage', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							$scope.loadPage();
						}
					});
					$scope.$watch('q', function(newVal, oldVal) {
						if(newVal !== oldVal) {
							if($scope.pagination.curPage != 1)
								$scope.pagination.curPage = 1;
							else
								$scope.loadPage();
						}
					});
					$scope.afterCreate = (game) => {
						$state.go('game', { url: game.url });
					};
					$scope.loadPage();
				}
			]
		})
		.state('game', {
			url: '/game/:url',
			template: require('./partials/games/game.html'),
			controller: 'GameCtrl'
		})
		;
}])
.controller('GameCtrl', [
	'$scope', '$stateParams', 'api', '$uibModal', '$state', 'Notification',
	function($scope, $stateParams, api, $uibModal, $state, Notification) {
		$scope.loadGame = function() {
			api.get('games', {
				fields: [ 'id', 'url', 'name_en', 'name_jp', 'description', 'num_packs' ],
				foreign: {
					releases: {
						fields: [ 'id', 'order', 'title', 'title_en', 'territory_code', 'release_date' ],
						foreign: {
							platform: {
								fields: [ 'url', 'name', 'name_en', 'name_jp' ]
							},
							publisher: {
								fields: [ 'url', 'name', 'name_en', 'name_jp' ]
							},
							screenshots: {
								fields: [ 'file_md5', 'order', 'filename', 'title' ],
								order_by: [ 'order' ]
							}
						},
						order_by: [ 'order' ]
					},
					packs: {
						fields: [
							'id',
							'url',
							'title',
							'description',
							'rating',
							'num_ratings',
							'rating_sort'
						],
						foreign: {
							game: {
								fields: [ 'id', 'url', 'name_en', 'name_jp', 'num_packs' ]
							},
							pack_chips: {
								foreign: {
									chip: {
										fields: [ 'url', 'name' ]
									}
								}
							},
							pack_authors: {
								fields: [ 'role' ],
								foreign: {
									author: {
										fields: [ 'id', 'username', 'avatar_ext' ]
									}
								}
							}
						},
						order_by: [ 'rating_sort:DESC' ]
					},
					game_staff: {
						fields: ['comment'],
						foreign: {
							person: {
								fields: [ 'id', 'url', 'name', 'name_en', 'name_jp' ]
							},
							role: {
								fields: [ 'id', 'name' ]
							}
						}
					},
					series_games: {
						foreign: {
							series: {
								fields: [ 'id', 'url', 'name' ]
							}
						}
					},
					game_links: {
						fields: [ 'url', 'name' ]
					},
					requests: {
						fields: [ 'id', 'title', 'num_request_votes', 'voted' ]
					},
					game_genres: {
						foreign: {
							genre: {
								fields: [ 'id', 'name', 'url' ]
							}
						}
					}
				},
				where: {
					conditions: [
						{
							field: 'url',
							type: '=',
							value: $stateParams.url
						}
					]
				}
			}).then(function(response) {
				if(response.results && response.results[0]) {
					var game = response.results[0];
					game.screenshots = [];
					for(var i in game.releases) {
						var release = game.releases[i];
						for(var j in release.screenshots) {
							var screenshot = release.screenshots[j];
							game.screenshots.push({
								fileMd5: screenshot.file_md5,
								order: screenshot.order,
								filename: screenshot.filename,
								title: screenshot.title,
								description: screenshot.description,
								releaseId: release.id,
							});
						}
					}
					$scope.game = game;
				} else {
					$scope.error = 'Not found';
				}
			});
		}
		$scope.loadGame();
		$scope.afterSave = function(game) {
			if(game.url != $stateParams.url)
				$state.go('game', { url: game.url });
			else
				$scope.loadGame();
		};
		$scope.afterDelete = function(game) {
			$state.go('games');
		};
		$scope.jp = false;

		var cleanTitle = (str) => { return str.replace(/\.[a-z]+$/, '').replace(/_/g, ' '); };
		$scope.openScreenshotEdit = (releaseId, fileMd5, file) => {
			$uibModal.open({
				template: require('./partials/games/screenshot-edit.html'),
				backdrop: 'static',
				size: 'md',
				controller: [
					'$scope', '$uibModalInstance', 'Notification',
					function(modalScope, $uibModalInstance, Notification) {
						if(releaseId && fileMd5) {
							api.get('screenshots', {
								fields: [ 'release_id', 'order', 'title', 'description', 'filename', 'file_md5' ],
								foreign: {
									release: {
										fields: [ 'id' ],
										foreign: {
											game: {
												fields: [ 'id', 'url', 'name_en', 'name_jp' ],
												foreign: {
													releases: {
														fields: [ 'id', 'order', 'release_date', 'title_en', 'title', 'territory_code' ],
														foreign: {
															platform: {
																fields: [ 'url', 'name_en', 'name_jp', 'short_name' ]
															},
															publisher: {
																fields: [ 'url', 'name_en', 'name_jp' ]
															}
														},
														order_by: [ 'order' ]
													}
												}
											}
										}
									}
								},
								where: {
									conditions: [
										{ field: 'release_id', value: releaseId },
										{ field: 'file_md5', value: fileMd5 }
									]
								}
							}).then(function(response) {
								modalScope.screenshot = response.results[0];
								if(file) {
									modalScope.screenshot.imageBlob = file;
									modalScope.screenshot.title = cleanTitle(file.name);
								}
								modalScope.initialScreenshotFilename = modalScope.screenshot.filename;
								modalScope.releases = modalScope.screenshot.release.game.releases;
							});
						} else if(releaseId) {
							api.get('releases', {
								fields: [ 'id' ],
								foreign: {
									screenshots: {
										fields: [ 'id', 'order' ]
									},
									game: {
										fields: [ 'id', 'url', 'name_en', 'name_jp' ],
										foreign: {
											releases: {
												fields: [ 'id', 'order', 'release_date', 'title_en', 'title', 'territory_code' ],
												foreign: {
													platform: {
														fields: [ 'url', 'name_en', 'name_jp', 'short_name' ]
													},
													publisher: {
														fields: [ 'url', 'name_en', 'name_jp' ]
													}
												},
												order_by: [ 'order' ]
											}
										}
									}
								},
								where: {
									conditions: [
										{ field: 'id', value: releaseId }
									]
								}
							}).then(function(response) {
								var release = response.results[0];
								var maxOrder = 1;
								for(var i in release.screenshots)
									if(release.screenshots[i].order > maxOrder)
										maxOrder = release.screenshots[i].order;
								modalScope.screenshot = {
									file_md5: null,
									order: maxOrder + 1,
									imageBlob: file || null,
									filename: file && file.name || null,
									title: file && file.name ? cleanTitle(file.name) : null
								};
								modalScope.releases = release.game.releases;
							});
						} else if(fileMd5) {
							var gameId = fileMd5;
							api.get('releases', {
								fields: [ 'id', 'order', 'release_date', 'title_en', 'title', 'territory_code' ],
								foreign: {
									platform: {
										fields: [ 'url', 'name_en', 'name_jp', 'short_name' ]
									},
									publisher: {
										fields: [ 'url', 'name_en', 'name_jp' ]
									}
								},
								order_by: [ 'order' ],
								where: {
									conditions: [
										{ field: 'game_id', value: gameId }
									]
								}
							}).then((response) => {
								modalScope.screenshot = {
									file_md5: null,
									order: 1,
									imageBlob: file || null,
									filename: file && file.name || null,
									title: file && file.name ? cleanTitle(file.name) : null
								}
								modalScope.releases = response.results;
							});
						}

						modalScope.ok = function() {
							if(!modalScope.screenshot.imageBlob && !modalScope.screenshot.file_md5) {
								Notification.error('Please select an image');
							} else if(!modalScope.screenshot.filename) {
								Notification.error('Please specify a filename for the image');
							} else {
								var saveScreenshot = function() {
									api.post('screenshots', {
										key: {
											release_id: releaseId,
											file_md5: fileMd5||modalScope.screenshot.file_md5
										},
										data: {
											release_id: modalScope.screenshot.release_id || null,
											file_md5: fileMd5||modalScope.screenshot.file_md5,
											title: modalScope.screenshot.title,
											description: modalScope.screenshot.description,
											filename: modalScope.screenshot.filename
										}
									}).then((response) => {
										Notification.success('Screenshot saved');
										$uibModalInstance.close(modalScope.screenshot);
									}, (err) => {
										Notification.error(err);
									});
								};
								if('imageBlob' in modalScope.screenshot) {
									if(modalScope.screenshot.imageBlob) {
										api.upload('files', {
											file: modalScope.screenshot.imageBlob
										}).then((response) => {
											modalScope.screenshot.file_md5 = response.file.id;
											saveScreenshot();
										}, (err) => {
											Notification.error(err);
										});
									}
								} else {
									saveScreenshot();
								}
							}
						};
						modalScope.cancel = function() {
							$uibModalInstance.dismiss('cancel');
						};
					}
				]
			}).result.then((screenshot) => { $scope.loadGame(); }, (err) => {});
		};
		$scope.addScreenshot = (file, gameId) => {
			if(file) {
				$scope.openScreenshotEdit(null, gameId, file);
			}
		};
		$scope.deleteScreenshot = (releaseId, fileMd5) => {
			$uibModal.open({
				template: '<div class="modal-header">\
						<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
							<span aria-hidden="true">&times;</span>\
						</button>\
						<h3 class="modal-title">Please confirm</h3>\
					</div>\
					<div class="modal-body">\
						Are you sure you want to delete this screenshot?<br/>\
					</div>\
					<div class="modal-footer">\
						<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
						<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
					</div>',
				backdrop: 'static',
				size: 'sm',
				controller: [
					'$scope', '$uibModalInstance', 'api', 'Notification',
					function(modalScope, $uibModalInstance, api, Notification) {
						modalScope.ok = function() {
							api.delete('screenshots', {
								release_id: releaseId,
								file_md5: fileMd5
							}).then(function(response) {
								Notification.success('Screenshot deleted');
								$uibModalInstance.close('success');
							}, function(err) {
								Notification.error(err);
							});
						};
						modalScope.cancel = function() {
							$uibModalInstance.dismiss('cancel');
						};
					}
				]
			}).result.then(() => { $scope.loadGame(); }, (err) => {});
		};

		/* Game Staff */
		$scope.openGameStaffEdit = function(game_id, person_id) {
			$uibModal.open({
				template: require('./partials/games/game-staff-edit.html'),
				backdrop: 'static',
				size: 'sm',
				controller: [
					'$scope', '$uibModalInstance',
					function(modalScope, $uibModalInstance) {
						if(person_id) {
							api.get('game_staff', {
								fields: [
									'comment'
								],
								foreign: {
									game: {
										fields: [ 'id', 'name_en' ]
									},
									person: {
										fields: [ 'id', 'name', 'name_en' ]
									},
									role: {
										fields: [ 'id', 'name' ]
									}
								},
								where: {
									conditions: [
										{ field: 'game_id', value: game_id },
										{ field: 'person_id', value: person_id }
									]
								}
							}).then(function(response) {
								modalScope.gameStaff = response.results[0];
							});
						} else {
							api.get('games', {
								fields: [ 'id', 'name_en' ],
								where: {
									conditions: [
										{ field: 'id', value: game_id }
									]
								}
							}).then(function(response) {
								modalScope.gameStaff = { game: response.results[0] };
							});
						}

						modalScope.ok = function() {
							if(!modalScope.gameStaff.person || !modalScope.gameStaff.person.id) {
								Notification.error('Please specify the person');
							} else if(!modalScope.gameStaff.role || !modalScope.gameStaff.role.id) {
								Notification.error('Please specify staff role');
							} else {
								api.post('game_staff', {
									key: {
										game_id: modalScope.gameStaff.game.id,
										person_id: modalScope.gameStaff.person.id
									},
									data: {
										role_id: modalScope.gameStaff.role.id,
										comment: modalScope.gameStaff.comment
									}
								}).then(function(response) {
										Notification.success('Game staff saved');
										$uibModalInstance.close(modalScope.gameStaff);
								}, function(err) {
									Notification.error(err);
								});
							}
						};
						modalScope.cancel = function() {
							$uibModalInstance.dismiss('cancel');
						};
					}
				]
			}).result.then((gameStaff) => {
				$scope.loadGame();
			}, (err) => {});
		};
		$scope.deleteGameStaff = function(gameId, personId) {
			$uibModal.open({
				template: '<div class="modal-header">\
						<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
							<span aria-hidden="true">&times;</span>\
						</button>\
						<h3 class="modal-title">Please confirm</h3>\
					</div>\
					<div class="modal-body">\
						Are you sure you want to delete this game staff?<br/>\
					</div>\
					<div class="modal-footer">\
						<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
						<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
					</div>',
				backdrop: 'static',
				size: 'sm',
				controller: [
					'$scope', '$uibModalInstance', 'api', 'Notification',
					function(modalScope, $uibModalInstance, api, Notification) {
						modalScope.ok = function() {
							api.delete('game_staff', {
								game_id: gameId,
								person_id: personId
							}).then(function(response) {
								Notification.success('Game staff deleted');
								$uibModalInstance.close('success');
							}, function(err) {
								Notification.error(err);
							});
						};
						modalScope.cancel = function() {
							$uibModalInstance.dismiss('cancel');
						};
					}
				]
			}).result.then(() => $scope.loadGame(), (err) => {});
		};

		/* Game genres */
		$scope.openGameGenreEdit = function(game_id, genre_id) {
			$uibModal.open({
				template: '<div class="modal-header">\
						<button type="button" class="close" ng-click="cancel()" aria-label="Close" tabIndex="-1"><span aria-hidden="true">&times;</span></button> \
						<h3 class="modal-title">Edit game genre</h3>\
					</div>\
					<form>\
					<div class="modal-body">\
						<div class="row">\
							<div class="col-md-12">\
								<div class="form-group" ng-class="{ \'has-error\': !gameGenre.genre || !gameGenre.genre.id }">\
									<label class="control-label">Genre</label>\
									<genre-select model="gameGenre.genre"></genre-select>\
								</div>\
							</div>\
						</div>\
					</div>\
					<div class="modal-footer">\
						<button class="btn btn-primary" type="submit" ng-click="ok()">OK</button>\
						<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
					</div>\
					</form>',
				backdrop: 'static',
				size: 'sm',
				controller: [
					'$scope', '$uibModalInstance',
					function(modalScope, $uibModalInstance) {
						if(genre_id) {
							api.get('game_genres', {
								fields: [],
								foreign: {
									game: {
										fields: [ 'id', 'name_en' ]
									},
									genre: {
										fields: [ 'id', 'name' ]
									}
								},
								where: {
									conditions: [
										{ field: 'game_id', value: game_id },
										{ field: 'genre_id', value: genre_id }
									]
								}
							}).then(function(response) {
								modalScope.gameGenre = response.results[0];
							});
						} else {
							api.get('games', {
								fields: [ 'id', 'name_en' ],
								where: {
									conditions: [
										{ field: 'id', value: game_id }
									]
								}
							}).then(function(response) {
								modalScope.gameGenre = { game: response.results[0] };
							});
						}

						modalScope.ok = function() {
							if(!modalScope.gameGenre.genre || !modalScope.gameGenre.genre.id) {
								Notification.error('Please specify the genre');
							} else {
								api.post('game_genres', {
									key: {
										game_id: modalScope.gameGenre.game.id,
										genre_id: modalScope.gameGenre.genre.id
									},
									data: {
									}
								}).then(function(response) {
										Notification.success('Game genre saved');
										$uibModalInstance.close(modalScope.gameGenre);
								}, function(err) {
									Notification.error(err);
								});
							}
						};
						modalScope.cancel = function() {
							$uibModalInstance.dismiss('cancel');
						};
					}
				]
			}).result.then((gameGenre) => {
				$scope.loadGame();
			}, (err) => {});
		};
		$scope.deleteGameGenre = function(gameId, genreId) {
			$uibModal.open({
				template: '<div class="modal-header">\
						<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
							<span aria-hidden="true">&times;</span>\
						</button>\
						<h3 class="modal-title">Please confirm</h3>\
					</div>\
					<div class="modal-body">\
						Are you sure you want to delete this game genre?<br/>\
					</div>\
					<div class="modal-footer">\
						<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
						<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
					</div>',
				backdrop: 'static',
				size: 'sm',
				controller: [
					'$scope', '$uibModalInstance', 'api', 'Notification',
					function(modalScope, $uibModalInstance, api, Notification) {
						modalScope.ok = function() {
							api.delete('game_genres', {
								game_id: gameId,
								genre_id: genreId
							}).then(function(response) {
								Notification.success('Game genre deleted');
								$uibModalInstance.close('success');
							}, function(err) {
								Notification.error(err);
							});
						};
						modalScope.cancel = function() {
							$uibModalInstance.dismiss('cancel');
						};
					}
				]
			}).result.then(() => $scope.loadGame(), (err) => {});
		};

		/* Game links */
		$scope.openGameLinkEdit = function(game_id, url) {
			$uibModal.open({
				template: require('./partials/games/game-link-edit.html'),
				backdrop: 'static',
				size: 'sm',
				controller: [
					'$scope', '$uibModalInstance',
					function(modalScope, $uibModalInstance) {
						if(game_id) {
							if(url) {
								api.get('game_links', {
									fields: [ 'game_id', 'url', 'name' ],
									foreign: {
										game: {
											fields: [ 'id', 'name_en', 'name_jp' ]
										}
									},
									where: {
										conditions: [
											{ field: 'game_id', value: game_id },
											{ field: 'url', value: url }
										]
									}
								}).then(function(response) {
									modalScope.game_link = response.results[0];
								});
							} else {
								api.get('games', {
									fields: [ 'id', 'name_en', 'name_jp' ],
									where: {
										conditions: [
											{ field: 'id', value: game_id }
										]
									}
								}).then(function(response) {
									modalScope.game_link = { game: response.results[0], url: '', name: '' }
								});
							}
						} else {
							modalScope.game_link = { game: {}, url: '', name: '' };
						}
						modalScope.ok = function() {
							if(!modalScope.game_link.game || !modalScope.game_link.game.id) {
								Notification.error('Please select a game for this game link');
							} else if(!modalScope.game_link.url) {
								Notification.error('Please input an URL for the game link');
							} else {
								api.post('game_links', {
									key: {
										game_id: modalScope.game_link.game.id,
										url: modalScope.game_link.url
									},
									data: {
										name: modalScope.game_link.name
									}
								}).then(function(response) {
									Notification.success('Game link saved');
									$uibModalInstance.close(modalScope.game_link);
								}, function(err) {
									Notification.error(err);
								});
							}
						};
						modalScope.cancel = function() {
							$uibModalInstance.dismiss('cancel');
						};
					}
				]
			}).result.then((game_link) => {
				$scope.loadGame();
			}, (err) => {});
		};

		$scope.deleteGameLink = function(gameId, url) {
			$uibModal.open({
				template: '<div class="modal-header">\
						<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
							<span aria-hidden="true">&times;</span>\
						</button>\
						<h3 class="modal-title">Please confirm</h3>\
					</div>\
					<div class="modal-body">\
						Are you sure you want to delete this link?<br/>\
						<pre>{{url}}</pre>\
					</div>\
					<div class="modal-footer">\
						<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
						<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
					</div>',
				backdrop: 'static',
				size: 'sm',
				controller: [
					'$scope', '$uibModalInstance', 'api', 'Notification',
					function(modalScope, $uibModalInstance, api, Notification) {
						modalScope.ok = function() {
							api.delete('game_links', {
								game_id: gameId,
								url: url
							}).then(function(response) {
								Notification.success('Game link deleted');
								$uibModalInstance.close('success');
							}, function(err) {
								Notification.error(err);
							});
						};
						modalScope.cancel = function() {
							$uibModalInstance.dismiss('cancel');
						};
					}
				]
			}).result.then(() => $scope.loadGame(), (err) => {});
		};
	}
])
.directive('gameEdit', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope', '$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(gameId) {
					var modal = $uibModal.open({
						template: require('./partials/games/game-edit.html'),
						backdrop: 'static',
						size: 'md',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(gameId) {
									api.get('games', {
										fields: [
											'id',
											'url',
											'name_en',
											'name_jp',
											'description'
										],
										where: {
											conditions: [
												{ field: 'id', type: '=', value: gameId }
											]
										}
									}).then(function(response) {
										modalScope.game = response.results[0];
										modalScope.initialGameUrl = modalScope.game.url;
									});
								} else {
									modalScope.game = { genre: null };
								}
								modalScope.ok = function() {
									if(!modalScope.game.name_en) {
										Notification.error('Please specify english name for game');
									} else if(!modalScope.game.url) {
										Notification.error('Please specify URL slug for game');
									} else {
										api.post('games', {
											key: {
												id: modalScope.game.id||null
											},
											data: {
												url: modalScope.game.url,
												name_en: modalScope.game.name_en,
												name_jp: modalScope.game.name_jp,
												description: modalScope.game.description
											}
										}).then(function(response) {
											Notification.success('Game saved');
											$uibModalInstance.close(modalScope.game);
										}, function(err) {
											Notification.error(err);
										});
									}
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then((game) => {
						$scope.success({ game: game });
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
.directive('gameSelect', function() {
	return {
		restrict: 'E',
		template: require('./partials/games/game-select.html'),
		scope: {
			model: '=?'
		},
		controller: [
			'$scope', 'api', '$uibModal',
			function($scope, api, $uibModal) {
				$scope.game = $scope.model;
				$scope.$watch('game', function() {
					$scope.model = $scope.game;
				});
				$scope.$watch('model', function() {
					if(!$scope.game)
						$scope.game = $scope.model;
				});
				$scope.clear = function() {
					$scope.game = null;
				};
				// Autocomplete
				$scope.getGames = function(val) {
					return api.get('games', {
						fields: [ 'id', 'url', 'name_en', 'name_jp' ],
						where: {
							conditions: [
								{
									type: 'or', value: [
										{ field: 'name_jp', type: 'like', value: val + '%' },
										{ field: 'name_en', type: 'like', value: val + '%' }
									]
								}
							]
						},
						limit: 10
					}).then(function(response) {
						return response.results;
					});
				};
			}
		]
	};
})
.directive('gameList', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=?'
		},
		controller: [
			'$scope', '$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function() {
					var modal = $uibModal.open({
						template: require('./partials/games/game-list.html'),
						controller: [
							'$scope', 'api', '$uibModalInstance',
							function(modalScope, api, $uibModalInstance) {
								modalScope.q = '';
								modalScope.pagination = { curPage: 1, limit: 10, total : 0 }
								modalScope.$watch('q', function() {
									modalScope.getGames();
								});
								modalScope.$watch('pagination.curPage', function(a, b) {
									modalScope.getGames();
								});
								modalScope.selectGame = function(idx) {
									$uibModalInstance.close(modalScope.games[idx]);
								};
								modalScope.ok = function() {
									$uibModalInstance.close();
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
								modalScope.getGames = function() {
									var where = {};
									if(modalScope.q) {
										where.conditions = [
											{
												type: 'or',
												value: [
													{ field: 'name_en', type: 'like', value: modalScope.q + '%' },
													{ field: 'name_jp', type: 'like', value: modalScope.q + '%' }
												]
											}
										];
									}
									api.get('games', {
										calc_found_rows: true,
										fields: [
											'id',
											'url',
											'name_en',
											'name_jp',
											'num_packs',
											'num_releases'
										],
										where: where,
										order_by: [ 'num_packs:ASC', 'name_en:ASC' ],
										skip: (modalScope.pagination.curPage - 1) * 10,
										limit: 10
									}).then(function(response) {
										modalScope.games = response.results;
										modalScope.pagination.total = parseInt(response.total);
										modalScope.pagination.skip = parseInt(response.skip);
										modalScope.pagination.limit = parseInt(response.limit);
									});
								};
								$uibModalInstance.opened.then(function() {
									modalScope.getGames();
								});
							}
						]
					});
					modal.result.then((selectedItem) => {
						$scope.model = selectedItem;
					}, (err) => {

					});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog();
			});
		}
	};
})
.directive('gameDelete', function() {
	return {
		restrict: 'A',
		scope: {
			model: '=',
			success: '&',
		},
		controller: [
			'$scope',
			'$uibModal',
			function($scope, $uibModal) {
				$scope.openDialog = function(gameId) {
					var modal = $uibModal.open({
						template: '<div class="modal-header">\
								<button type="button" class="close" ng-click="cancel()" aria-label="Close">\
									<span aria-hidden="true">&times;</span>\
								</button>\
								<h3 class="modal-title">Please confirm</h3>\
							</div>\
							<div class="modal-body">\
								Are you sure you want to delete {{game.name_en||game.name_jp }}?<br/>\
								This game has {{game.num_releases}} releases and {{game.num_packs}} packs.\
							</div>\
							<div class="modal-footer">\
								<button class="btn btn-danger" type="button" ng-click="ok()">Delete</button>\
								<button class="btn btn-default" type="button" ng-click="cancel()">Cancel</button>\
							</div>',
						size: 'md',
						controller: [
							'$scope', '$uibModalInstance', 'api', 'Notification',
							function(modalScope, $uibModalInstance, api, Notification) {
								if(gameId) {
									api.get('games', {
										fields: [
											'id',
											'url',
											'name_en',
											'name_jp',
											'num_releases',
											'num_packs'
										],
										where: {
											conditions: [
												{ field: 'id', type: '=', value: gameId }
											]
										},

									}).then(function(response) {
										modalScope.game = response.results[0];
									});
								}
								modalScope.ok = function() {
									api.delete('games', {
										id: modalScope.game.id,
									}).then(function(response) {
										Notification.success('Game deleted');
										$uibModalInstance.close(modalScope.game);
									}, function(err) {
										Notification.error(err);
									});
								};
								modalScope.cancel = function() {
									$uibModalInstance.dismiss('cancel');
								};
							}
						]
					}).result.then((game) => {
						$scope.success({ game: game });
					}, (err) => {});
				};
			}
		],
		link: function(scope, element, attr, controller) {
			element.on('click', function(event) {
				event.preventDefault();
				scope.openDialog(scope.model&&scope.model.id);
			});
		}
	};
})
;
